import React from "react";

type TextButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  disabled?: boolean;
  id?: string;
  tabIndex?: number;
  children: React.ReactNode;
  style?: any;
  title?: string;
};

const TextButton: React.FC<TextButtonProps> = (props) => {
  const {
    onClick,
    className = "",
    children,
    disabled = false,
    tabIndex,
    style = {},
    id = "",
    title = "",
  } = props;

  return (
    <button
      {...props}
      type="button"
      title={title}
      className={`bg-transparent border-0 p-0 ${className}`}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex ?? undefined}
      style={style}
      id={id}
    >
      {children}
    </button>
  );
};

export default TextButton;
