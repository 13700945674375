import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import Toggle from "react-toggle";
import { useState } from "react";
import PaginatedSelectInput from "../form/PaginatedSelectInput";
import required from "../utils/required";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import { IUseApiWithData } from "../api/apiTypes";
import SelectInputAsync from "../utils/SelectInputAsync";
import { money, percentage } from "../form/formatters";
import {
  Actions,
  NonStandardOpportunityTypes,
  Stages,
} from "./opportunityTypes";
import LIKELIHOOD_OF_SUCCESS from "../../data/likelihood_of_success";
import renderToggleInput from "../utils/renderToggleInput";
import { connect } from "react-redux";
import FormErrorAlert from "../form/FormErrorAlert";
import { ClientIndex } from "../clients/clientTypes";
import { useAuth } from "../../context/auth-context";

interface OpportunityFormProps {
  bidTeamInvolved?: boolean;
  childOrganisationId?: number;
}

const OpportunityForm = (
  props: InjectedFormProps<any, OpportunityFormProps> & OpportunityFormProps,
) => {
  const {
    handleSubmit,
    change,
    bidTeamInvolved,
    childOrganisationId,
    initialValues,
  } = props;

  const { user } = useAuth();

  const [newClient, setNewClient] = useState(!!initialValues?.client_name);

  const {
    data: organisationChildren,
  }: IUseApiWithData<
    {
      display_name: string;
      id: number;
    }[]
  > = useApi("organisations/children/show", []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert {...props} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div
          className={`form-group ${
            organisationChildren.length === 0 ? "col-lg-6" : "col-lg-4"
          }`}
        >
          <Field
            component={PaginatedSelectInput}
            name="project_id"
            url="projects?"
            label="Project"
            validate={required}
            required
            initialOptions={
              initialValues?.project_id
                ? [
                    {
                      label: (
                        <div>
                          <p className="mb-0 text-dark">
                            {initialValues.project.name}
                          </p>
                          <p className="mb-0 tx-12">
                            {initialValues.project.number}
                          </p>
                        </div>
                      ),
                      value: initialValues?.project_id,
                    },
                  ]
                : null
            }
            formatData={(data: any[]) => {
              return data.map((d) => ({
                label: (
                  <div>
                    <p className="mb-0 text-dark">{d.name}</p>
                    <p className="mb-0 tx-12">{d.number}</p>
                  </div>
                ),
                value: d.id,
              }));
            }}
          />
        </div>

        {organisationChildren.length > 0 && (
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInput}
              name="child_organisation_id"
              label="Organisation"
              changeValue={() => {
                change("industries", null);
                change("services", null);
                change("client_id", null);
              }}
              options={organisationChildren.map((child) => ({
                label: child.display_name,
                value: child.id,
              }))}
            />
          </div>
        )}
        <div
          className={`form-group ${
            organisationChildren.length === 0 ? "col-lg-6" : "col-lg-4"
          }`}
        >
          <div className="d-flex align-items-center mb-2">
            <label className="tx-inverse tx-semibold mb-0">
              Client <span className="tx-danger"> *</span>
            </label>
            <div className="d-flex ms-auto align-items-center">
              <Toggle
                checked={newClient}
                onChange={() => {
                  setNewClient(!newClient);
                  change("client_name", null);
                  change("client_id", null);
                }}
                className="toggle-sm"
                icons={false}
              />
              <span className="tx-sm ms-1">New Client</span>
            </div>
          </div>
          <Field
            name={newClient ? "client_name" : "client_id"}
            component={newClient ? RenderField : PaginatedSelectInput}
            url={`/organisation-clients?${
              childOrganisationId &&
              childOrganisationId !== user?.active_organisation?.id
                ? `organisation_id=${childOrganisationId}`
                : ""
            }`}
            cacheResults={false}
            validate={required}
            required
            initialOptions={
              initialValues?.client_id
                ? [
                    {
                      label: initialValues.client.name,
                      value: initialValues?.client_id,
                    },
                  ]
                : null
            }
            formatData={(data: ClientIndex[]) => {
              return data.map((d) => ({
                label: d.is_disabled ? (
                  <>
                    <p className="mb-0">{d.display_name}</p>
                    <p className="tx-12 text-warning mb-0">
                      {d.disabled_reason}
                    </p>
                  </>
                ) : (
                  d.name
                ),
                value: d.id,
                disabled: d.is_disabled,
              }));
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            validate={required}
            required
            component={PaginatedSelectInput}
            name="job.project_manager"
            label="Opportunity Manager"
            cacheResults={false}
            initialOptions={
              initialValues?.job?.manager
                ? [
                    {
                      label: initialValues.job.manager.name,
                      value: initialValues?.job?.manager.id,
                    },
                  ]
                : null
            }
            formatData={(data: any[]) => {
              return data.map((d) => ({
                label: d.full_name,
                value: d.id,
              }));
            }}
            url={`/users?paginate=9${
              childOrganisationId &&
              childOrganisationId !== user?.active_organisation?.id
                ? `&organisation_id=${childOrganisationId}`
                : ""
            }`}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            {...money}
            name="estimated_fee"
            label="Estimated Fee"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            type="date"
            name="job.scheduled_start_date"
            label="Tender Start Date"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            type="date"
            name="job.scheduled_finish_date"
            label="Tender Finish Date"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            name="industries"
            component={PaginatedSelectInput}
            required
            label="Markets"
            isMulti
            closeMenuOnSelect={false}
            cacheResults={false}
            validate={required}
            url={`/industries?${
              childOrganisationId &&
              childOrganisationId !== user?.active_organisation?.id
                ? `organisation_id=${childOrganisationId}`
                : ""
            }`}
            formatData={(data: any[]) =>
              data.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="status"
            label="Stage"
            options={[
              {
                label: "Identified",
                value: Stages.Identified,
              },
              {
                label: "Positioning",
                value: Stages.Positioning,
              },
            ]}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="next_action"
            label="Next Action"
            options={[
              {
                label: "Invitation To Bid",
                value: Actions["Invitation To Bid"],
              },
              {
                label: "TBC",
                value: Actions.TBC,
              },
              {
                label: "Project Progression",
                value: Actions["Project Progression"],
              },
              {
                label: "Client Decision",
                value: Actions["Client Decision"],
              },
            ]}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={PaginatedSelectInput}
            name="services"
            label="Services"
            isMulti
            closeMenuOnSelect={false}
            cacheResults={false}
            url={`/services?${
              childOrganisationId &&
              childOrganisationId !== user?.active_organisation?.id
                ? `organisation_id=${childOrganisationId}`
                : ""
            }`}
            formatData={(data: any[]) => {
              return data.map((d) => ({
                label: d.name,
                value: d.id,
              }));
            }}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="non_standard_opportunity_type_id"
            label="Non Standard Opportunity Type"
            options={[
              {
                label: "Sole Source",
                value: NonStandardOpportunityTypes["Sole Source"],
              },
              {
                label: "Panel",
                value: NonStandardOpportunityTypes["Panel"],
              },
              {
                label: "Alliance",
                value: NonStandardOpportunityTypes["Alliance"],
              },
              {
                label: "PPP",
                value: NonStandardOpportunityTypes["PPP"],
              },
              {
                label: "Direct Competitive",
                value: NonStandardOpportunityTypes["Direct Competitive"],
              },
            ]}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="expected_award_date"
            label="Expected Award Date"
            type="date"
          />
        </div>
        <div className="form-group col-lg-4">
          <Field
            component={SelectInput}
            name="likelihood_of_success"
            label="Likelihood of Success"
            options={LIKELIHOOD_OF_SUCCESS}
            order={false}
          />
        </div>
        <div className="form-group col-lg-4">
          <Field
            name="probability_of_work_starting"
            component={RenderField}
            label="Probability of Work Commencing"
            {...percentage}
          />
          <small>
            The percentage chance that the project/job will actually go ahead.
          </small>
        </div>

        <div className="form-group col-lg-6">
          <Field
            name="work_start_date"
            label="Estimated Start Date of Work"
            type="date"
            component={RenderField}
          />
        </div>

        <div className="form-group col-lg-6">
          <Field
            name="work_finish_date"
            label="Estimated Completion Date of Work"
            type="date"
            component={RenderField}
          />
        </div>

        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="bid_team_involved"
            label="Bid Team Involved"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.checked) {
                change("bid_coordinator", null);
              }
            }}
          />
        </div>
        {bidTeamInvolved ? (
          <div className="col-lg-12 form-group">
            <Field
              name="bid_coordinator"
              label="Bid Coordinator"
              required
              validate={required}
              component={SelectInputAsync}
              url="/users/staff"
            />
          </div>
        ) : null}
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="job.description"
            label="Notes"
            textarea
          />
        </div>

        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<any, OpportunityFormProps>({});

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    bidTeamInvolved: selector(state, "bid_team_involved"),
    childOrganisationId: selector(state, "child_organisation_id"),
  };
};

export default connect(mapStateToProps, {})(form(OpportunityForm));
