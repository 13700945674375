import CreatableSelect from "react-select/creatable";
import { WrappedFieldProps } from "redux-form";

const CreatableSelectInput = (
  props: WrappedFieldProps & {
    label: string | React.ReactNode;
    required?: boolean;
    isMulti?: boolean;
    options: {
      label: string;
      value: number;
      disabled?: boolean;
    }[];
  },
) => {
  const { label, required, isMulti = false, options, input } = props;

  return (
    <div>
      <label className="form-control-label tx-inverse tx-semibold">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>
      <CreatableSelect
        isMulti={isMulti}
        {...props}
        options={options}
        isOptionDisabled={(option) => option.disabled ?? false}
        {...input}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  );
};

export default CreatableSelectInput;
