import useModal from "../hooks/useModal";

interface SplitProductivityModalProps {
    content: (toggle: () => void) => JSX.Element | null;
    toggle: () => void;
}

const SplitProductivityModal = (props : SplitProductivityModalProps) => {
    const { content, toggle  } = props;
    const { modal } = useModal();

    return (
        <>
            {modal}
            {content && content(toggle)}
        </>
    );
};

export default SplitProductivityModal;