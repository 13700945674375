const isSubmitting = (
  submitting = false,
  save: React.ReactNode = "Save",
  saving: React.ReactNode = "Saving...",
) => {
  return submitting ? (
    <>
      <i className="fa fa-cog fa-spin fa-fw" />
      {saving}
    </>
  ) : (
    save
  );
};
export default isSubmitting;
