import { ApprovalAction, ApprovalStatus } from "./../approvals/approvalTypes";
import { Dayjs } from "dayjs";
export interface ProductivityEvent {
  comments?: string;
  end: Date;
  finish_time_date: string;
  finish_time_time: string;
  id: string;
  internal: boolean;
  job_id: number;
  job_name: string;
  project: number;
  project_name: string;
  start: Date;
  start_time_date: string;
  start_time_time: string;
  title: string;
  break_type?: BreakType;
}

export interface Allowance {
  uuid: string;
  name: string;
  unit: "Days" | "Hours" | "Kilometre";
  rate: number;
  amount: number;
  project: {
    name: string;
    link: string;
  };
  start_time: string;
  finish_time: string;
  date: string;
  link: string;
  total: number;
}

export interface ProductivityOverviewType {
  timesheets?: number;
  total_productivity?: number;
  billable_productivity?: number;
  non_billable_productivity?: number;
  break_productivity?: BreakType;
}

export type ProductivityRange = [Dayjs | undefined, Dayjs | undefined];

export enum ProductivityStatus {
  OK,
  BELOW,
  ABOVE,
}

export enum BreakType {
  Unpaid,
  Paid,
}

//May not be 100% correct
export interface Timesheet {
  uuid: string;
  id: number;
  start_time: string;
  finish_time: string;
  paid_break: string;
  unpaid_break: string;
  remarks: string;
  productivity: any[];
  total_productivity_hours: string;
  total_hours: string;
  date: string;
  display_date: string;
  user_id: number;
  user_uuid: string;
  role_id: string;
  approval_status: ApprovalStatus;
  approvals: string;
  approval_action?: ApprovalAction;
  approval_action_remarks?: ApprovalAction[];
  night_shift: boolean;
  pay_correction: boolean;
  pay_as_normal: string;
  pay_correction_reason: string;
  pay_correction_comments: string;
  pay_correction_explanation: string;
  hourly_rate: string;
  type_description: string;
  leave_reason_id: string;
  created_at: string;
  public_holiday_id: string;
  hourly_rates_for_day: string;
  user: {
    uuid: string;
    name: string;
  };
  pay_id?: number;
  allowances: Allowance[];
  user_address: {
    suburb?: string;
    state?: string;
    postcode?: string;
    country?: string;
    line_1?: string;
    line_2?: string;
  };
}

export interface UserProductivity {
  id: number;
  uuid: string;
  project_id: number;
  project_link: string;
  project_number: string;
  project_name: string;
  job_id: number;
  job_link: string;
  job_name: string;
  date: string;
  role_name: string;
  hours: number;
}
