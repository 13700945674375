import { ChangeEventHandler, useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { Button, Progress } from "reactstrap";
import { WrappedFieldProps } from "redux-form";
import { IDocument } from "../documents/documentTypes";
import useModal from "../hooks/useModal";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";
import GenerateFromTemplateModal from "./GenerateFromTemplateModal";

interface ApiFile {
  name: string;
  size: number;
  link: string;
}

interface CustomFileInputProps {
  required?: boolean;
  customFileClassName?: string;
  label?: string | React.ReactNode;
  percent?: number;
  information?: string;
  templates: IDocument[];
  parentUuid?: string;
  fieldUuid: string;
}

const CustomFileInput = (props: CustomFileInputProps & WrappedFieldProps) => {
  const {
    input,
    required,
    label,
    meta,
    percent = 0,
    customFileClassName = "",
    templates,
    fieldUuid,
  } = props;

  const { modal, toggle } = useModal();

  const [file, setFile] = useState<File | ApiFile | null>();

  useEffect(() => {
    const val = props?.input?.value;
    if (val?.name) {
      setFile({
        name: val.name,
        size: val.file_size,
        link: val.link,
      });
    }
  }, [props?.input?.value]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const file = target.files?.[0];
    if (file) {
      input.onChange(file);
      setFile(file);
    }
  };

  const handleRemove = () => {
    if (file) {
      input.onChange("");
      setFile(null);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        {label && (
          <label className="tx-inverse tx-semibold">
            {label}
            {required ? <span className="tx-danger"> *</span> : ""}
            <FieldInformationPopOver information={props.information} />
          </label>
        )}
        {templates?.length > 0 ? (
          <Button onClick={toggle} color="link" className="ms-auto p-0 mb-2">
            Generate From Template
          </Button>
        ) : null}
      </div>
      <div className={`custom-file ${customFileClassName}`}>
        {file && (
          <button className="remove-input" type="button" onClick={handleRemove}>
            <GrClose />
          </button>
        )}
        <input
          type="file"
          onChange={onChange}
          className={`custom-file-input ${
            meta.error && meta.touched ? "parsley-error" : ""
          }`}
          {...props}
          required={false}
        />
        {percent > 0 && (
          <Progress
            className="mt-2 progress-bar-sm"
            value={percent}
            animated
            striped
            color={percent == 100 ? "success" : "primary"}
          />
        )}
        <p className="custom-file-label custom-file-label-primary rounded-lg shadow-sm">
          {file?.name || ""}
        </p>
      </div>
      <div className="d-flex p-1">
        {file && "link" in file ? (
          <a className="pe-1" href={file?.link ?? ""}>
            {file?.name}
          </a>
        ) : (
          <span className="pe-1">{file?.name}</span>
        )}

        {file?.name && (
          <div className="pe-1">{`(${Math.floor(
            (file?.size ?? 0) / 1000,
          )} KB)`}</div>
        )}

        {meta.touched && meta.error && (
          <span className={meta.error ? "parsley-errors-list" : ""}>
            {meta.error}
          </span>
        )}
      </div>
      {props.parentUuid && (
        <GenerateFromTemplateModal
          toggle={toggle}
          modal={modal}
          templates={templates}
          parentUuid={props.parentUuid}
          fieldUuid={props.fieldUuid}
          onChange={input.onChange}
        />
      )}
    </>
  );
};
export default CustomFileInput;
