import React from "react";
import useModal from "../hooks/useModal";

interface AuditLogModalProps {
  content: (toggle: () => void) => React.ReactNode;
  toggle: () => void;
}

const AuditLogModal = (props: AuditLogModalProps) => {
  const { content, toggle } = props;
  const { modal } = useModal();

  return (
    <>
      {modal}
      {content && content(toggle)}
    </>
  );
};

export default AuditLogModal;
