import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import isSubmitting from "../../utils/submitting";
import { BsFiletypeCsv } from "react-icons/bs";
import TextButton from "../../utils/TextButton";
dayjs.extend(localizedFormat);

const TestStatusDump = () => {
  const { takeAction, loading } = useApi();

  const generateDump = () => {
    return takeAction("store", "test-status-dump")
      .then(({ data }) => {
        fileDownload(data, `Test Dump ${dayjs().format("DD-MM-YYYY, LT")}.csv`);
      })
      .catch(errorSwal);
  };

  return (
    <TextButton
      className="text-secondary"
      disabled={loading}
      onClick={generateDump}
    >
      <BsFiletypeCsv />{" "}
      {isSubmitting(
        loading,
        "Download Test Statuses",
        "Generating Test Statuses...",
      )}
    </TextButton>
  );
};

export default TestStatusDump;
