import { MouseEventHandler } from "react";
import isSubmitting from "./submitting";

interface SubmitButtonProps {
  valid?: boolean;
  save?: string | React.ReactNode;
  saving?: string | React.ReactNode;
  submitting?: boolean;
  className?: string;
  flex?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const SubmitButton = ({
  valid = false,
  save = "Save",
  saving = "Saving",
  submitting = false,
  className = " ms-auto",
  flex = true,
  onClick = undefined,
  disabled = false,
}: SubmitButtonProps) => (
  <button
    className={`btn btn${!valid ? "-outline" : ""}-primary ${
      flex ? "d-flex justify-content-center" : ""
    } ${className}`}
    type="submit"
    disabled={submitting || disabled}
    onClick={onClick || undefined}
  >
    <div className="w-100">{isSubmitting(submitting, save, saving)}</div>
  </button>
);

export default SubmitButton;
