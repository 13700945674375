import useModal from "../hooks/useModal";

interface PriceBandModalProps {
  content: (toggle: () => void) => React.ReactNode | null;
  toggle: () => void;
}

const PriceBandModal = (props: PriceBandModalProps) => {
  const { content, toggle } = props;
  const { modal } = useModal();

  return (
    <>
      {modal}
      {content && content(toggle)}
    </>
  );
};

export default PriceBandModal;
