import {
  Field,
  FormSubmitHandler,
  WrappedFieldProps,
  formValueSelector,
} from "redux-form";
import useModal from "../hooks/useModal";
import { Integration } from "../integrations/integrationTypes";
import PaginatedList from "../pagination/PaginatedList";
import ReactTable from "../table/ReactTable";
import FormModal from "../utils/FormModal";
import useAssistants from "./hooks/useAssistants";
import RenderField from "../utils/renderField";
import { Button } from "reactstrap";
import { useMemo, useState } from "react";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { useQueryClient } from "react-query";
import { Assistant, VectorStoreItem } from "./openAiTypes";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { useDropzone } from "react-dropzone";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import BasicSelectInput from "../form/BasicSelectInput";
import FormHeader from "../utils/FormHeader";

interface Model {
  id: string;
  owned_by: string;
}

const OpenAiAssistants = ({ integration }: { integration: Integration }) => {
  const { data: vectorStores }: IUseApiWithData<VectorStoreItem[]> = useApi(
    "open-ai/vector-stores",
    [],
    true,
  );

  const { data: models }: IUseApiWithData<Model[]> = useApi(
    "open-ai/models",
    [],
    true,
  );

  return (
    <PaginatedList
      indexHook={useAssistants}
      originalFilters={[]}
      list={({ data }) => {
        const assistants = data as Assistant[];

        return (
          <div className="col-12">
            <AssistantTable
              assistants={assistants}
              models={models}
              vectorStores={vectorStores}
            />
          </div>
        );
      }}
    />
  );
};

const AssistantTable = ({
  assistants,
  models,
  vectorStores,
}: {
  assistants: Assistant[];
  models: Model[];
  vectorStores: VectorStoreItem[];
}) => {
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info: any) => {
          const { toggle, modal } = useModal();

          return (
            <>
              <Button
                color="link"
                className="p-0"
                onClick={() => {
                  toggle();
                }}
              >
                {info.getValue() || "Untitled Assistant"}
              </Button>
              <WrappedModal
                toggle={toggle}
                modal={modal}
                initialValues={info.row.original}
                form={`AssistantForm_${info.row.original.id}`}
                title={`Update ${info.getValue() || "Untitled Assistant"}`}
                models={models}
                vectorStores={vectorStores}
              />
            </>
          );
        },
      },
      {
        header: "Model",
        accessorKey: "model",
      },
      {
        header: "Description",
        accessorKey: "description",
      },
    ],
    [assistants, models, vectorStores],
  );

  return <ReactTable disableSearch columns={columns} data={assistants} />;
};

const AssistantModal = ({
  toggle,
  modal,
  initialValues,
  form,
  title,
  models,
  vectorStores,
  tools,
  toolResources,
}: any) => {
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const { upload, files, setFiles } = useUploadMultipleToS3(
    `tmp/open-ai/assistants/files`,
  );

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction(
      "update",
      `open-ai/assistants/${initialValues.id}`,
      values,
    )
      .then(() => {
        toast.success("Assistant updated successfully");
        toggle();
        queryClient.invalidateQueries("openai-assistants");
      })
      .catch(formError);
  };

  const hasFileSearch = tools?.map((t: any) => t.type).includes("file_search");

  const onDrop = (acceptedFiles: File[]) => {
    return upload(acceptedFiles, false, true).then((data) => {
      return takeAction("store", "open-ai/files", {
        files: data,
        assistant_id: initialValues.id,
      })
        .then(({ data }: { data: any }) => {
          setUploadedFiles(data.data);
          toast.success("Files uploaded successfully");
          setFiles([]);
        })
        .catch((err: any) => {
          setFiles([]);
          errorSwal(err);
        });
    });
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    disabled: !hasFileSearch,
    onDrop,
    noClick: true,
  });

  return (
    <FormModal
      title={title}
      form={form}
      toggle={toggle}
      modal={modal}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ change }: { change: Function }) => {
        return (
          <>
            <div className="col-12 form-group">
              <Field component={RenderField} name="name" label="Name" />
            </div>

            <div className="col-12 form-group">
              <Field
                component={RenderField}
                name="description"
                label="Description"
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                name="instructions"
                label="Instructions"
                textarea
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                name="model"
                label="Model"
                required
                validate={required}
                options={models.map((m: Model) => ({
                  label: m.id,
                  value: m.id,
                }))}
              />
            </div>
            <FormHeader>Tools</FormHeader>
            <div className="col-12 form-group">
              <div
                {...getRootProps()}
                className={`border rounded-lg p-3 d-flex align-items-center tn-300 ${
                  isDragActive ? "border-dashed border-dark" : ""
                }`}
              >
                <input {...getInputProps()} />
                <div className="w-100">
                  <div className="d-flex align-items-center">
                    {files.length > 0 && (
                      <div className="d-flex">
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span>Uploading...</span>
                      </div>
                    )}
                    <Field
                      component={RenderToggle}
                      name="file_search"
                      tools={tools}
                      change={change}
                      label="File Search"
                      toolResources={toolResources}
                    />
                    {hasFileSearch && (
                      <div className="ms-auto">
                        <input
                          type="file"
                          name="file"
                          className="upload-input"
                          onChange={(e) => {
                            const droppedFiles = e.target.files;
                            let files = [];
                            if (!droppedFiles) return;

                            for (const file of droppedFiles) {
                              files.push(file);
                            }

                            onDrop(files);
                          }}
                          multiple
                        />
                      </div>
                    )}
                  </div>
                  {hasFileSearch && (
                    <div className="w-100 d-block mt-3">
                      <Field
                        component={BasicSelectInput}
                        name="tool_resources.file_search.vector_store_ids"
                        label="Vector Store"
                        options={vectorStores.map((v: any) => ({
                          label: v.id,
                          value: v.id,
                        }))}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 form-group">
              <div className="border rounded-lg p-3 d-flex align-items-center tn-300">
                <Field
                  component={RenderToggle}
                  name="code_interpreter"
                  tools={tools}
                  change={change}
                  label="Code Interpreter"
                  toolResources={toolResources}
                />
              </div>
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderRange}
                name="temperature"
                label="Temperature"
                type="range"
                min={0.01}
                max={2}
                step={0.01}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderRange}
                name="top_p"
                label="Top P"
                type="range"
                min={0.01}
                max={1}
                step={0.01}
              />
            </div>
            <div className="col-lg-12 form-group">{/*  */}</div>
          </>
        );
      }}
    </FormModal>
  );
};

const RenderToggle = (
  props: WrappedFieldProps & {
    tools: any[];
    change: Function;
    label: React.ReactNode;
    toolResources: any;
  },
) => {
  const { input, tools, label, toolResources } = props;

  const isActive = tools?.map((t) => t.type).includes(input.name);

  return (
    <div className="d-flex align-items-center">
      <Toggle
        checked={isActive}
        onChange={(value) => {
          if (value.target.checked) {
            props.change("tools", [
              ...tools,
              {
                type: input.name,
              },
            ]);
          } else {
            props.change(
              "tools",
              tools.filter((t) => t.type !== input.name),
            );

            const resources = toolResources;
            delete resources[input.name];

            props.change("tool_resources", resources);
          }
        }}
        icons={false}
        id={input.name}
        className="toggle-sm"
      />
      <div className="ms-2">
        <label htmlFor={input.name} className="mb-0 d-block tx-14">
          <div className="text-dark fw-bolder">{label}</div>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    tools: selector(state, "tools"),
    toolResources: selector(state, "tool_resources"),
  };
};

const WrappedModal = connect(mapStateToProps)(AssistantModal);

const RenderRange = ({
  input,
  label,
  meta,
  ...rest
}: WrappedFieldProps & { label: React.ReactNode }) => {
  const { touched, error, warning } = meta;

  return (
    <div>
      <div className="d-flex align-items-center">
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
        </label>
        <p className="mb-0 ms-auto text-dark tx-12">{input.value}</p>
      </div>
      <input {...input} {...rest} className="w-100" />
      {touched && error && <span className="parsley-errors-list">{error}</span>}

      {touched && warning && <span className="tx-warning">{warning}</span>}
    </div>
  );
};

export default OpenAiAssistants;
