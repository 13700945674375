import { DropdownItem } from "reactstrap";
import { FiMoreVertical } from "react-icons/fi";
import ActionButtonDropdown from "../../utils/ActionButtonDropdown";
import { Link } from "react-router-dom";
import TestStatusDump from "./TestStatusDump";
import SampleDumpModal from "./SampleDumpModal";
import GenerateReportModal from "./GenerateReportModal";

const SampleListActionDropdown = (props: any) => {
  const dropdownItems = [
    {
      key: "header",
      content: (
        <DropdownItem className="px-2" header>
          Sample List Actions
        </DropdownItem>
      ),
    },
    {
      key: "report",
      content: (
        <DropdownItem className="text-secondary">
          <GenerateReportModal />
        </DropdownItem>
      ),
    },
    {
      key: "sample-dump",
      content: (
        <DropdownItem className="text-secondary">
          <SampleDumpModal filters={props.filter} />
        </DropdownItem>
      ),
    },
    {
      key: "test-status",
      content: (
        <DropdownItem className="text-secondary">
          <TestStatusDump />
        </DropdownItem>
      ),
    },
    {
      key: "moistures",
      content: (
        <DropdownItem className="text-secondary">
          <Link className="text-secondary" to="/moistures">
            <i className="fa fa-link text-secondary" /> Moistures (beta)
          </Link>
        </DropdownItem>
      ),
    },
    {
      key: "test-dashboard",
      content: (
        <DropdownItem className="text-secondary">
          <Link className="text-secondary" to="/test-dashboard">
            <i className="fa fa-link text-secondary" /> Testing Dashboard (beta)
          </Link>
        </DropdownItem>
      ),
    },
  ];

  return (
    <ActionButtonDropdown
      dropdownItems={dropdownItems.map((item) => item.content)}
      toggleButtonIcon={<FiMoreVertical className="tx-18 text-secondary" />}
    />
  );
};

export default SampleListActionDropdown;
