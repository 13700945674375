import { useEffect } from "react";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import useApi from "../api/useApi";
import { findAndReplace, remove } from "../utils/arrayUtils";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import AddClientContactToProject from "./AddClientContactToProject";
import ProjectContactForm from "./Form";
import ProjectContactList from "./List";

const Contacts = (props) => {
  const { project, setProject } = props;

  const {
    data: contacts,
    setData: setContacts,
    loading,
    response,
    takeAction,
  } = useApi(`projects/${project.uuid}/contacts`, [], true);

  const { data: clientContacts, setData: setClientContacts } = useApi(
    `clients/${project.client.uuid}/contacts`,
    [],
  );

  const projectContactIds = contacts.map((contact) => contact.contact_id);

  const filteredContacts = filterContacts(clientContacts, projectContactIds);

  useEffect(() => {
    setProject({
      ...project,
      contacts,
    });
  }, [contacts]);

  const onSubmit = (values, dispatch) => {
    return takeAction("store", "project-contacts", {
      ...values,
      project_id: project.id,
    })
      .then(({ data }) => {
        setContacts([...contacts, data.data]);
        toast.success("Contact Added");
        dispatch(reset("ProjectContacts"));
      })
      .catch(errorSwal);
  };

  const onDelete = (contact) => {
    return deleteSwal()
      .then(() => takeAction("destroy", `project-contacts/${contact.uuid}`))
      .then(() => {
        setContacts(remove("uuid", contacts, contact));
        toast.success(`${contact.name} deleted.`);
      })
      .catch(errorSwal);
  };

  const onUpdate = (contact, data) => {
    return takeAction("update", `project-contacts/${contact.uuid}`, data)
      .then(({ data }) => {
        setContacts(findAndReplace("uuid", contacts, data.data));
        toast.success(`${contact.name} updated.`);
      })
      .catch(errorSwal);
  };

  if (loading && !response) {
    return <CustomScaleLoader>Fetching Project Contacts...</CustomScaleLoader>;
  }

  return (
    <>
      <div className="bg-white border position-relative mt-3">
        {loading && (
          <div
            style={{ zIndex: 10 }}
            className="position-absolute w-100 h-100  z-10 d-flex align-items-center justify-content-center bg-black-3"
          >
            <div>
              <CustomScaleLoader>Loading...</CustomScaleLoader>
            </div>
          </div>
        )}
        <div className="bg-gray-100 p-3 border-bottom">
          <h5 className="tx-inverse mb-0">Contacts</h5>
          <small>Contacts for {project.number}</small>
        </div>

        <ProjectContactForm contacts={filteredContacts} onSubmit={onSubmit} />
        <ProjectContactList
          contacts={contacts}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
        <AddClientContactToProject
          setContacts={setContacts}
          project={project}
          clientContacts={clientContacts}
          contacts={contacts}
          setClientContacts={setClientContacts}
        />
      </div>
    </>
  );
};

const filterContacts = (contacts, projectContactIds) => {
  return contacts.reduce((carry, contact) => {
    if (!projectContactIds.includes(contact.id)) {
      carry.push({
        value: contact.id,
        label: contact.name,
      });
    }
    return carry;
  }, []);
};

export default Contacts;
