import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Avatar from "../utils/Avatar";
import { BootstrapColour } from "../utils/utilTypes";

interface PaginationCardHeaderProps {
  colour: BootstrapColour;
  title: string;
  link: string;
  subTitle?: string | React.ReactNode;
  uuid: string;
}

const PaginationCardHeader = (props: PaginationCardHeaderProps) => {
  const { colour, title, link, subTitle, uuid } = props;
  const tooltipId = `tt_${uuid}`;

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={title} colour={colour} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={link}>{title}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {title}
          </UncontrolledTooltip>
          {subTitle ? <p className="mb-0">{subTitle}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default PaginationCardHeader;
