import {
  Field,
  FormSubmitHandler,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import INVOICE_TERMS from "../../data/invoice_terms";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import FormErrorAlert from "../form/FormErrorAlert";
import formError from "../utils/formError";
import Spinner from "../utils/Spinner";
import { IUseApiWithData } from "../api/apiTypes";
import { connect } from "react-redux";

interface AccountsSettings {
  accounts: {
    invoice_terms: number;
    invoice_terms_amount: number;
    supplier_invoice_terms: number;
    supplier_invoice_terms_amount: number;
  };
}

const Settings = (props: AccountsSettings) => {
  const { data, loading }: IUseApiWithData<AccountsSettings["accounts"]> =
    useApi("accounts/settings", {}, true);

  return (
    <>
      <HeaderPageAdminFilter titlePage="Accounts Settings" crumbs={[]} />
      <Spinner loading={loading} />

      {!loading && (
        <WrappedForm
          initialValues={{
            accounts: {
              invoice_terms: data.invoice_terms,
              invoice_terms_amount: data.invoice_terms_amount,
              supplier_invoice_terms: data.supplier_invoice_terms,
              supplier_invoice_terms_amount: data.supplier_invoice_terms_amount,
            },
          }}
          accounts={props.accounts}
        />
      )}
    </>
  );
};

const SettingsForm = (
  props: InjectedFormProps<AccountsSettings, AccountsSettings> &
    AccountsSettings,
) => {
  const { handleSubmit, accounts, change } = props;

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<AccountsSettings, AccountsSettings> = (
    values,
  ) => {
    return takeAction("update", "accounts/settings", values)
      .then(() => {
        toast.success("Accounts Settings Updated");
      })
      .catch(formError);
  };

  const isClientCOD = accounts.invoice_terms == 0;
  const isSupplierCOD = accounts.supplier_invoice_terms == 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <FormHeader>Default Client Account Settings</FormHeader>
        <div className={`${isClientCOD ? "col-lg-12" : "col-lg-6"} form-group`}>
          <Field
            component={SelectInput}
            name="accounts.invoice_terms"
            label="Invoice Terms"
            options={INVOICE_TERMS}
            validate={required}
            required
            changeValue={({ value }: { value: string }) => {
              if (value == "0") {
                change("accounts.invoice_terms_amount", null);
              }
            }}
          />
        </div>
        {isClientCOD ? null : (
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="accounts.invoice_terms_amount"
              label="Days"
              validate={required}
              required
            />
          </div>
        )}
        <FormHeader>Default Supplier Account Settings</FormHeader>
        <div
          className={`${isSupplierCOD ? "col-lg-12" : "col-lg-6"} form-group`}
        >
          <Field
            component={SelectInput}
            name="accounts.supplier_invoice_terms"
            label="Invoice Terms"
            options={INVOICE_TERMS}
            validate={required}
            changeValue={({ value }: { value: string }) => {
              if (value == "0") {
                change("accounts.supplier_invoice_terms_amount", null);
              }
            }}
            required
          />
        </div>
        {isSupplierCOD ? null : (
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="accounts.supplier_invoice_terms_amount"
              label="Days"
              validate={required}
              required
            />
          </div>
        )}
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<AccountsSettings, AccountsSettings>({
  form: "AccountsSettings",
});

const WrappedForm = form(SettingsForm);

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("AccountsSettings");
  return {
    accounts: selector(state, "accounts"),
  };
};

export default connect(mapStateToProps, {})(Settings);
