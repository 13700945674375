import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Tender } from "./tenderTypes";
import ReactTable from "../table/ReactTable";
import { Link } from "react-router-dom";
import { getStatus } from "./TendersList";

const TenderTable = ({ tenders }: { tenders: Tender[] }) => {
  const columnHelper = createColumnHelper<Tender>();

  const columns = useMemo<ColumnDef<Tender, any>[]>(
    () => [
      columnHelper.accessor("job.name", {
        header: "Name",
        cell: (info) => {
          const tender = info.row.original;
          return (
            <div>
              <Link
                className="text-dark"
                to={`/tenders/${tender.uuid}/details`}
              >
                {info.getValue()}
              </Link>
              <Link
                className="text-muted d-block"
                to={`/${tender.project?.link}`}
              >
                {tender.project?.name}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor("type.name", {
        header: "Type",
      }),
      columnHelper.accessor("client_name", {
        header: "Client",
        cell: (info) => {
          const client = info.row.original.client;

          if (info.getValue()) {
            return info.getValue();
          }

          return (
            <Link className="text-muted" to={`clients/${client?.uuid}/details`}>
              {client?.name}
            </Link>
          );
        },
      }),
      columnHelper.display({
        id: "status",
        header: "Status",
        cell: (info) => {
          const statusDetails = getStatus(info.row.original);

          return (
            <div
              className={`rounded-pill shadow bg-${statusDetails.colour} d-inline-block text-white tx-10 text-center px-3 no-wrap`}
            >
              {statusDetails.status}
            </div>
          );
        },
      }),
    ],
    [],
  );

  return <ReactTable<Tender> disableSearch data={tenders} columns={columns} />;
};

export default TenderTable;
