import { Field, InjectedFormProps, reduxForm } from "redux-form";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import { Productivity } from "./projectTypes";

interface BulkUpdateProductivityFormProps {
  refreshData: () => void;
  projectJobs: any;
  selectedRows: Productivity[];
  setSelectedRows: (rows: Productivity[]) => void;
}

const BulkUpdateProductivityForm = (
  props: InjectedFormProps<{}, BulkUpdateProductivityFormProps> &
    BulkUpdateProductivityFormProps,
) => {
  const { handleSubmit, refreshData, selectedRows, projectJobs } = props;
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `bulk-update-productivity`, {
      productivities: selectedRows.map((row: Productivity) => row.id),
      ...values,
    }).then(() => {
      refreshData();
      toast.success("Selected rows have been successfully updated!");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            options={projectJobs.map((projectJob: any) => ({
              label: projectJob.name,
              value: projectJob.id,
            }))}
            name={`job_id`}
            validate={required}
            required
            label="Select Job"
          />
        </div>

        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<{}, BulkUpdateProductivityFormProps>({
  form: "BulkUpdateProductivityForm",
});

export default form(BulkUpdateProductivityForm);
