import dayjs from "dayjs";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import NoMatch from "./404";
import CreateError from "./CreateError";
import AddBillableItems from "./billableItems/AddBillableItem";
import ListBillableItems from "./billableItems/BillableItemsList";
import EditBillableItems from "./billableItems/EditBillableItem";
import AddBillableItemUnits from "./billableItemUnits/AddBillableItemUnit";
import ListBillableItemUnits from "./billableItemUnits/BillableItemUnitList";
import EditBillableItemUnits from "./billableItemUnits/EditBillableItemUnit";
import addBranch from "./branches/Add";
import BranchCustomFields from "./branches/BranchCustomFields";
import BranchesList from "./branches/BranchesList";
import BranchProfile from "./branches/BranchProfile";
import ProfitAndLoss from "./branches/ProfitAndLoss";
import AddBrandingTheme from "./brandingThemes/AddBrandingTheme";
import BrandingThemeList from "./brandingThemes/BrandingThemeList";
import BrandingThemeProfile from "./brandingThemes/BrandingThemeProfile";

import BudgetProfile from "./budgets/BudgetProfile";
import AddBudgetTemplate from "./budgetTemplates/Add";
import BudgetTemplateList from "./budgetTemplates/List";
import BudgetTemplateProfile from "./budgetTemplates/Profile";
import CalibrationsDue from "./calibrations/CalibrationsDue";
import CalibrationProfile from "./calibrations/Profile";
import CalibrationUnits from "./calibrationUnits/add";
import ChartOfAccounts from "./chartOfAccounts/ChartOfAccounts";
import OrganisationPositionChart from "./charts/OrganisationPositionChart";
import AddClientFeedbackTemplate from "./clientFeedback/AddClientFeedbackTemplate";
import ClientFeedbackTemplateProfile from "./clientFeedback/ClientFeedbackTemplateProfile";
import ClientFeedbackTemplatesList from "./clientFeedback/ClientFeedbackTemplates";
import addClient from "./clients/Add";
import listClients from "./clients/ClientsList";
import clientProfile from "./clients/Profile";
import ClientTypesList from "./clientTypes/ClientTypesList";
import ListCPIRCategories from "./CPIRCategories/List";
import AddCPIR from "./CPIRs/Add";
import CPIRList from "./CPIRs/CpirList";
import CPIRDashboard from "./CPIRs/Profile";
import AddCpirType from "./cpirTypes/AddCpirType";
import CpirTypeProfile from "./cpirTypes/CpirTypeProfile";
import CpirTypesList from "./cpirTypes/CpirTypesList";
import CreditCardReceiptProfile from "./creditCardReceipts/Profile";
import AddCustomJob from "./customJobs/Add";
import CustomJobs from "./customJobs/CustomJobs";
import CustomJobJobProfile from "./customJobs/jobs/Profile";
import CustomJobProfile from "./customJobs/Profile";
import ManagementDashboard from "./dashboard/ManagementDashboard";
import AddDeduction from "./deductions/Add";
import DeductionProfile from "./deductions/Profile";
import DeliverableProfile from "./deliverables/Profile";
import DirectReports from "./directReports/DirectReports";
import listDivisions from "./divisions/list";
import AddDocket from "./dockets/Add";
import DocketsList from "./dockets/DocketsList";
import DocketProfile from "./dockets/Profile";
import AddDocketType from "./docketTypes/AddDocketType";
import DocketTypeList from "./docketTypes/DocketTypeList";
import DocketTypeProfile from "./docketTypes/DocketTypeProfile";
import Documents from "./documents/Documents";
import AddEmailTemplate from "./emailTemplates/Add";
import EditEmailTemplate from "./emailTemplates/Edit";
import EmailTemplates from "./emailTemplates/EmailTemplates";
import AddEmploymentAward from "./employmentAwards/AddEmploymentAward";
import EmploymentAwardProfile from "./employmentAwards/EmploymentAwardProfile";
import EmploymentAwardsList from "./employmentAwards/EmploymentAwardsList";
import AddEmploymentDetails from "./employmentDetails/Add";
import EmploymentDetailsList from "./employmentDetails/EmploymentDetailsList";
import EmploymentDetailsProfile from "./employmentDetails/EmploymentDetailsProfile";
import AddEquipment from "./equipment/AddEquipment";
import EquipmentCustomFields from "./equipment/EquipmentCustomFields";
import EquipmentList from "./equipment/EquipmentList";
import EquipmentUpload from "./equipment/EquipmentUploadPage";
import EquipmentProfile from "./equipment/Profile";
import EquipmentCalibrationTypeProfile from "./equipmentCalibrationTypes/Profile";
import EquipmentTypeGroupList from "./equipmentTypeGroups/List";
import addEquipmentType from "./equipmentTypes/Add";
import EquipmentTypes from "./equipmentTypes/List";
import EquipmentTypeProfile from "./equipmentTypes/Profile";
import AddExpenseClaim from "./expenseClaims/Add";
import ExpenseClaimProfile from "./expenseClaims/Profile";
import GroupList from "./groups/GroupList";
import GroupUserList from "./groupUsers/GroupUserList";
import Home from "./home";
import ComplianceMetricsPage from "./home/ComplianceMetricsPage";
import Industries from "./industries/Industries";
import TheSiteClassifierApp from "./integrations/TheSiteClassifierApp/TheSiteClassifierApp";
import TheTestingApp from "./integrations/theTestingApp/TheTestingApp";
import InvoicesList from "./invoices/InvoicesList";
import InvoiceProfile from "./invoices/Profile";
import InvoiceReminders from "./invoices/reminders/InvoiceReminders";
import addJobType from "./jobs/jobTypes/Add";
import editJobType from "./jobs/jobTypes/Edit";
import listJobTypes from "./jobs/jobTypes/List";
import JobProfile from "./jobs/Profile";
import AddJob from "./jobs/register/AddJob";
import JobsList from "./jobs/register/JobsList";
import JobTypeRouter from "./jobs/standardJobTypes/index";
import Laboratories from "./laboratories";
import RequestLeave from "./leave/Request";
import RequestedLeaveProfile from "./leave/RequestedLeaveProfile";
import LeaveReasons from "./leaveReasons";
import MachineDayRateProfile from "./machineDayRates/Profile";
import NewManagementDashboard from "./managementDashboard/ManagementDashboard";
import MicrosoftIntegrationProfile from "./microsoft/MicrosoftIntegrationProfile";
import MicrosoftRedirect from "./microsoft/Redirects";
import MoistureContainers from "./moistureContainers/MoistureContainers";
import MoisturesList from "./moistures/MoisturesList";
import NotificationsDashboard from "./notifications/NotificationsDashboard";
import AddToExistingUser from "./organisation/AddToExistingUser";
import OrganisationProfile from "./organisation/OrganisationProfile";
import SelectOrganisation from "./organisation/SelectOrganisation";
import AddOrganisationRole from "./organisationRoles/Add";
import ListOrganisationRoles from "./organisationRoles/List";
import OrganisationRoleProfile from "./organisationRoles/Profile";
import AddPayRun from "./payRuns/add";
import ListPayRun from "./payRuns/list";
import ProfilePayRun from "./payRuns/Profile";
import AddPenaltyRate from "./penaltyRates/AddPenaltyRate";
import PenaltyRateProfile from "./penaltyRates/PenaltyRateProfile";
import PenaltyRatesList from "./penaltyRates/PenaltyRatesList";
import AddPermissionGroup from "./permissionGroups/AddPermissionGroup";
import PermissionGroupList from "./permissionGroups/PermissionGroupList";
import PermissionGroupProfile from "./permissionGroups/PermissionGroupProfile";
import addPosition from "./positions/Add";
import listPositions from "./positions/List";
import PositionProfile from "./positions/Profile";
import StandardPricing from "./prices/StandardPricing";
import ProjectManagerDashboard from "./projectManagers/ProjectManagerDashboard";
import AddProject from "./projects/Add";
import ProjectProfile from "./projects/Profile";
import ProjectList from "./projects/ProjectsList";
import AddPublicHoliday from "./publicHolidays/Add";
import EditPublicHoliday from "./publicHolidays/Edit";
import PublicHolidays from "./publicHolidays/PublicHolidays";
import PurchaseOrderProfile from "./purchaseOrders/Profile";
import PurchaseItemOverBudget from "./purchases/PurchaseItemOverBudget";
import PurchaseList from "./purchases/PurchasesList";
import AddQualification from "./qualifications/Add";
import EditQualification from "./qualifications/Edit";
import QualificationList from "./qualifications/List";
import RecurringJobProfile from "./recurringJobs/RecurringJobProfile";
import RecurringPaymentPurchaseProfile from "./recurringPaymentPurchases/Profile";
import RecurringPurchaseProfile from "./recurringPurchases/Profile";
import RecurringPurchasesList from "./recurringPurchases/RecurringPurchasesList";
import AddRegisterItem from "./registerItems/AddRegisterItem";
import RegisterItemList from "./registerItems/RegisterItemList";
import RegisterItemProfile from "./registerItems/RegisterItemProfile";
import AddRegister from "./registers/AddRegister";
import RegisterProfile from "./registers/RegisterProfile";
import RegistersList from "./registers/RegistersList";
import RequiredProjectInformation from "./requiredProjectInformation";
import AddRequiredProjectInformation from "./requiredProjectInformation/Add";
import RequiredProjectInformationProfile from "./requiredProjectInformation/Profile";
import Schedule from "./schedule";
import addResidential from "./siteInspections/residential/AddResidentialSiteInspection";
import AddSkillCategory from "./skillCategories/Add";
import SkillCategoriesList from "./skillCategories/SkillCategoriesList";
import SkillCategoryProfile from "./skillCategories/Profile";
import SkillLevelProfile from "./skillLevels/Profile";
import StaffCompetencyProfile from "./staffCompetencies/Profile";
import Matrix from "./staffMatrix/Matrix";
import Subject from "./subjects/Subject";
import SuperUser from "./superUsers";
import SupplierProjects from "./supplierProjects/Profile";
import AddSupplier from "./suppliers/Add";
import SuppliersList from "./suppliers/SuppliersList";
import SupplierProfile from "./suppliers/Profile";
import SupplierTypeList from "./supplierTypes/List";
import SupplierTypeProfile from "./supplierTypes/Profile";
import SupportRequestProfile from "./supportDashboard/SupportRequestProfile";
import SupportRequests from "./supportDashboard/SupportRequests";
import TemplateDocumentProfile from "./templateDocuments/TemplateDocumentProfile";
import AddTemplate from "./templates/Add";
import TemplateList from "./templates/List";
import TemplateProfile from "./templates/Profile";
import TenderProfile from "./tenders/TenderProfile";
import TendersList from "./tenders/TendersList";
import TenderTypes from "./tenderTypes";
import TenderTypeProfile from "./tenderTypes/TypeProfile";
import addTestRequest from "./testRequests/Add";
import TestRequestDashboard from "./testRequests/Dashboard";
import PrintSampleLabels from "./testRequests/samples/PrintSampleLabels";
import TestDashboard from "./testRequests/TestDashboard";
import TestIndex from "./testRequests/tests/index";
import AddTestSuite from "./testSuites/AddTestSuite";
import TestSuiteList from "./testSuites/TestSuiteList";
import TestSuiteProfile from "./testSuites/TestSuiteProfile";
import TestSuiteStatusProfile from "./testSuites/testSuiteStatuses/TestSuiteStatusProfile";
import TestSuiteTestProfile from "./testSuites/testSuiteTests/TestSuiteTestProfile";
import ListTimesheetApprovalUser from "./timesheetApprovalUsers/List";
import TimesheetProfile from "./timesheets/TimesheetProfile";
import AddTopic from "./topics/AddTopic";
import TopicProfile from "./topics/TopicProfile";
import TopicsList from "./topics/TopicsList";
import TrackingCategories from "./trackingCategories";
import AddTrackingSite from "./trackingSites/Add";
import EditTrackingSite from "./trackingSites/Edit";
import TrackingSites from "./trackingSites/List";
import UserInvite from "./user/Invite";
import UserList from "./user/List";
import Profile from "./user/Profile";
import UserDetail from "./user/UserDetail";
import BranchJobs from "./userJobs/BranchJobs";
import JobManagerJobList from "./userJobs/JobManagerJobList";
import DateFormatTable from "./utils/DateFormatTable";
import SwitchOrganisation from "./utils/SwitchOrganisation";
import IntegrationSuccess from "./xero/IntegrationSuccess";
import TwoFactorReminder from "./login/TwoFactorReminder";
import useUserGroup from "./hooks/useUserGroup";
import JobOverviewDashboard from "./dashboard/JobOverviewDashboard";
import XeroIntegrationProfile from "./xero/XeroIntegrationProfile";
import XeroPayrollSuccess from "./integrations/xeroPayroll/XeroPayrollSuccess";
import XeroPayrollProfile from "./integrations/xeroPayroll/XeroPayrollProfile";
import SafetyInitiatives from "./hazardReports/SafetyInitiatives";
import HazardReportOverview from "./hazardReports/HazardReportOverview";
import AddHazardReport from "./hazardReports/AddHazardReport";
import Todos from "./todos/Todos";
import AddGroup from "./groups/AddGroup";
import LoginConsent from "./auth/LoginConsent";
import Noticeboard from "./noticeboards/Noticeboard";
import AnalysedInvoice from "./purchaseOrders/AnalysedInvoice";
import UserMfaList from "./user/UserMfaList";
import AddTender from "./tenders/Add";
import ProjectForms from "./projectForms/ProjectForms";
import AddProjectForm from "./projectForms/Add";
import ProjectFormProfile from "./projectForms/Profile";
import ProjectTypes from "./projectTypes/ProjectTypes";
import AddProjectTypes from "./projectTypes/Add";
import ProjectTypeProfile from "./projectTypes/Profile";
import TestWorksheetList from "./testWorksheets/TestWorksheetList";
import AddTestWorksheet from "./testWorksheets/AddTestWorksheet";
import TestWorksheetProfile from "./testWorksheets/TestWorksheetProfile";
import ProjectFormJobProfile from "./projectForms/jobs/Profile";
import EmploymentHeroRedirect from "./integrations/EmploymentHero/EmploymentHeroRedirect";
import EmploymentHeroIntegrationProfile from "./employmentHero/EmploymentHeroIntegrationProfile";
import InboundAccountsPayableList from "./inboundAccountsPayable/InboundAccountsPayableList";
import InboundAccountsPayableProfile from "./inboundAccountsPayable/InboundAccountsPayableProfile";
import NewUserForm from "./user/NewUserForm";
import DeductionsList from "./deductions/DeductionsList";
import AllowanceTypesList from "./allowanceTypes/AllowanceTypesList";
import SafetyInitiativeAdd from "./hazardReports/Add";
import SafetyInitiativeProfile from "./hazardReports/Profile";
import PerformanceReviewTemplatesList from "./performanceReviews/PerformanceReviewTemplateList";
import AddPerformanceReviewTemplate from "./performanceReviews/AddPerformanceReviewTemplate";
import PerformanceReviewTemplateProfile from "./performanceReviews/PerformanceReviewTemplateProfile";
import PerformanceReviewProfile from "./performanceReviews/PerformanceReviewProfile";
import UserCreditCards from "./userCreditCards/UserCreditCardsList";
import UploadCreditCardReceipts from "./creditCardReceipts/UploadCreditCardReceipts";
import TicketTypeProfile from "./ticketTypes/Profile";
import TicketTypes from "./ticketTypes/List";
import AddTicketType from "./ticketTypes/Add";
import SupportTicketOverview from "./supportTickets/Overview";
import OpenAIProfile from "./openAi/OpenAIProfile";
import ForceTimesheets from "./timesheets/ForceTimesheets";
import InterEntityInvoiceProfile from "./invoices/interEntity/InterEntityInvoiceProfile";
import ExpectedTestTimeList from "./expectedTestTimes/ExpectedTestTimeList";
import ExpectedTestTimeProfile from "./expectedTestTimes/ExpectedTestTimeProfile";
import NewSampleList from "./testRequests/samples/NewSampleList";
import ExternalTestRequestTemplateList from "./externalTestRequestTemplates/ExternalTestRequestTemplateList";
import OpportunitiesList from "./opportunities/OpportunitiesList";
import AddOpportunity from "./opportunities/AddOpportunity";
import ServiceList from "./services/ServiceList";
import OpportunityProfile from "./opportunities/OpportunityProfile";
import Settings from "./accounts/Settings";

const Main = () => {
  const { user } = useAuth();

  const isAccounting = useUserGroup("Accounting");

  const isPositionAdmin = user.is_position_admin;

  if (user.timesheet_required) {
    return (
      <main>
        <Switch>
          <Route
            exact
            path="/organisations/select"
            component={SelectOrganisation}
          />
          <Route component={ForceTimesheets} />
        </Switch>
      </main>
    );
  }

  return (
    <main>
      <Switch>
        <Route path="/noticeboard" exact component={Noticeboard} />
        <Route path="/home/management" exact component={ManagementDashboard} />
        <Route
          path="/home/dashboard"
          exact
          component={NewManagementDashboard}
        />

        <Route path="/" exact component={Home} />

        <Route path="/home" exact component={Home} />

        <Route
          path="/organisation-chart"
          exact
          component={OrganisationPositionChart}
        />

        <Route path="/branch-jobs" exact component={BranchJobs} />

        <Route
          path="/project-manager/:managerUuid?"
          exact
          component={ProjectManagerDashboard}
        />

        {/* Organisation Project form & Project Type */}
        <Route exact path="/project-forms" component={ProjectForms} />
        <Route exact path="/project-forms/add" component={AddProjectForm} />
        <Route
          exact
          path="/project-forms/:uuid/:page"
          component={ProjectFormProfile}
        />

        <Route exact path="/project-types" component={ProjectTypes} />
        <Route exact path="/project-types/add" component={AddProjectTypes} />
        <Route
          exact
          path="/project-types/:uuid/:page"
          component={ProjectTypeProfile}
        />

        {/* Job Manager */}
        <Route path="/job-manager" exact component={JobManagerJobList} />

        <Route
          exact
          path="/jobs/add/:projectUuid/:jobType"
          component={JobTypeRouter}
        />

        <Route
          exact
          path="/jobs/add/:projectUuid/standard/:jobTypeId/:model"
          component={AddJob}
          initialValues={{
            branch_id: user.default_branch_id,
            project_manager: user.id,
            scheduled_start_date: dayjs().format("YYYY-MM-DD"),
          }}
        />

        <Route
          path="/jobs/:jobUuid/:page/:folderUuid?"
          component={JobProfile}
        />

        <Route
          exact
          path="/projects/:number/:page/:folderUuid?/:jobFolderUuid?"
          component={ProjectProfile}
        />

        <Route
          path="/projects/:number/jobs/:jobUuid/:page/:folderUuid?"
          component={JobProfile}
        />

        <Route exact path="/projects/add" component={AddProject} />
        <Route exact path="/projects" component={ProjectList} />

        {/* Users */}

        <Route exact path="/users/:id/leave/request" component={RequestLeave} />

        <Route exact path="/users/invite" component={UserInvite} />

        <Route exact path="/users/invite/add" component={NewUserForm} />

        <Route path="/users/:userId/:page/:folderUuid?" component={Profile} />
        <Route exact path="/profile/:number" component={UserDetail} />
        <Route exact path="/users/:number" component={UserDetail} />
        <Route exact path="/users" component={UserList} />

        <Route
          exact
          path="/requested-leave/:uuid/:page/:folderUuid?"
          component={RequestedLeaveProfile}
        />

        {/* Organisations */}
        <Route
          exact
          path="/organisations/select"
          component={SelectOrganisation}
        />
        <Route
          path="/organisations/settings/:page/:folderUuid?"
          component={user.is_admin ? OrganisationProfile : NoMatch}
        />

        <Route exact path="/accounts/settings" component={Settings} />

        {/* Documents */}
        <Route exact path="/documents/:uuid?" component={Documents} />

        {/* Equipment */}
        <Route exact path="/equipment" component={EquipmentList} />
        <Route
          exact
          path="/equipment/:uuid/:page/:folderUuid?"
          component={EquipmentProfile}
        />
        <Route
          exact
          path="/equipment/upcomingcalibrations"
          component={CalibrationsDue}
        />
        <Route exact path="/equipment/add" component={AddEquipment} />

        {/* Tracking Sites */}
        <Route exact path="/trackingsites" component={TrackingSites} />
        <Route exact path="/trackingsites/add" component={AddTrackingSite} />
        <Route exact path="/trackingsites/:uuid" component={EditTrackingSite} />

        {/* Equipment Types */}
        <Route
          exact
          path="/equipment-types/:uuid/:page/:folderUuid?"
          component={EquipmentTypeProfile}
        />
        <Route exact path="/equipment-types" component={EquipmentTypes} />
        <Route exact path="/equipment-types/add" component={addEquipmentType} />

        {/* Organisation Divisions */}
        <Route exact path="/divisions" component={listDivisions} />
        <Route exact path="/divisions/:uuid" component={listDivisions} />

        {/* Organisation Branches */}
        <Route exact path="/branches" component={BranchesList} />
        <Route exact path="/branches/add" component={addBranch} />
        <Route
          exact
          path="/branches/:uuid/profit-loss"
          component={ProfitAndLoss}
        />
        <Route exact path="/branches/:uuid/:page" component={BranchProfile} />

        {/* Organisation Positions */}
        <Route exact path="/positions" component={listPositions} />
        <Route exact path="/positions/add" component={addPosition} />
        <Route
          exact
          path="/positions/:uuid/:page"
          component={PositionProfile}
        />

        {/* Clients */}
        <Route
          exact
          path="/clients"
          component={isPositionAdmin ? listClients : NoMatch}
        />
        <Route
          exact
          path="/clients/add"
          component={isPositionAdmin ? addClient : NoMatch}
        />
        <Route
          exact
          path="/clients/:uuid/:page/:folderUuid?"
          component={isPositionAdmin ? clientProfile : NoMatch}
        />

        <Route
          exact
          path="/supplier-projects/:uuid/:page"
          component={SupplierProjects}
        />
        <Route exact path="/suppliers" component={SuppliersList} />

        <Route
          exact
          path="/suppliers/:uuid/:page/:folderUuid?"
          component={SupplierProfile}
        />

        {/* Organisation Job Types */}
        <Route exact path="/jobtypes" component={listJobTypes} />
        <Route exact path="/jobtypes/add" component={addJobType} />
        <Route exact path="/jobtypes/:uuid" component={editJobType} />

        <Route exact path="/jobs" component={JobsList} />

        {/* Organisation Roles */}
        <Route
          exact
          path="/organisation-roles"
          component={ListOrganisationRoles}
        />
        <Route
          exact
          path="/organisation-roles/add"
          component={AddOrganisationRole}
        />
        <Route
          exact
          path="/organisation-roles/:uuid/:page"
          component={OrganisationRoleProfile}
        />

        {/* Staff Roles */}
        <Route
          exact
          path="/employment-details"
          component={isPositionAdmin ? EmploymentDetailsList : NoMatch}
        />
        <Route
          exact
          path="/employment-details/add"
          component={AddEmploymentDetails}
        />
        <Route
          exact
          path="/employment-details/:uuid/:page"
          component={EmploymentDetailsProfile}
        />

        <Route path="/tests/:testMethod/:test" component={TestIndex} />

        {/* Test request */}
        <Route exact path="/testrequests/add" component={addTestRequest} />
        <Route
          exact
          path="/testrequests/:uuid/:page/:folderUuid?"
          component={TestRequestDashboard}
        />

        {/* CPIRs */}
        <Route exact path="/cpirs" component={CPIRList} />
        <Route exact path="/cpirs/add" component={AddCPIR} />
        <Route
          exact
          path="/cpirs/:uuid/:page/:folderUuid?"
          component={CPIRDashboard}
        />

        {/* Notifications */}
        <Route
          exact
          path="/notifications/:page"
          component={NotificationsDashboard}
        />

        {/* Deliverables */}
        <Route
          exact
          path="/deliverables/:uuid/:page"
          component={DeliverableProfile}
        />

        <Route exact path="/samples" component={NewSampleList} />

        <Route exact path="/dockets" component={DocketsList} />
        <Route
          exact
          path="/dockets/add"
          component={AddDocket}
          initialValues={{ user_id: user.id }}
        />

        <Route exact path="/dockets/:uuid/:page" component={DocketProfile} />

        <Route
          exact
          path="/groups"
          component={user.is_admin ? GroupList : NoMatch}
        />
        <Route exact path="/groups/add" component={AddGroup} />

        <Route exact path="/groups/:uuid" component={GroupUserList} />

        <Route
          exact
          path="/siteinspections/residential/:uuid"
          component={addResidential}
          user={user}
        />

        <Route path="/billable-items/add" component={AddBillableItems} />
        <Route path="/billable-items/:uuid" component={EditBillableItems} />
        <Route path="/billable-items" component={ListBillableItems} />

        <Route path="/skill-categories/add" component={AddSkillCategory} />
        <Route
          path="/skill-categories/:uuid/:page"
          component={SkillCategoryProfile}
        />
        <Route path="/skill-categories" component={SkillCategoriesList} />
        <Route path="/cpir-categories" component={ListCPIRCategories} />

        <Route
          path="/billable-item-units/add"
          component={AddBillableItemUnits}
        />
        <Route
          path="/billable-item-units/:uuid"
          component={EditBillableItemUnits}
        />
        <Route path="/billable-item-units" component={ListBillableItemUnits} />

        <Route
          path="/timesheet-approval-users/"
          component={ListTimesheetApprovalUser}
        />

        <Route
          path="/timesheets/:uuid/:page/:folderUuid?"
          component={TimesheetProfile}
        />

        <Route
          path="/pay-runs/add"
          component={user.is_admin ? AddPayRun : NoMatch}
        />
        <Route path="/pay-runs/:uuid/:page" component={ProfilePayRun} />
        <Route
          path="/pay-runs"
          component={user.is_admin ? ListPayRun : NoMatch}
        />

        <Route exact path="/staff-matrix/users/:uuid?/:competency?">
          <Matrix page="users" />
        </Route>
        <Route exact path="/staff-matrix/skills/:uuid?/:competency?">
          <Matrix page="skills" />
        </Route>

        {/* Doing this as we've changed the URL */}
        <Route exact path="/staff-matrix/:uuid?/:competency?">
          {({ match }) => {
            return (
              <Redirect
                to={`/staff-matrix/users/${match?.params.uuid ?? ""}`}
              />
            );
          }}
        </Route>

        <Route
          path="/staff-competencies/:uuid"
          component={StaffCompetencyProfile}
        />

        <Route path="/standard-pricing" component={StandardPricing} />

        <Route
          path="/machine-day-rates/:uuid/:page/:folderUuid?"
          component={MachineDayRateProfile}
        />

        <Route path="/calibration-units" component={CalibrationUnits} />

        <Route path="/moisture-containers" component={MoistureContainers} />

        <Route path="/suppliers/add" component={AddSupplier} />

        <Route
          path="/invoices/:uuid/:page/:folderUuid?"
          component={InvoiceProfile}
        />

        <Route
          path="/inter-entity-invoices/:uuid/:page/:folderUuid?"
          component={InterEntityInvoiceProfile}
        />

        <Route
          path="/invoices"
          component={user.is_admin || isAccounting ? InvoicesList : NoMatch}
        />

        <Route path="/chart-of-accounts" component={ChartOfAccounts} />

        <Route path="/industries" component={Industries} />

        <Route
          path="/credit-card-receipts/:uuid/:page"
          component={CreditCardReceiptProfile}
        />

        <Route
          path="/recurring-purchases/:uuid/:page"
          component={RecurringPurchaseProfile}
        />

        <Route
          path="/contract-agreements"
          component={
            user.is_admin || isAccounting ? RecurringPurchasesList : NoMatch
          }
        />

        <Route
          path="/recurring-payment-purchases/:uuid/:page"
          component={RecurringPaymentPurchaseProfile}
        />

        <Route
          path="/purchases"
          component={user.is_admin || isAccounting ? PurchaseList : NoMatch}
        />

        <Route
          path="/purchase-orders/:uuid/:page/:folderUuid?"
          component={PurchaseOrderProfile}
        />

        <Route
          path="/tenders/:uuid/:page/:folderUuid?"
          component={TenderProfile}
        />
        <Route exact path="/tenders/add" component={AddTender} />

        <Route
          path="/calibrations/:uuid/:page"
          component={CalibrationProfile}
        />

        <Route path="/qualifications/add" component={AddQualification} />
        <Route path="/qualifications/:uuid" component={EditQualification} />
        <Route path="/qualifications" component={QualificationList} />

        <Route path="/templates/add" component={AddTemplate} />
        <Route path="/templates/:uuid/:page" component={TemplateProfile} />
        <Route path="/templates" component={TemplateList} />

        <Route path="/organisations/add" component={AddToExistingUser} />

        <Route path="/laboratories" component={Laboratories} />
        <Route path="/schedule" component={Schedule} />

        <Route path="/leave-reasons/:uuid" component={LeaveReasons} />

        <Route exact path="/leave-reasons" component={LeaveReasons} />
        <Route exact path="/leave/request" component={RequestLeave} />

        <Route
          exact
          path="/supplier-types/:uuid/:page"
          component={SupplierTypeProfile}
        />
        <Route exact path="/supplier-types" component={SupplierTypeList} />

        <Route
          exact
          path="/equipment-type-groups"
          component={EquipmentTypeGroupList}
        />

        <Route
          exact
          path="/xero-payroll/successful"
          component={XeroPayrollSuccess}
        />
        <Route
          exact
          path="/integrations/xero-payroll/:uuid/:page"
          component={XeroPayrollProfile}
        />
        <Route
          exact
          path="/xero-integration/:page"
          component={IntegrationSuccess}
        />
        <Route
          exact
          path="/required-project-information/:uuid/:page"
          component={RequiredProjectInformationProfile}
        />
        <Route
          exact
          path="/required-project-information"
          component={RequiredProjectInformation}
        />

        <Route
          exact
          path="/required-project-information/add"
          component={AddRequiredProjectInformation}
        />

        <Route
          exact
          path="/custom-jobs/jobs/:uuid/:page/:folderUuid?"
          component={CustomJobJobProfile}
        />

        <Route
          exact
          path="/project-forms/jobs/:uuid/:page/:folderUuid?"
          component={ProjectFormJobProfile}
        />

        <Route
          exact
          path="/custom-jobs/:uuid/:page"
          component={CustomJobProfile}
        />

        <Route exact path="/custom-jobs" component={CustomJobs} />

        <Route exact path="/custom-jobs/add" component={AddCustomJob} />

        <Route
          exact
          path="/equipment-calibration-types/:uuid/:page"
          component={EquipmentCalibrationTypeProfile}
        />
        <Route
          exact
          path="/tracking-categories"
          component={TrackingCategories}
        />

        <Route exact path="/deductions/add" component={AddDeduction} />
        <Route exact path="/deductions" component={DeductionsList} />

        <Route exact path="/expense-claims/add" component={AddExpenseClaim} />

        <Route
          exact
          path="/expense-claims/:uuid/:page/:folderUuid?"
          component={ExpenseClaimProfile}
        />

        <Route
          exact
          path="/deductions/:uuid/:page/:folderUuid?"
          component={DeductionProfile}
        />

        <Route
          exact
          path="/skill-levels/:uuid/:page/:folderUuid?"
          component={SkillLevelProfile}
        />

        <Route exact path="/tender-types" component={TenderTypes} />

        <Route
          exact
          path="/tender-types/:uuid/:page"
          component={TenderTypeProfile}
        />

        <Route exact path="/super-user" component={SuperUser} />

        <Route
          exact
          path="/integrations/the-site-classifier-app"
          component={TheSiteClassifierApp}
        />
        <Route
          exact
          path="/integrations/the-testing-app"
          component={TheTestingApp}
        />

        <Route exact path="/email-templates/add" component={AddEmailTemplate} />
        <Route
          exact
          path="/email-templates/:uuid"
          component={EditEmailTemplate}
        />
        <Route exact path="/email-templates" component={EmailTemplates} />

        <Route exact path="/public-holidays/add" component={AddPublicHoliday} />
        <Route
          exact
          path="/public-holidays/:uuid"
          component={EditPublicHoliday}
        />
        <Route exact path="/public-holidays" component={PublicHolidays} />

        <Route
          exact
          path="/budget-templates/add"
          component={AddBudgetTemplate}
        />
        <Route
          exact
          path="/budget-templates/:uuid/:page"
          component={BudgetTemplateProfile}
        />

        <Route exact path="/budget-templates" component={BudgetTemplateList} />

        <Route exact path="/budgets/:uuid" component={BudgetProfile} />

        <Route
          exact
          path="/purchase-items/:uuid"
          component={PurchaseItemOverBudget}
        />

        <Route exact path="/samples/labels" component={PrintSampleLabels} />

        <Route exact path="/date-format" component={DateFormatTable} />

        <Route
          exact
          path="/template-documents/:uuid/:page"
          component={TemplateDocumentProfile}
        />

        <Route
          exact
          path="/recurring-jobs/:uuid/:page/:folderUuid?"
          component={RecurringJobProfile}
        />

        <Route exact path="/topics/:uuid/:page" component={TopicProfile} />
        <Route exact path="/topics" component={TopicsList} />

        <Route exact path="/topics/add" component={AddTopic} />

        <Route exact path="/subjects/:uuid" component={Subject} />

        <Route
          exact
          path="/client-feedback-types"
          component={ClientFeedbackTemplatesList}
        />

        <Route
          exact
          path="/client-feedback-types/add"
          component={AddClientFeedbackTemplate}
        />

        <Route
          exact
          path="/client-feedback-types/:uuid/:page"
          component={ClientFeedbackTemplateProfile}
        />

        <Route exact path="/cpir-types" component={CpirTypesList} />
        <Route exact path="/cpir-types/add" component={AddCpirType} />
        <Route
          exact
          path="/cpir-types/:uuid/:page"
          component={CpirTypeProfile}
        />

        <Route exact path="/client-types" component={ClientTypesList} />

        <Route exact path="/penalty-rates" component={PenaltyRatesList} />
        <Route exact path="/penalty-rates/add" component={AddPenaltyRate} />
        <Route
          exact
          path="/penalty-rates/:uuid/:page/:folderUuid?"
          component={PenaltyRateProfile}
        />

        <Route
          exact
          path="/employment-awards"
          component={isPositionAdmin ? EmploymentAwardsList : NoMatch}
        />
        <Route
          exact
          path="/employment-awards/add"
          component={AddEmploymentAward}
        />
        <Route
          exact
          path="/employment-awards/:uuid/:page"
          component={EmploymentAwardProfile}
        />

        <Route exact path="/direct-reports" component={DirectReports} />

        <Route exact path="/docket-types" component={DocketTypeList} />
        <Route exact path="/docket-types/add" component={AddDocketType} />
        <Route
          exact
          path="/docket-types/:uuid/:page"
          component={DocketTypeProfile}
        />

        <Route exact path="/redirect/ms-graph" component={MicrosoftRedirect} />

        <Route
          exact
          path="/organisations/:id/switch"
          component={SwitchOrganisation}
        />

        <Route exact path="/test-suites" component={TestSuiteList} />

        <Route exact path="/test-suites/add" component={AddTestSuite} />
        <Route
          exact
          path="/test-suites/:uuid/:page"
          component={TestSuiteProfile}
        />

        <Route
          exact
          path="/test-suite-statuses/:uuid/:page"
          component={TestSuiteStatusProfile}
        />

        <Route
          exact
          path="/test-suite-tests/:uuid/:page"
          component={TestSuiteTestProfile}
        />

        <Route
          exact
          path="/integrations/microsoft/:uuid/:page"
          component={MicrosoftIntegrationProfile}
        />

        <Route
          exact
          path="/integrations/open-ai/:uuid/:page"
          component={OpenAIProfile}
        />

        <Route
          exact
          path="/integrations/xero/:uuid/:page"
          component={XeroIntegrationProfile}
        />

        <Route exact path="/branding-themes" component={BrandingThemeList} />
        <Route exact path="/branding-themes/add" component={AddBrandingTheme} />

        <Route
          exact
          path="/branding-themes/:uuid/:page"
          component={BrandingThemeProfile}
        />

        <Route
          exact
          path="/compliance-metrics"
          component={ComplianceMetricsPage}
        />
        <Route exact path="/moistures" component={MoisturesList} />

        <Route path="/analysed-invoice" exact component={AnalysedInvoice} />

        {/* <Route
          exact
          path="/upload-purchase-order"
          component={UploadPurchaseOrder}
        /> */}

        <Route exact path="/test-dashboard" component={TestDashboard} />

        <Route
          exact
          path="/branches/custom-fields"
          component={BranchCustomFields}
        />

        <Route
          exact
          path="/equipment/custom-fields"
          component={EquipmentCustomFields}
        />

        <Route exact path="/registers/add" component={AddRegister} />

        <Route exact path="/registers" component={RegistersList} />

        <Route
          exact
          path="/registers/:uuid/:page"
          component={RegisterProfile}
        />

        <Route exact path="/register-list/:uuid" component={RegisterItemList} />

        <Route
          exact
          path="/register-items/:uuid/add"
          component={AddRegisterItem}
        />
        <Route
          exact
          path="/register-items/:uuid/:page"
          component={RegisterItemProfile}
        />
        <Route exact path="/invoice-reminders" component={InvoiceReminders} />

        <Route exact path="/tenders" component={TendersList} />

        <Route exact path="/support-requests" component={SupportRequests} />

        <Route
          exact
          path="/support-requests/:uuid"
          component={SupportRequestProfile}
        />

        <Route
          exact
          path="/permission-groups"
          component={PermissionGroupList}
        />
        <Route
          exact
          path="/permission-groups/add"
          component={AddPermissionGroup}
        />

        <Route
          exact
          path="/permission-groups/:uuid/:page"
          component={PermissionGroupProfile}
        />

        <Route exact path="/equipment/import" component={EquipmentUpload} />

        <Route
          exact
          path="/two-factor-reminder"
          component={TwoFactorReminder}
        />

        <Route
          exact
          path="/job-overview-dashboard"
          component={JobOverviewDashboard}
        />

        <Route exact path="/safety-initiatives" component={SafetyInitiatives} />

        <Route
          exact
          path="/safety-initiatives/add"
          component={SafetyInitiativeAdd}
        />

        <Route
          exact
          path="/safety-initiatives/:uuid/:page"
          component={SafetyInitiativeProfile}
        />

        <Route
          exact
          path="/hazard-reports/:uuid"
          component={HazardReportOverview}
        />

        <Route
          exact
          path="/hazard-reports/:type/:id/add"
          component={AddHazardReport}
        />

        <Route path="/to-dos/:uuid?" exact component={Todos} />
        <Route path="/to-dos/:uuid?/tasks/:taskUuid" exact component={Todos} />

        <Route exact path="/login">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/create-log-to-sentry" component={CreateError} />

        <Route exact path="/login/consent" component={LoginConsent} />
        <Route exact path="/mfa-details" component={UserMfaList} />

        <Route exact path="/test-worksheets" component={TestWorksheetList} />
        <Route exact path="/test-worksheets/add" component={AddTestWorksheet} />
        <Route
          exact
          path="/test-worksheets/:uuid"
          component={TestWorksheetProfile}
        />

        <Route
          exact
          path="/inbound-accounts-payable"
          component={InboundAccountsPayableList}
        />

        <Route
          exact
          path="/inbound-accounts-payable/:uuid/:page"
          component={InboundAccountsPayableProfile}
        />

        <Route
          exact
          path="/redirect/employment-hero"
          component={EmploymentHeroRedirect}
        />

        <Route
          exact
          path="/integrations/employment-hero/:uuid/:page"
          component={EmploymentHeroIntegrationProfile}
        />

        <Route exact path="/allowance-types" component={AllowanceTypesList} />

        <Route
          exact
          path="/performance-review-templates"
          component={PerformanceReviewTemplatesList}
        />
        <Route
          exact
          path="/performance-review-templates/add"
          component={AddPerformanceReviewTemplate}
        />
        <Route
          exact
          path="/performance-review-templates/:uuid/:page"
          component={PerformanceReviewTemplateProfile}
        />

        <Route
          exact
          path="/performance-reviews/:uuid/:page"
          component={PerformanceReviewProfile}
        />

        <Route path="/user-credit-cards" exact component={UserCreditCards} />

        <Route
          path="/upload-purchases"
          exact
          component={UploadCreditCardReceipts}
        />

        {/* Ticket Types */}
        <Route
          exact
          path="/ticket-types/:uuid/:page/:folderUuid?"
          component={TicketTypeProfile}
        />
        <Route exact path="/ticket-types" component={TicketTypes} />
        <Route exact path="/ticket-types/add" component={AddTicketType} />

        <Route
          exact
          path="/support-tickets/:uuid"
          component={SupportTicketOverview}
        />

        <Route
          exact
          path="/expected-test-times"
          component={ExpectedTestTimeList}
        />

        <Route
          exact
          path="/expected-test-times/:method"
          component={ExpectedTestTimeProfile}
        />

        <Route
          exact
          path="/external-test-request-templates"
          component={ExternalTestRequestTemplateList}
        />

        <Route exact path="/opportunities" component={OpportunitiesList} />
        <Route exact path="/opportunities/add" component={AddOpportunity} />

        <Route
          exact
          path="/opportunities/:uuid/:page"
          component={OpportunityProfile}
        />

        <Route exact path="/services" component={ServiceList} />

        {/* 404 - Not Found */}
        <Route component={NoMatch} />
      </Switch>
    </main>
  );
};

export default Main;
