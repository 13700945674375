import React, { useEffect, useState } from "react";

import AsyncSelect from "react-select/async";
import { WrappedFieldProps } from "redux-form";
import { SelectOption } from "../utils/utilTypes";

interface AsyncSelectInputProps {
  asyncFunction: (inputValue: string) => Promise<any[]>;
  label: React.ReactNode;
  required?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  empty?: React.ReactNode;
  changeValue?: (value: SelectOption) => void;
  defaultOptions?: SelectOption[];
  placeholder?: string;
}

const AsyncSelectInput = (props: WrappedFieldProps & AsyncSelectInputProps) => {
  const {
    input,
    label,
    required,
    isMulti,
    isDisabled,
    isLoading,
    empty,
    meta: { touched, error, warning },
    asyncFunction,
    changeValue,
    defaultOptions = [],
    placeholder
  } = props;

  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void,
  ) => {
    asyncFunction(inputValue).then((options) => {
      callback(options);
      setOptions(options);
    });
  };

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, []);

  const value = isMulti
    ? input.value
    : options.filter((option) => input.value === option.value);

  return (
    <div>
      <div className="d-flex space-x-1">
        <label className="form-control-label tx-inverse tx-semibold">
          {label}
        </label>
        {required ? <span className="tx-danger"> *</span> : ""}
      </div>
      <AsyncSelect
        isClearable
        placeholder={placeholder ?? "Select..."}
        {...props}
        {...props.input}
        loadOptions={loadOptions}
        isOptionDisabled={(option) => option.disabled}
        onChange={(value: SelectOption, { action }) => {
          changeValue?.(value);

          if (action === "clear") {
            input.onChange(null);
          } else {
            input.onChange(isMulti ? value : value.value);
          }
        }}
        /** @ts-ignore */
        onBlur={() => input.onBlur()}
        isLoading={isLoading}
        value={value}
        className="shadow-sm"
        isDisabled={isDisabled}
        cacheOptions
      />
      {/* {!isLoading && options.length === 0 && (
        <small className="d-block">{empty}</small>
      )} */}
      {touched && warning && <span className="tx-warning">{warning}</span>}
      {touched && error && <span className="parsley-errors-list">{error}</span>}
    </div>
  );
};

export default AsyncSelectInput;
