import { BiPlus } from "react-icons/bi";
// @ts-ignore
import "react-datetime/css/react-datetime.css";
import { TaskListProps } from "./TaskList";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { TodoTask, TodoTaskList } from "./todoTypes";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import TextButton from "../utils/TextButton";
import dayjs from "dayjs";
import { useActionState, useRef } from "react";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const AddTask = (
  props: TaskListProps & { setOptimisticList: (action: TodoTask) => void },
) => {
  const { uuid } = useParams<{ uuid: string | "my-day" }>();
  const { taskList, setTaskList, setOptimisticList } = props;
  const { takeAction }: IUseApi<{}, { data: TodoTask }> = useApi();

  // const useWindowEvent = ('')

  const onSubmit = (currentState: TodoTaskList | null, formData: FormData) => {
    const oldTaskList = taskList;

    setOptimisticList({
      title: formData.get("title")?.toString() ?? "",
      due_date: formData.get("due_date")?.toString() || undefined,
    });

    return takeAction(
      "store",
      uuid === "my-day"
        ? "to-do-tasks"
        : `to-do-task-lists/${uuid}/to-do-tasks`,
      {
        title: formData.get("title"),
        due_date: formData?.get("due_date"),
      },
    )
      .then(({ data }) => {
        const updatedTaskList = {
          ...taskList,
          tasks: [...(taskList?.tasks ?? []), data.data],
        } as TodoTaskList;

        setTaskList(updatedTaskList);

        return updatedTaskList;
      })
      .catch((err) => {
        setTaskList(oldTaskList);
        errorSwal(err);

        return oldTaskList;
      });
  };

  const [state, formAction, isPending] = useActionState<TodoTaskList, FormData>(
    onSubmit,
    taskList,
  );

  const dueDateRef = useRef<HTMLInputElement>(null);

  return (
    <div className="border shadow-sm rounded-lg bg-white">
      <form action={formAction}>
        <div className=" border-bottom w-100 d-flex px-2 align-items-center">
          <BiPlus className="tx-20" />
          <input
            name="title"
            className="no-focus border-0 w-100 px-1 py-3"
            placeholder="Add Todo"
            required
          />
        </div>
        <div className="bg-gray-100 p-2 rounded-bottom d-flex align-items-center">
          <input
            type="date"
            name="due_date"
            ref={dueDateRef}
            className="bg-gray-100 p-2 no-focus border-0 rounded-lg due-date"
            placeholder="Due Date"
          />
          <div className="space-x-3">
            <TextButton
              className="text-secondary"
              //So that we can click it, then click enter to submit the form.
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                //Set the form due date to today
                const today = dayjs();
                if (dueDateRef?.current) {
                  dueDateRef.current.value = today.format("YYYY-MM-DD");
                }
              }}
            >
              Today
            </TextButton>
            <TextButton
              className="text-secondary"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                //Set the form due date to today
                const today = dayjs().add(1, "day");
                if (dueDateRef?.current) {
                  dueDateRef.current.value = today.format("YYYY-MM-DD");
                }
              }}
            >
              Tomorrow
            </TextButton>
            <TextButton
              className="text-secondary"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                //Set the form due date to today
                const today = dayjs()
                  .add(1, "week")
                  .startOf("week")
                  .add(1, "day");

                if (dueDateRef?.current) {
                  dueDateRef.current.value = today.format("YYYY-MM-DD");
                }
              }}
            >
              Next Week
            </TextButton>
          </div>
          <Button
            outline
            size="sm"
            className="ms-auto"
            color="primary"
            type="submit"
            disabled={isPending}
          >
            {isSubmitting(isPending, "Add", "Adding...")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTask;
