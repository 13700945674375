import React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import useModal from "../hooks/useModal";
import { FiMoreVertical } from "react-icons/fi";

interface ActionButtonDropdownProps {
  toggleButtonIcon?: React.ReactNode;
  dropdownItems: React.ReactNode[];
}

const ActionButtonDropdown: React.FC<ActionButtonDropdownProps> = ({
  toggleButtonIcon = <FiMoreVertical className="tx-18 text-secondary" />,
  dropdownItems,
}) => {
  const { toggle, modal } = useModal();

  return (
    <Dropdown toggle={() => {}} isOpen={modal} className="z-1000">
      <DropdownToggle onClick={toggle} color="link" className="p-1">
        {toggleButtonIcon}
      </DropdownToggle>
      <DropdownMenu className="z-20">
        {dropdownItems.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActionButtonDropdown;
