import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import Edit from "./Edit";
import InvoiceForm from "./InvoiceForm";
import Loans from "../purchases/Loans";
import PrePaymentPage from "../purchases/PrePaymentPage";

type Pages =
  | "details"
  | "quote"
  | "purchase-invoice"
  | "invoice-file"
  | "equipment"
  | "loan"
  | "pre-payments";

const ShowPage = (props: any) => {
  const { purchaseOrder, isAccounting, setPurchaseOrder } = props;
  const { page } = useParams<{ page: Pages }>();
  const { user } = useAuth();

  const onUploadQuote = ({ data }: { data: { data: { link: string } } }) =>
    setPurchaseOrder({
      ...purchaseOrder,
      quote: {
        link: data.data.link,
      },
    });

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={purchaseOrder} />;
    case "quote":
      return (
        <ProfileSingleFile
          whenSubmitted={onUploadQuote}
          {...props}
          link={purchaseOrder.quote.inline_link}
          downloadLink={purchaseOrder.quote.link}
          mime_type={purchaseOrder.quote.mime_type}
          documentable_id={purchaseOrder.uuid}
          documentable_type="App\Models\Purchase"
          bucket={`purchase-orders/${purchaseOrder.uuid}`}
        />
      );
    case "purchase-invoice":
      return isAccounting ||
        purchaseOrder?.purchase_invoice?.approval_action?.user_id ==
          user?.id ? (
        <InvoiceForm
          initialValues={{
            ...props.purchaseOrder.purchase_invoice,
            equipment: props.purchaseOrder.equipment,
          }}
          {...props}
          project={{
            id: props.purchaseOrder.project_id,
          }}
        />
      ) : (
        <NotFound />
      );
    case "invoice-file":
      return (
        <ProfileSingleFile
          {...props}
          link={purchaseOrder.purchase_invoice.file.inline_link}
          downloadLink={purchaseOrder.purchase_invoice.file.link}
          mime_type={purchaseOrder.purchase_invoice.file.mime_type}
          documentable_id={purchaseOrder.purchase_invoice.uuid}
          documentable_type="App\Models\PurchaseInvoice"
          bucket={`purchase-order-invoices/${purchaseOrder.purchase_invoice.uuid}`}
          disableUpload
        />
      );
    case "loan":
      return <Loans purchase={purchaseOrder} setPurchase={setPurchaseOrder} />;

    case "pre-payments":
      return (
        <PrePaymentPage purchase={purchaseOrder} onSuccess={setPurchaseOrder} />
      );
    default:
      return null;
  }
};

export default ShowPage;
