import { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import dayjsLocalizer from "../utils/dayjsLocalizer";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Calendar } from "react-big-calendar";
import { ResizableBox } from "react-resizable";
import { toast } from "react-toastify";
import Select from "react-select";
import { deleteStaffSchedule } from "../../actions/staffScheduleActions";
import ChangeStep from "../user/ChangeStep";
import ScheduleModal from "../user/ScheduleModal";
import ToggleBranchSchedule from "../user/ToggleBranchSchedule";
import CustomScaleLoader from "../utils/scaleLoader";
import "react-resizable/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import formError from "../utils/formError";
import { useHistory } from "react-router-dom";
import useApi from "../api/useApi";

// Dayjs extensions
dayjs.locale("en");
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const localizer = dayjsLocalizer(dayjs);

const UserSchedule = ({ userId }) => {
  const history = useHistory();

  const [selectedTimes, setSelectedTimes] = useState({
    start: null,
    end: null,
  });

  const [modal, setModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [eventModal, setEventModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? JSON.parse(localStorage.getItem("selectedBranch"))
      : null,
  );

  const [calendarTimes, setCalendarTimes] = useState({
    start: dayjs().startOf("week").format("YYYY-MM-DD"),
    end: dayjs().endOf("week").format("YYYY-MM-DD"),
  });

  const { takeAction } = useApi();

  const updateOrCreate = (values) => {
    return takeAction(
      values.uuid ? "update" : "store",
      `/staff-schedules/${values.uuid ?? ""}`,
      values,
    )
      .then(() => {
        toast.success(`${values.title} updated`);
        refreshData();
        return isUpdating() ? toggleEvent() : toggle();
      })
      .catch(formError);
  };

  const url = selectedBranch
    ? `/branches/${selectedBranch.uuid}/to-dos`
    : "/calendar-tasks";

  const getUrl = () => {
    return selectedBranch
      ? `/branches/${selectedBranch.uuid}/to-dos`
      : "/calendar-tasks";
  };

  const {
    data: rawEvents,
    setUrl,
    loading,
    refreshData,
  } = useApi(
    `${getUrl()}${
      calendarTimes.start || calendarTimes.end
        ? `?start=${calendarTimes.start}&end=${calendarTimes.end}`
        : ""
    }`,
    [],
  );

  const fetchToDos = (start, end, url) => {
    setUrl(`${url ?? "/calendar-tasks"}?start=${start}&end=${end}`);
  };

  const [calendarDate, setCalendarDate] = useState(new Date());

  const [height, setHeight] = useState();

  const [jobTypeFilter, setJobTypeFilter] = useState(
    localStorage.getItem("schedule_filter")
      ? JSON.parse(localStorage.getItem("schedule_filter"))
      : null,
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const [stepSize, setStepSize] = useState(
    localStorage.getItem("schedule_step")
      ? parseInt(localStorage.getItem("schedule_step"))
      : 30,
  );
  const [rightClick, setRightClick] = useState({});

  useEffect(() => {
    if (shouldClearScheduleFilter()) {
      localStorage.setItem("schedule_filter", "");
      setJobTypeFilter(null);
    }
  }, []);

  const onResize = (e, data) => {
    const { height: newHeight } = data.size;
    if (Number.isInteger(newHeight)) {
      localStorage.setItem("schedule_height", newHeight);
    }
    setHeight(newHeight);
  };

  const handleSelect = ({ start, end }) => {
    if (rightClick.x) return;
    setSelectedTimes({ start, end });
    setModal(!modal);
  };

  const toggle = () => {
    setModal(!modal);
    setSelectedEvent("");
  };

  const toggleEvent = () => {
    setEventModal(!eventModal);
    setSelectedEvent("");
  };

  const selectEvent = (event) => {
    if (rightClick.x) return;
    if (event.type === "App\\Models\\StaffSchedule") {
      setSelectedEvent(event);
      setEventModal(!eventModal);
      return;
    }
    history.push(event.link);
  };

  const isUpdating = () => (selectedEvent ? true : false);

  const submit = (values) => {
    const data = {
      start_time: `${values.start_time_date} ${values.start_time_time}`,
      finish_time: `${values.finish_time_date} ${values.finish_time_time}`,
      job_id: values.job_id,
      title: values.title,
      comments: values.comments,
      uuid: values.uuid,
    };

    if (!selectedEvent.id) data.user_uuid = userId;

    return updateOrCreate(data)
      .then((payload) => {
        toast.success(
          `${payload.title} ${isUpdating() ? "updated" : "added"}!`,
        );
        return;
      })
      .catch(formError);
  };

  const changeRange = (dateRange) => {
    const range = {};

    if (dateRange.start) {
      range.start = dayjs(dateRange.start).format("YYYY-MM-DD");
      range.end = dayjs(dateRange.end).format("YYYY-MM-DD");
    } else {
      range.start = dayjs(dateRange[0]).format("YYYY-MM-DD");
      range.end = dayjs(dateRange[dateRange.length - 1]).format("YYYY-MM-DD");
    }

    setCalendarTimes({ start: range.start, end: range.end });

    return fetchToDos(range.start, range.end);
  };

  const handleNavigate = (newDate) => setCalendarDate(newDate);

  const eventClassGetter = (event) => {
    let classType = "";
    let borderColor = "";
    switch (event.type) {
      case "App\\Models\\ProjectJob":
        classType = "info";
        borderColor = "#4d81bc";
        break;
      case "App\\Models\\StaffSchedule":
        classType = "primary";
        borderColor = "#00468e";
        break;
      case "App\\Models\\ApprovalAction":
        classType = "warning";
        break;
    }

    return {
      className: `bg-${classType}`,
      style: { borderColor },
    };
  };

  const handleChange = (newJobTypeFilter) => {
    localStorage.setItem(
      "schedule_filter",
      newJobTypeFilter.length > 0 ? JSON.stringify(newJobTypeFilter) : null,
    );

    setJobTypeFilter(newJobTypeFilter.length > 0 ? newJobTypeFilter : null);
  };

  const changeStep = (e) => {
    const val = e.target.value;
    if (val <= 0) {
      toast.warning("Step size must be larger than 0");
      return;
    }
    if (val) {
      localStorage.setItem("schedule_step", val);
      setStepSize(val);
    }
  };

  const contextMenu = (e) => {
    setRightClick({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };

  const hideContextMenu = () => setRightClick({});

  // Process events
  const events = rawEvents.reduce((events, ev) => {
    const event = {
      id: ev.uuid,
      title: `${ev.title} ${ev.comments ?? ""}`,
      start: dayjs(ev.start).toDate(),
      end: dayjs(ev.end).toDate(),
      job: ev.job,
      job_id: ev.job_id,
      comments: ev.comments,
      type: ev.type,
      link: ev.link,
    };

    if (!jobTypeFilter) {
      events.push(event);
      return events;
    }
    if (event.type !== "App\\Models\\ProjectJob") {
      events.push(event);
      return events;
    }
    if (
      event.type === "App\\Models\\ProjectJob" &&
      jobTypeFilter?.map(({ value }) => value).includes(ev.job_type)
    ) {
      events.push(event);
    }
    return events;
  }, []);

  const jobs = rawEvents.filter(
    (event) => event.type === "App\\Models\\ProjectJob",
  );

  const options = [...new Set(jobs.map((x) => x.job_type))].map((option) => ({
    label: option,
    value: option,
  }));

  const effectExecuted = useRef(false);

  useEffect(() => {
    if (!effectExecuted.current && events.length > 0) {
      const calendarElement = document.querySelector(".rbc-calendar");
      if (calendarElement) {
        const height = localStorage.getItem("schedule_height") ? parseInt(localStorage.getItem("schedule_height")) : calendarElement.offsetHeight;
        setHeight(height);
        effectExecuted.current = true;
      }
    }
  }, [events]);

  return (
    <Fragment>
      <div className="form-group mb-0">
        <label>Filter by Job Type</label>
        <Select
          options={options}
          value={jobTypeFilter}
          isClearable
          onChange={handleChange}
          isMulti
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 10,
            }),
          }}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setTimeout(() => setMenuOpen(false), 500)}
        />
      </div>
      <div className="d-flex justify-content-end my-2">
        <ToggleBranchSchedule
          calendarTimes={calendarTimes}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          fetchToDos={fetchToDos}
        />
      </div>
      <div style={{ height: localStorage.getItem("schedule_height") }}>
        {loading ? (
          <CustomScaleLoader />
        ) : (
          <div onContextMenu={contextMenu} onClick={hideContextMenu}>
            <ResizableBox
              height={height}
              className="box"
              onResize={onResize}
              axis="y"
              minConstraints={[10, 250]}
            >
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  selectable
                  scrollToTime={new Date(1970, 1, 1, 7)}
                  defaultView={localStorage.getItem("schedule_view") ?? "week"}
                  onSelectSlot={!menuOpen ? handleSelect : null}
                  onSelectEvent={!menuOpen ? selectEvent : null}
                  eventPropGetter={eventClassGetter}
                  onRangeChange={changeRange}
                  date={calendarDate}
                  onNavigate={handleNavigate}
                  step={parseInt(stepSize)}
                  onView={(view) => localStorage.setItem("schedule_view", view)}
                />
            </ResizableBox>
          </div>
        )}
      </div>
      {modal && (
        <ScheduleModal
          initialValues={{
            start_time_date: dayjs(selectedTimes.start).format("YYYY-MM-DD"),
            finish_time_date: dayjs(selectedTimes.end).format("YYYY-MM-DD"),
            start_time_time: dayjs(selectedTimes.start).format("HH:mm:ss"),
            finish_time_time: dayjs(selectedTimes.end).format("HH:mm:ss"),
          }}
          modal={modal}
          toggle={toggle}
          whenSubmitted={submit}
          onDelete={deleteStaffSchedule}
          isEditable={true}
        />
      )}
      {selectedEvent && (
        <ScheduleModal
          initialValues={{
            start_time_date: dayjs(selectedEvent.start).format("YYYY-MM-DD"),
            finish_time_date: dayjs(selectedEvent.end).format("YYYY-MM-DD"),
            start_time_time: dayjs(selectedEvent.start).format("HH:mm:ss"),
            finish_time_time: dayjs(selectedEvent.end).format("HH:mm:ss"),
            uuid: selectedEvent.id,
            job_id: selectedEvent.job_id,
            project: selectedEvent.job.project_id,
            title: selectedEvent.title,
            comments: selectedEvent.comments,
          }}
          selectedEvent={selectedEvent}
          modal={eventModal}
          toggle={toggleEvent}
          whenSubmitted={submit}
          onDelete={deleteStaffSchedule}
          update={true}
          isEditable={true}
        />
      )}
      <ChangeStep
        changeStep={changeStep}
        stepSize={stepSize}
        rightClick={rightClick}
      />
    </Fragment>
  );
};

const shouldClearScheduleFilter = () => {
  return (
    localStorage.getItem("schedule_filter") &&
    !Array.isArray(JSON.parse(localStorage.getItem("schedule_filter")))
  );
};

export default UserSchedule;
