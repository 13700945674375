import { Field } from "redux-form";
import FormModal from "../../utils/FormModal";
import RenderField from "../../utils/renderField";

const EmailModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-lg-6 form-group">
        <Field component={RenderField} name="recipients" label="Recipients" />
        <small className="d-block">
          To add multiple, separate with a semicolon (;)
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field component={RenderField} name="cc" label="CC" />
        <small className="d-block">
          To add multiple, separate with a semicolon (;)
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field component={RenderField} name="bcc" label="BCC" />
        <small className="d-block">
          To add multiple, separate with a semicolon (;)
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field component={RenderField} name="reply_to" label="Reply To" />
      </div>
    </FormModal>
  );
};

export default EmailModal;
