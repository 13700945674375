import dayjs from "dayjs";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import useApi from "../api/useApi";

import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";
import ProjectForm, { fieldsToRemoveIfTemplate } from "./ProjectForm";

const AddProject = (props) => {
  const { history, handleSubmit } = props;

  const { user } = useAuth();
  const { takeAction } = useApi();

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/projects/required-information`,
  );

  const onSubmit = (values) => {
    if (values.is_template) {
      fieldsToRemoveIfTemplate.forEach((field) => {
        values[field] = null;
      });
    }

    return upload(values)
      .then((data) =>
        takeAction("store", "projects", {
          ...data,
          template_jobs: values.template_jobs.filter((job) => job.selected_job),
          template_recurring_jobs: values.template_recurring_jobs.filter((recurringJob) => recurringJob.selected_job),
        }),
      )
      .then(({ data }) => {
        toast.success("Project created successfully!");
        history.push(`/projects/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };
  const { takeAction: generatePrompt, loading: generatingPrompt } = useApi();

  const onPrompt = (
    fieldUuid,
    prompt,
    name,
    change,
    attachments,
    setAnnotations,
  ) => {
    return generatePrompt("store", "general-prompts", {
      prompts: [
        {
          role: "user",
          content: prompt,
          attachments: attachments.map((a) => ({
            file_id: a.id,
            tools: [
              {
                type: "file_search",
              },
            ],
          })),
        },
      ],
    }).then(({ data }) => {
      toast.success("Prompt added");

      change(name, data.data.content.text.value);

      if (setAnnotations && data.data.content.text.annotations.length > 0) {
        setAnnotations(data.data.content.text.annotations);
      }
      //Replace the last .value of the 'name' to .thread_id
      const nameArray = name.split(".");

      nameArray[nameArray.length - 1] = "thread_id";

      change(nameArray.join("."), data.data.thread_id);
    });
  };

  return (
    <>
      <HeaderPage
        titlePage="New project"
        crumbs={[
          { link: "projects", name: "Projects" },
          { link: "projects/add", name: "New Project", active: true },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProjectForm
          onPrompt={onPrompt}
          generatingPrompt={generatingPrompt}
          adding={true}
          {...props}
        />
      </form>
    </>
  );
};

const form = reduxForm({
  form: "Project",
  initialValues: {
    status: "0",
    scheduled_start_date: dayjs().format("YYYY-MM-DD"),
    copy_jobs: true,
    copy_documents: true,
  },
});

export default form(AddProject);
