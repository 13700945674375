import { useEffect, useState } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import * as JobFields from "../jobs/register/jobFields";
import dateField from "../utils/dateField";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import requireDashes from "../utils/requireDashes";
import SelectInputAsync from "../utils/SelectInputAsync";
import isSubmitting from "../utils/submitting";
import { useHistory, useParams } from "react-router-dom";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import SelectInput from "../form/SelectInput";
import FormErrorAlert from "../form/FormErrorAlert";

const TestRequestForm = (props) => {
  const [options, setOptions] = useState([]);
  const { user } = useAuth();

  const { uuid, projectUuid } = useParams();

  const history = useHistory();

  const { takeAction } = useApi();

  const {
    initialValues,
    change,
    submitting,
    testRequest,
    handleSubmit,
    error,
  } = props;

  const { data: branches } = useApi("organisation-branches", [], true);

  const deleteTestRequest = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `/testrequests/${uuid}`))
      .then(() => {
        history.push("/samples");
        toast.success("Test Request deleted successfully");
      });
  };

  const updateSelectionMethod = (method) => {
    return axios
      .get(`/testrequests/locationselectionsub?method=${method}`)
      .then(({ data }) => {
        setOptions(data);
      });
  };

  useEffect(() => {
    updateSelectionMethod(initialValues?.location_selection_method);
    change(
      "location_selection_sub_method",
      initialValues?.location_selection_sub_method,
    );
  }, [initialValues]);

  const hasChanged =
    initialValues &&
    (initialValues?.testrequest?.client_number !== testRequest?.client_number ||
      initialValues?.testrequest?.client_lot_number !==
        testRequest?.client_lot_number);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <FormHeader>Job Details </FormHeader>
        <JobFields.Dependencies
          name="job.job_dependency"
          projectUuid={projectUuid || initialValues.project.uuid}
          className="col-lg-4"
        />
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="job.branch_id"
            label="Branch"
            options={branches.map((branch) => ({
              label: `${branch.number} - ${branch.name}`,
              value: branch.id,
            }))}
          />
        </div>
        <JobFields.JobManager name="job.project_manager" className="col-lg-4" />
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_start_date"
            type="date"
            component={dateField}
            required
            label="Scheduled Start Date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_start_time"
            type="time"
            component={renderField}
            label="Scheduled Start Time"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_finish_date"
            type="date"
            component={dateField}
            required
            label="Scheduled Finish Date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_finish_time"
            type="time"
            component={renderField}
            label="Scheduled Finish Time"
          />
        </div>
        <FormHeader>Test Request Details</FormHeader>
        <div className="form-group col-lg-6">
          <Field
            name="testrequest.client_number"
            validate={requireDashes}
            required
            type="text"
            component={renderField}
            label="Client Test Request"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="testrequest.client_lot_number"
            validate={requireDashes}
            required
            type="text"
            component={renderField}
            label="Client Lot Number"
          />
        </div>
        {hasChanged && (
          <div className="form-group col-lg-12">
            <Field
              name="testrequest.update_job_name"
              component={renderToggleInput}
              label="Change Job Name"
            />
          </div>
        )}
        <div className="form-group col-lg-4">
          <Field
            component={SelectInputAsync}
            required
            validate={required}
            name="testrequest.location_selection_method"
            getCallback={updateSelectionMethod}
            url="/testrequests/locationselection"
            label="Location Selection Method"
            placeholder="Location Selection Method"
          />
        </div>
        <div className="form-group col-lg-4">
          <Field
            component={SelectInput}
            name="testrequest.location_selection_sub_method"
            label="Location Selection Sub Method"
            placeholder="Location Selection Sub Method"
            options={options}
          />
        </div>
        <div className="form-group col-lg-4">
          <Field
            component={SelectInput}
            name="testrequest.type"
            label="Type"
            placeholder="Type"
            options={[
              {
                label: "Level 1",
                value: 1,
              },
              {
                label: "Level 2",
                value: 2,
              },
            ]}
          />
        </div>
        {user.is_position_admin && (
          <div className="form-group col-lg-12">
            <Field
              component={SelectInput}
              name="testrequest.branch_completed_at_id"
              label="Lab Completed At"
              options={branches.map((branch) => ({
                label: `${branch.number} - ${branch.name}`,
                value: branch.id,
              }))}
            />
            <small>
              If the test request is done from a different branch to the job,
              please select here.
            </small>
          </div>
        )}
        <div className="form-group col-lg-12">
          <Field
            name="testrequest.remarks"
            validate={requireDashes}
            required
            type="textarea"
            textarea={true}
            rows={3}
            cols={64}
            component={renderField}
            label="Remarks"
          />
        </div>
        <div className="col-lg-12 mg-t-25 d-none d-lg-block" />
        <div className="form-group col-lg-3 mg-b-10">
          <button
            className="btn btn-primary bd-0 form-control"
            disabled={submitting}
          >
            {isSubmitting(submitting)}
          </button>
        </div>
        {initialValues !== undefined && user.is_admin && (
          <div className="form-group col-lg-3 float-end">
            <div className="btn btn-outline-danger" onClick={deleteTestRequest}>
              <i className="fa fa-trash mg-r-5" /> Delete
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    testRequest: {
      client_number: selector(state, "testrequest.client_number"),
      client_lot_number: selector(state, "testrequest.client_lot_number"),
    },
  };
};

const form = reduxForm({});

export default connect(mapStateToProps, {})(form(TestRequestForm));
