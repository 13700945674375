import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import SelectInput from "../form/SelectInput";
import dateTime, { formatting } from "../utils/dateTime";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import StaffRoleType from "./StaffRoleType";
import axios from "../api/api";
import EMPLOYMENT_BASIS from "../../data/employment_basis";
import EMPLOYMENT_UNITS from "../../data/employment_units";
import { money } from "../form/formatters";
import useApi from "../api/useApi";
import EmploymentDetailAllowances from "./EmploymentDetailAllowances";
import FormErrorAlert from "../form/FormErrorAlert";
import DaysWorkedFields from "./DaysWorkedFields";
import { IntegrationType } from "../integrations/integrationTypes";
import Toggle from "react-toggle";

const FormStaffRole = (props: any) => {
  const { takeAction } = useApi();
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");

  const {
    initialValues,
    onDelete,
    handleSubmit,
    showAllowances = false,
    employmentBasis,
    isPrimary,
    change,
    isOrganisationHead,
  } = props;
  const [isHead, setIsHead] = useState(false);
  const { user } = useAuth();

  const { data: staffRoles, setUrl } = useApi();
  const { data: organisations, setUrl: setOrganisationUrl } = useApi("", []);

  useEffect(() => {
    setIsHead(isOrganisationHead);
  }, [isOrganisationHead]);

  useEffect(() => {
    if (initialValues?.branch_id) {
      setBranches(initialValues.branch_id);
      return;
    }
  }, []);

  useEffect(() => {
    if (props.userId) {
      setUrl(`users/${props.userId}/staff-roles`);
      setOrganisationUrl(`users/${props.userId}/organisations`);
    }
  }, [props.userId]);

  useEffect(() => {
    if (!initialValues && staffRoles && staffRoles.length === 0) {
      props.change("is_primary", true);
    }
  }, [staffRoles]);

  const setBranches = (branchId: number) => {
    return axios
      .get(
        `/organisation/positions?model=${encodeURIComponent(
          "App\\Models\\OrganisationBranch",
        )}&model_id=${branchId}`,
      )
      .then(({ data }) => {
        setPositions(data);

        return axios.get(
          `/organisationroles/organisationroles?model=${encodeURIComponent(
            "App\\Models\\OrganisationBranch",
          )}&model_id=${branchId}`,
        );
      })
      .then(({ data }) => setRoles(data));
  };

  const changeBranch = (e: any, val: any) => {
    return setBranches(val);
  };

  const selectedUserIsPrimary =
    organisations.find(
      (organisation: any) =>
        organisation.organisation.id === user?.active_organisation.id,
    )?.is_primary_organisation || organisations.length === 1;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="form-group col-lg-4">
          <Field
            name="user_id"
            label="Staff Member"
            component={SelectInputAsync}
            url="/users/staff"
            required
            validate={required}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="branch_id"
            label="Branch"
            component={SelectInputAsync}
            url="/organisationbranches/branches"
            hideDisabled={true}
            onChange={changeBranch}
            required
            validate={required}
          />
        </div>
        <div className="form-group col-lg-4">
          <div className="d-flex align-items-center mb-2">
            <label className="tx-inverse tx-semibold mb-0">Manager</label>
            <div className="d-flex ms-auto align-items-center">
              <Toggle
                checked={isHead}
                onChange={(value) => {
                  const newIsHead = !isHead;
                  setWarningMessage("");
                  setIsHead(newIsHead);
                  change("is_organisation_head", newIsHead);

                  const managerId = newIsHead ? "" : initialValues.manager_id;
                  change("manager_id", managerId);

                  if (newIsHead) {
                    return takeAction(
                      "index",
                      `staffroles?filter[is_organisation_head]=true`,
                    ).then(({ data }: { data: { data: any } }) => {
                      if (data.data.length == 0) {
                        return;
                      }

                      const head = data.data[0];

                      if (head.id == initialValues.id) {
                        return;
                      }

                      setWarningMessage(
                        `${head.user.name} (${head.position.name}, ${head.branch}) is already the organisation head, if you mark this as organisation head, it will overwrite this.`,
                      );
                    });
                  }
                }}
                className="toggle-sm"
                icons={false}
              />
              <span className="tx-sm ms-1">Organisation Head</span>
            </div>
          </div>
          <Field
            name="manager_id"
            component={SelectInputAsync}
            url="/users/staff"
            disabled={isHead}
          />
          <small className="d-block">{warningMessage}</small>
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="start_date"
            label="Start Date"
            component={dateTime}
            required
            validate={required}
            {...formatting}
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="finish_date"
            label="Finish Date"
            component={dateTime}
            {...formatting}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="employment_basis"
            label="Employment Basis"
            component={SelectInput}
            options={EMPLOYMENT_BASIS}
            required
            validate={required}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="position_id"
            label="Position"
            component={SelectInput}
            options={positions || []}
            required
            validate={required}
          />
          {positions?.length === 0 && (
            <small>
              Unable to find any positions attached to the division of the
              selected branch. Please ensure your organisation has these created
              before continuing.
            </small>
          )}
        </div>

        <div className="form-group col-lg-4">
          <Field
            information={
              <>
                <p className="d-block mb-0">
                  This is the title that will be displayed for the staff member
                  if this is their primary role.
                </p>
                <br />
                <p className="d-block mb-0">
                  Leave blank to use the position title as the staff member's
                  title.
                </p>
              </>
            }
            name="title"
            component={renderField}
            label="Title"
          />
        </div>
        {employmentBasis?.includes("Part Time") && (
          <FieldArray name="days" component={DaysWorkedFields} />
        )}

        {employmentBasis !== "Student Placement" && (
          <>
            <div className="form-group col-lg-4">
              <Field
                name="chargeout_rate"
                {...money}
                component={renderField}
                label="Chargeout Rate (Per Hour)"
              />
            </div>
            <div className="form-group col-lg-4">
              <Field
                name="remuneration"
                {...money}
                component={renderField}
                label="Remuneration"
                required
                validate={required}
              />
            </div>

            <div className="form-group col-lg-4">
              <Field
                name="units"
                type="text"
                component={SelectInput}
                options={EMPLOYMENT_UNITS}
                label="Units"
                required
                validate={required}
              />
            </div>

            <div className="form-group col-lg-6">
              <Field
                name="award"
                component={SelectInput}
                label="Award"
                url="/employment-awards"
                required
                validate={required}
                formatData={(data: any) =>
                  data.map(
                    ({ abbreviation, id }: { abbreviation: any; id: any }) => ({
                      label: abbreviation,
                      value: id,
                    }),
                  )
                }
              />
            </div>
          </>
        )}

        <div className="form-group col-lg-6">
          <Field
            name="remarks"
            type="text"
            component={renderField}
            label="Remarks"
          />
        </div>

        <div className="form-group col-lg-12">
          <Field
            name="overtime"
            component={renderToggleInput}
            label="Overtime"
          />
        </div>
        {staffRoles?.length > 0 && (
          <div className="form-group col-lg-12">
            <Field
              component={renderToggleInput}
              name="is_primary"
              label="Primary Role"
            />
          </div>
        )}

        {isPrimary &&
          employmentBasis !== "Contract" &&
          user?.active_organisation.integration_types.includes(
            IntegrationType.Payroll,
          ) &&
          selectedUserIsPrimary && (
            <div className="form-group col-lg-12">
              <Field
                component={SelectInput}
                name="leaveTemplate"
                label="Leave Template"
                url="/payroll-integration/leave-allowance-templates"
                required
                validate={required}
                formatData={(data: any) =>
                  data.map((d: any) => {
                    return {
                      label: d.name,
                      value: d.name,
                    };
                  })
                }
              />
            </div>
          )}

        <FieldArray
          {...props}
          roles={roles}
          component={StaffRoleType}
          name="role_types"
        />
        <FieldArray component={EmploymentDetailAllowances} name="allowances" />
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>

        {initialValues !== undefined && user?.is_admin && (
          <div className="form-group col-lg-3">
            <button
              type="button"
              className="btn btn-outline-danger form-control"
              onClick={() => {
                onDelete(initialValues.uuid);
              }}
            >
              <i className="fa fa-trash mg-r-5" /> Delete
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({ form: "StaffRole" });

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("StaffRole");

  return {
    employmentBasis: selector(state, "employment_basis"),
    userId: selector(state, "user_id"),
    isPrimary: selector(state, "is_primary"),
    isOrganisationHead: selector(state, "is_organisation_head"),
  };
};

export default connect(mapStateToProps, {})(form(FormStaffRole));
