import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import Spinner from "../utils/Spinner";
import ClientSignUpForm from "./ClientSignUpForm";

const Add = () => {
  const setting = useOrganisationSetting("client_sign_up");
  const { data: defaultSettings, loading } = useApi(
    "accounts/settings",
    {},
    true,
  );

  return (
    <>
      <HeaderPage
        titlePage="Add new Client"
        crumbs={[
          { link: "clients", name: "Clients" },
          { name: "Add Client", active: true },
        ]}
      />
      <Spinner loading={loading} />
      {!loading && (
        <ClientSignUpForm
          form="AddClient"
          enableReinitialize={true}
          initialValues={{
            has_account: true,
            abn_holder: true,
            request_account: true,
            account_request: {
              ...defaultSettings,
            },
          }}
          setting={setting}
        />
      )}
    </>
  );
};

export default Add;
