import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import {
  DepreciationCalculationMethods,
  DepreciationMethods,
} from "../equipmentTypeGroups/equipmentTypeGroupTypes";
import RenderField from "../utils/renderField";
import { money, percentage } from "../form/formatters";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import required from "../utils/required";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { Equipment } from "../equipment/equipmentTypes";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import FormHeader from "../utils/FormHeader";
import { useEffect } from "react";

interface EquipmentSelectOption {
  label: string;
  value: number;
  uuid: string;
}

const PurchaseOrderEquipmentFields = ({
  fields,
  change,
  initialValues,
}: {
  change: Function;
  initialValues?: {
    equipment: {
      id: number;
    }[];
  };
} & WrappedFieldArrayProps) => {
  const equipmentIds = initialValues?.equipment?.map(({ id }) => id).join(",");

  const {
    data: equipment,
    setUrl,
  }: IUseApiWithData<{ name: string; id: number; uuid: string }[]> = useApi(
    "",
    [],
    true,
  );

  useEffect(() => {
    setUrl(
      `custom-fields/equipment?filter[group_is_integrated]=true&filter[not_integrated]=${
        equipmentIds || "true"
      },`,
    );
  }, [initialValues?.equipment]);

  const {
    data: taxSettings,
  }: IUseApiWithData<{ tax_depreciation_enabled: boolean }> = useApi(
    "accounting/tax-settings",
    {
      tax_depreciation_enabled: false,
    },
    true,
  );

  return (
    <>
      {fields.map((field, index) => (
        <PurchaseOrderEquipmentField
          key={index}
          field={field}
          change={change}
          index={index}
          fields={fields}
          equipment={equipment.map(({ name, id, uuid }) => ({
            label: name,
            value: id,
            uuid,
          }))}
          taxSettings={taxSettings}
        />
      ))}
      <div className="col-lg-12">
        <Button outline onClick={() => fields.push({})}>
          Add Equipment
        </Button>
      </div>
    </>
  );
};

const PurchaseOrderEquipmentField = ({
  field,
  equipment,
  index,
  change,
  fields,
  taxSettings,
}: {
  field: string;
  equipment: EquipmentSelectOption[];
  index: number;
  change: Function;
  fields: any;
  taxSettings: {
    tax_depreciation_enabled: boolean;
  };
}) => {
  const { takeAction }: IUseApi<{}, { data: Equipment }> = useApi();

  const { takeAction: deleteItem }: IUseApi = useApi();

  const value = fields.get(index);

  const removeItem = () => {
    if (value.id) {
      return deleteSwal()
        .then(() =>
          deleteItem("destroy", `purchase-orders/equipment/${value.uuid}`),
        )
        .then(() => {
          toast.success("Equipment Removed");
          fields.remove(index);
        })
        .catch(errorSwal);
    }

    fields.remove(index);
  };

  const depreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(value.depreciation_method as DepreciationMethods);

  const taxDepreciationDoesntRequireInfo = [
    DepreciationMethods.FullDepreciation,
    DepreciationMethods.NoDepreciation,
  ].includes(value.tax_depreciation_method as DepreciationMethods);

  return (
    <>
      <div className="col-lg-12 form-group">
        <p className="mb-0 text-dark fw-bolder">Equipment {index + 1}</p>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name={`${field}.id`}
          label="Equipment"
          options={equipment}
          required
          validate={required}
          changeValue={(value: EquipmentSelectOption) => {
            change(`${field}.uuid`, value.uuid);

            takeAction("show", `equipment/${value.uuid}`).then(({ data }) => {
              //   console.log(data.data.group);

              change(
                `${field}.fixed_asset_account_id`,
                data.data.group.fixed_asset_account_id,
              );
              change(
                `${field}.depreciation_expense_account_id`,
                data.data.group.depreciation_expense_account_id,
              );
              change(
                `${field}.accumulated_depreciation_account_id`,
                data.data.group.accumulated_depreciation_account_id,
              );

              change(
                `${field}.depreciation_method`,
                data.data.group.depreciation_method,
              );

              change(
                `${field}.depreciation_rate`,
                data.data.group.depreciation_rate,
              );

              change(
                `${field}.effective_life_years`,
                data.data.group.effective_life_years,
              );

              change(
                `${field}.depreciation_calculation_method`,
                data.data.group.depreciation_calculation_method,
              );

              change(
                `${field}.depreciation_start_date`,
                data.data.date_received,
              );

              change(
                `${field}.tax_depreciation_method`,
                data.data.group.tax_depreciation_method,
              );

              change(
                `${field}.tax_depreciation_rate`,
                data.data.group.tax_depreciation_rate,
              );

              change(
                `${field}.tax_effective_life_years`,
                data.data.group.tax_effective_life_years,
              );

              change(
                `${field}.tax_depreciation_calculation_method`,
                data.data.group.tax_depreciation_calculation_method,
              );

              change(
                `${field}.tax_depreciation_start_date`,
                data.data.date_received,
              );
            });
          }}
        />
        {value.uuid && (
          <Link
            to={`/equipment/${value.uuid}/details`}
            className="text-secondary"
          >
            View Equipment
          </Link>
        )}
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.purchase_price`}
          label="Purchase Price"
          {...money}
          required
          validate={required}
        />
      </div>

      <FormHeader>Book Depreciation</FormHeader>
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          name={`${field}.depreciation_method`}
          label="Depreciation Method"
          required
          validate={required}
          options={[
            {
              label: "No Depreciation",
              value: DepreciationMethods.NoDepreciation,
            },
            {
              label: "Straight Line",
              value: DepreciationMethods.StraightLine,
            },
            {
              label: "Diminishing Value",
              value: DepreciationMethods.DiminishingValue100,
            },
            {
              label: "Diminishing Value (150%)",
              value: DepreciationMethods.DiminishingValue150,
            },
            {
              label: "Diminishing Value (200%)",
              value: DepreciationMethods.DiminishingValue200,
            },
            {
              label: "Full Depreciation",
              value: DepreciationMethods.FullDepreciation,
            },
          ]}
          changeValue={(value: { value: DepreciationMethods }) => {
            if (
              [
                DepreciationMethods.FullDepreciation,
                DepreciationMethods.NoDepreciation,
              ].includes(value.value)
            ) {
              change(`${field}.effective_life_years`, null);
              change(`${field}.depreciation_rate`, null);
              change(`${field}.depreciation_calculation_method`, null);
            }
          }}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.depreciation_start_date`}
          label="Depreciation Start Date"
          type="date"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.depreciation_rate`}
          label="Depreciation Rate"
          {...percentage}
          extraProps={{
            disabled:
              value.effective_life_years || depreciationDoesntRequireInfo,
          }}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name={`${field}.effective_life_years`}
          label="Effective Life (Years)"
          extraProps={{
            disabled: value.depreciation_rate || depreciationDoesntRequireInfo,
          }}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name={`${field}.depreciation_calculation_method`}
          label="Depreciation Calculation Method"
          options={[
            {
              label: "None",
              value: DepreciationCalculationMethods.None,
            },
            {
              label: "Rate",
              value: DepreciationCalculationMethods.Rate,
            },
            {
              label: "Life",
              value: DepreciationCalculationMethods.Life,
            },
          ]}
          required={!depreciationDoesntRequireInfo}
          isDisabled={depreciationDoesntRequireInfo}
          validate={!depreciationDoesntRequireInfo ? required : null}
        />
      </div>
      {taxSettings.tax_depreciation_enabled && (
        <>
          <FormHeader>Tax Depreciation</FormHeader>
          <div className="col-lg-12 form-group">
            <Field
              component={SelectInput}
              name={`${field}.tax_depreciation_method`}
              options={[
                {
                  label: "No Depreciation",
                  value: DepreciationMethods.NoDepreciation,
                },
                {
                  label: "Straight Line",
                  value: DepreciationMethods.StraightLine,
                },
                {
                  label: "Diminishing Value",
                  value: DepreciationMethods.DiminishingValue100,
                },
                {
                  label: "Diminishing Value (150%)",
                  value: DepreciationMethods.DiminishingValue150,
                },
                {
                  label: "Diminishing Value (200%)",
                  value: DepreciationMethods.DiminishingValue200,
                },
                {
                  label: "Full Depreciation at Purchase",
                  value: DepreciationMethods.FullDepreciation,
                },
              ]}
              label="Depreciation Method"
              required
              changeValue={(value: { value: DepreciationMethods }) => {
                if (
                  [
                    DepreciationMethods.FullDepreciation,
                    DepreciationMethods.NoDepreciation,
                  ].includes(value.value)
                ) {
                  change(`${field}.tax_effective_life_years`, null);
                  change(`${field}.tax_depreciation_rate`, null);
                  change(`${field}.tax_depreciation_calculation_method`, null);
                }
              }}
              validate={required}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name={`${field}.tax_depreciation_start_date`}
              label="Tax Depreciation Start Date"
              type="date"
              required
              validate={required}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name={`${field}.tax_depreciation_rate`}
              label="Rate"
              {...percentage}
              extraProps={{
                disabled:
                  value.tax_effective_life_years ||
                  taxDepreciationDoesntRequireInfo,
              }}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name={`${field}.tax_effective_life_years`}
              label="Effective Life (Years)"
              extraProps={{
                disabled:
                  value.tax_depreciation_rate ||
                  taxDepreciationDoesntRequireInfo,
              }}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={SelectInput}
              name={`${field}.tax_depreciation_calculation_method`}
              options={[
                {
                  label: "None",
                  value: DepreciationCalculationMethods.None,
                },
                {
                  label: "Rate",
                  value: DepreciationCalculationMethods.Rate,
                },
                {
                  label: "Life",
                  value: DepreciationCalculationMethods.Life,
                },
              ]}
              label="Depreciation Calculation Method"
              required={!taxDepreciationDoesntRequireInfo}
              isDisabled={taxDepreciationDoesntRequireInfo}
              validate={!taxDepreciationDoesntRequireInfo ? required : null}
            />
          </div>
        </>
      )}
      <div className="col-lg-9">
        <Field
          component={RenderField}
          {...money}
          name={`${field}.cost_limit`}
          label="Cost Limit"
        />
      </div>

      <div className="col-lg-3 d-flex align-items-end">
        <Button
          className="d-block"
          outline
          block
          color="danger"
          onClick={removeItem}
        >
          Remove
        </Button>
      </div>
      <div className="col-12 form-group">
        <small>
          The value of the asset you want to depreciate, if this is less than
          the cost of the asset.
        </small>
      </div>
    </>
  );
};

export default PurchaseOrderEquipmentFields;
