import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import isSubmitting from "../utils/submitting";
import SelectInput from "../form/SelectInput";

const Form = (props) => {
  const {
    handleSubmit,
    change,
    templateTypes,
    selectedTemplate,
    initialValues,
    loadingTemplateTypes = false,
  } = props;

  const { takeAction, loading } = useApi();

  const history = useHistory();

  const deleteFunction = () => {
    return deleteSwal()
      .then(() =>
        takeAction("destroy", `email-templates/${initialValues.uuid}`),
      )
      .then(() => {
        toast.success("Template Deleted");
        history.push("/email-templates");
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {templateTypes && (
          <div className="form-group col-12">
            <Field
              component={SelectInput}
              options={templateTypes}
              loading={loadingTemplateTypes}
              name="template"
              label="Template"
              changeValue={(value) => {
                change("templatable_type", value?.templatable_type ?? null);
                change("templatable_id", value?.templatable_id ?? null);
                change("type", value?.type ?? null);
                change("attachments", null);
              }}
              required
              validate={required}
            />
          </div>
        )}
        <div className="form-group col-lg-6">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={RenderField}
            name="subject"
            label="Subject"
            validate={required}
            required
          />
        </div>
        <div className="form-group col-lg-12">
          <Field
            component={RenderField}
            name="body"
            label="Body"
            validate={required}
            required
            textarea
          />
        </div>
        {selectedTemplate?.available_attachments.length > 0 && (
          <div className="col-12 form-group">
            <Field
              component={SelectInputAsync}
              options={selectedTemplate?.available_attachments}
              name="attachments"
              label="Attachments"
              multiple
            />
          </div>
        )}

        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
        <div className="col-lg-12 form-group d-flex justify-content-end">
          {initialValues?.uuid ? (
            <Button
              color="danger"
              type="button"
              outline
              onClick={deleteFunction}
              disabled={loading}
            >
              {isSubmitting(loading, "Delete", "Deleting...")}
            </Button>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default reduxForm()(Form);
