import useApi from "../../api/useApi";
import useS3Uploader from "../../hooks/useS3Uploader";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
import errorSwal from "../../utils/errorSwal";
import { useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { BiCloudUpload } from "react-icons/bi";
import InformationAlert from "../../utils/InformationAlert";
import { Button, Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import ReactTable from "../../table/ReactTable";
import isSubmitting from "../../utils/submitting";
import { IUseApi } from "../../api/apiTypes";
import TextButton from "../../utils/TextButton";
import { GoFileZip } from "react-icons/go";
import Select from "react-select";
import { SelectOption } from "../../utils/utilTypes";

const GenerateReportModal = (props: any) => {
  const { takeAction, loading: saving }: IUseApi<{}, { data: any }> = useApi();
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { modal, toggle } = useModal();
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [loading, setLoading] = useState(false);
  const [toUpload, setToUpload] = useState<any[]>();
  const [reportType, setReportType] = useState<SelectOption | null>(null);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections);

    setLoading(true);
    return upload(
      acceptedFiles[0],
      `tmp/organisations/samples`,
      (file: any) => {
        return takeAction("store", "sample-reports", {
          ...file,
          type: reportType?.value,
        })
          .then((res: any) => {
            toast.success(res?.data?.message);
            setUploadPercent(0);
            setLoading(false);
            setToUpload(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              toast.warning("No samples with reports found in the file.");
            }
          })
          .catch((err) => {
            setLoading(false);
            errorSwal(err);
          });
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ],
    multiple: false,
    onDrop,
    disabled: loading,
  });

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "sample_id",
        header: "Sample",
      },
    ];
  }, [toUpload]);

  return (
    <>
      <TextButton type="button" onClick={toggle} className="text-secondary">
        <GoFileZip /> Generate ZIP from CSV
      </TextButton>
      <Modal
        className="wd-md-1000 mx-wd-800"
        backdrop="static"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Generate ZIP Report from CSV</ModalHeader>
        <ModalBody>
          <div className="position-relative">
            <div className="mb-3">
              <label className="form-control-label tx-inverse tx-semibold">
                Report Type
              </label>
              <Select
                value={reportType}
                placeholder="Select Report Type"
                isDisabled={toUpload && toUpload.length > 0}
                isClearable
                onChange={(option) => setReportType(option)}
                options={[
                  {
                    label: "Level 1",
                    value: 1,
                  },
                  {
                    label: "Level 2",
                    value: 2,
                  },
                ]}
              />
            </div>

            <div className="mb-3">
              <p className="fw-bolder text-dark mb-0">Upload Excel or CSV</p>
            </div>
            <div className="">
              <div
                {...getRootProps()}
                className={`tn-300 rounded-lg ${
                  isDragActive ? "bg-gray-100 border-2 text-muted" : "border"
                } d-flex align-items-center w-100 h-100 justify-content-center border-dashed`}
                style={{ minHeight: "7rem" }}
              >
                <input {...getInputProps()} />
                {loading ? (
                  <div>
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                ) : (
                  <div>
                    <div className="text-center ">
                      <BiCloudUpload className="tx-24" />
                    </div>
                    <p className="mb-0">Upload Sample List</p>
                  </div>
                )}
              </div>
              {uploadPercent > 0 && uploadPercent < 100 && (
                <div className="mt-3">
                  <Progress
                    className="progress-bar-sm"
                    striped
                    animated
                    value={uploadPercent}
                    color="primary"
                  />
                </div>
              )}
              {rejectedFiles.length > 0 && (
                <div className="mt-3">
                  <InformationAlert
                    type="warning"
                    title="Files Rejected"
                    body={`The following files were rejected: \n\n${rejectedFiles
                      .map((file) => file.file.name)
                      .join("\n\n")}.\n\nPlease upload a CSV or excel file.`}
                  />
                </div>
              )}
            </div>
          </div>
          {toUpload && toUpload.length > 0 && (
            <>
              <div className="col-12">
                <div className="d-flex align-items-center mt-4">
                  <label className="section-title my-0">
                    {toUpload.length} Sample{toUpload.length > 1 ? "s" : ""} to
                    generate the report
                  </label>
                  <div className="ms-auto">
                    <Button
                      outline
                      size="sm"
                      className="ms-auto"
                      onClick={() => setToUpload(undefined)}
                    >
                      Clear
                    </Button>
                  </div>
                </div>

                <hr className="w-100" />
              </div>
              <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <ReactTable columns={columns} data={toUpload} />
              </div>
              <Button
                className="mt-3"
                color="primary"
                onClick={() => {
                  const sampleIds = toUpload.flatMap((item) => item.id);

                  const payload = { sample_ids: sampleIds };
                  takeAction("update", "sample-reports", payload)
                    .then(({ data }: { data: any }) => {
                      toast.success(data.message);
                      toggle();
                      setToUpload([]);
                    })
                    .catch(errorSwal);
                }}
              >
                {isSubmitting(saving, "Generate", "Generate...")}
              </Button>
            </>
          )}
          {toUpload && toUpload.length === 0 && (
            <div className="mt-3">
              <InformationAlert
                type="warning"
                title="No Samples Found"
                body="Please upload a new CSV or excel file."
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default GenerateReportModal;
