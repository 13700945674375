import { useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { reset } from "redux-form";
import { useAuth } from "../../../context/auth-context";
import TemplateType from "../../../enums/TemplateType";
import useApi from "../../api/useApi";
import useModal from "../../hooks/useModal";
import EmailModal from "./EmailModal";
import formError from "../../utils/formError";

const Emails = ({ emails, model }) => {
  const [selected, setSelected] = useState();

  const { user } = useAuth();

  const { takeAction } = useApi();

  const { toggle, modal } = useModal();

  const onSubmit = (values, dispatch) => {
    return takeAction("store", `sent-email-templates`, values)
      .then(() => {
        toast.success("Email Sent");
        setSelected();
        toggle();
        dispatch(reset("SendEmail"));
      })
      .catch(formError);
  };

  if (!emails || emails.length === 0) return null;

  return (
    <li className="nav-item">
      <UncontrolledDropdown type="button">
        <DropdownToggle caret className="w-100 nav-link">
          <AiOutlineSend className="tx-primary tx-20 me-1" />
          Send Email
        </DropdownToggle>
        <DropdownMenu>
          {emails.map((email) => {
            return (
              <DropdownItem
                onClick={() => {
                  setSelected(email);
                  toggle();
                }}
                key={email.uuid}
              >
                {email.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <EmailModal
        title={`Send ${selected?.name}`}
        onSubmit={onSubmit}
        modal={modal}
        toggle={toggle}
        form="SendEmail"
        initialValues={{
          reply_to: user.email,
          sender_id: model?.id,
          sender_type: "App\\Models\\ProjectJob",
          template_id: selected?.id,
          type: TemplateType.CustomJob,
        }}
        save="Send"
        saving="Sending..."
      />
    </li>
  );
};

export default Emails;
