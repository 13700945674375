import { Link, useHistory, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import { Opportunity, Stages } from "./opportunityTypes";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import ShowOpportunityPage from "./ShowOpportunityPage";
import ProfileButtons from "../utils/ProfileButtons";
import useModal from "../hooks/useModal";
import CreateTenderFromOpportunityModal from "./CreateTenderFromOpportunityModal";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { toast } from "react-toastify";

export const getStatusDetails = (opportunity: Opportunity) => {
  if (opportunity.status == Stages.Identified) {
    return {
      color: "bg-warning",
      text: "Identified",
    };
  }

  if (opportunity.status == Stages.Positioning) {
    return {
      color: "bg-info",
      text: "Positioning",
    };
  }

  return {
    color: "bg-success",
    text: "Tendered",
  };
};

const OpportunityProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const history = useHistory();

  const { data, refreshData, loading, setData }: IUseApi<Opportunity> = useApi(
    `opportunities/${uuid}`,
    null,
    true,
    "/opportunities",
  );

  const { toggle, modal } = useModal();

  const { takeAction, loading: deleting }: IUseApi = useApi();

  const deleteOpportunity = () => {
    deleteSwal(opportunity.name)
      .then(() => takeAction("destroy", `opportunities/${opportunity.uuid}`))
      .then(() => {
        history.push("/opportunities");
        toast.success("Opportunity deleted successfully");
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading />;
  }

  const opportunity = data as Opportunity;

  const statusDetails = getStatusDetails(opportunity);

  return (
    <>
      <HeaderPage
        titlePage={opportunity.name}
        crumbs={[
          {
            name: "Opportunities",
            link: "/opportunities",
          },
          {
            name: opportunity.name,
            link: `/opportunities/${opportunity.uuid}`,
            active: true,
          },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <div className="d-flex align-items-center mb-1">
              <div>
                <DashboardH3 className="mb-0">{opportunity.name}</DashboardH3>
                <small className="text-muted">
                  {opportunity.organisation.display_name}
                </small>
              </div>

              {opportunity.tender ? (
                <div
                  className={`d-inline-block shadow w-auto rounded-pill text-white tx-10 text-center px-3 ms-auto bg-success`}
                >
                  Tender Created
                </div>
              ) : (
                <div
                  className={`d-inline-block shadow w-auto rounded-pill text-white tx-10 text-center px-3 ms-auto ${statusDetails.color}`}
                >
                  {statusDetails.text} ({opportunity.days_in_stage} days)
                </div>
              )}
            </div>
            <div className="d-flex">
              <Link
                className="d-block text-dark tx-12"
                to={`/jobs/${opportunity.job.uuid}/details`}
              >
                View Job
              </Link>
            </div>
            {opportunity.tender ? (
              <div className="d-flex">
                <Link
                  className="d-block text-dark tx-12"
                  to={opportunity.tender.link}
                >
                  View Tender
                </Link>
              </div>
            ) : null}
          </DashboardHeader>
        }
        content={
          <>
            {opportunity.status == Stages.Positioning && !opportunity.tender ? (
              <>
                <ProfileButtons
                  buttons={[
                    {
                      text: "Create Tender",
                      onClick: toggle,
                    },
                  ]}
                />
                <CreateTenderFromOpportunityModal
                  modal={modal}
                  toggle={toggle}
                  refreshData={refreshData}
                  opportunity={opportunity}
                />
              </>
            ) : null}
            <ShowOpportunityPage
              opportunity={opportunity}
              setOpportunity={setData}
            />
          </>
        }
        tabs={[
          {
            label: "Details",
            link: `/opportunities/${opportunity.uuid}/details`,
            icon: "icon ion-clipboard text-capitalize",
            page: "details",
          },
          {
            label: "Documents",
            link: `/opportunities/${opportunity.uuid}/documents`,
            icon: "icon ion-clipboard text-capitalize",
            page: "documents",
          },
        ]}
        sideBar={
          [Stages.Identified, Stages.Positioning].includes(
            opportunity.status,
          ) ? (
            <Button
              disabled={deleting}
              block
              outline
              color="danger"
              className="mt-3"
              onClick={deleteOpportunity}
            >
              {isSubmitting(deleting, "Delete Opportunity", "Deleting...")}
            </Button>
          ) : null
        }
      />
    </>
  );
};

export default OpportunityProfile;
