import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import { useMemo } from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import useModal from "../hooks/useModal";
import SentBadge from "../deliverables/SentBadge";
import getInvoiceDetails from "../invoices/getInvoiceDetails";
import useApi from "../api/useApi";
import Select from "react-select";
import errorSwal from "../utils/errorSwal";
import BulkUpdateProductivityForm from "./BulkUpdateProductivityForm";
import { Productivity } from "./projectTypes";
import { Table } from "@tanstack/react-table";

interface ProductivityListProps {
  productivity: Productivity[];
  modelId: number;
  refreshData: () => void;
  loading: boolean;
  projectJobs: any;
  handleRowClick?: (row: any) => void;
  isEditable: boolean;
  selectedRows: Productivity[];
  setSelectedRows: (selectedRows: Productivity[]) => void;
  setTableInstance: (tableInstance: Table<Productivity>) => void;
}

const ProductivityList = (props: ProductivityListProps) => {
  const {
    productivity,
    modelId,
    refreshData,
    loading,
    handleRowClick,
    projectJobs,
    isEditable,
    selectedRows,
    setSelectedRows,
    setTableInstance,
  } = props;

  const { takeAction } = useApi();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "start_time",
        header: "Date",
        cell: (info: any) => {
          const handleClick = handleRowClick
            ? () => handleRowClick(info.row.original)
            : undefined;
          return isEditable ? (
            <Button
              onClick={handleClick}
              color="link"
              className="p-0 text-nowrap text-secondary"
            >
              {dayjs(info.getValue()).format("DD/MM/YYYY")}
            </Button>
          ) : (
            <span>{dayjs(info.getValue()).format("DD/MM/YYYY")}</span>
          );
        },
      },
      {
        accessorKey: "user",
        header: "User",
        cell: (info: any) => (
          <a href={`mailto:${info.row.original.user_email}`}>
            {info.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "job_full_name",
        header: "Job",
        cell: (info: any) => {
          const job = projectJobs.find(
            (job: any) => job.id === info.row.original.job_id,
          );
          return isEditable ? (
            <Select
              options={projectJobs.map((job: any) => ({
                value: job.id,
                label: job.name,
              }))}
              isDisabled={!isEditable} // Toggle based on edit mode
              value={{
                value: job?.id,
                label: job?.name,
              }}
              onChange={(value: any) => {
                return takeAction(
                  "update",
                  `productivity/${info.row.original.uuid}`,
                  {
                    job_id: value.value,
                  },
                )
                  .then(refreshData)
                  .catch(errorSwal);
              }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          ) : (
            <span>{job?.name}</span>
          );
        },
      },
      {
        accessorKey: "time_in_hours",
        header: "Hours",
      },
      {
        accessorKey: "chargeout_amount",
        header: "Charge Out",
        cell: (info: any) => <SpreadPrice price={info.getValue()} />,
      },
      {
        accessorKey: "deliverables",
        header: "Deliverables",
        cell: (info: any) => {
          const { modal, toggle } = useModal();

          return isEditable ? (
            <>
              <Button
                onClick={toggle}
                color="link"
                className="p-0 text-nowrap text-secondary"
              >
                {info.getValue().length} Deliverable
                {info.getValue().length !== 1 ? "s" : ""} added
              </Button>
              <Modal
                className="wd-md-1000 mx-wd-800 w-95"
                isOpen={modal}
                toggle={toggle}
              >
                <ModalHeader>Deliverables</ModalHeader>
                <ModalBody>
                  <div className="space-y-3">
                    {info.getValue().map((deliverable: any) => {
                      const invoicedAt = getInvoiceDetails(
                        info.row.original.invoice,
                      );
                      return (
                        <div className="bg-white rounded-lg shadow-sm border p-3">
                          <div>
                            <Link
                              className="text-dark d-block"
                              to={deliverable.link}
                            >
                              {deliverable.name}
                            </Link>
                            <Link
                              className="text-secondary"
                              to={deliverable.job.link}
                            >
                              {deliverable.job.name}
                            </Link>
                          </div>
                          <div className="d-flex space-x-3 mt-3">
                            <SentBadge
                              className="rounded-pill shadow"
                              sent={deliverable.sent}
                            />
                            <Badge
                              className="rounded-pill shadow"
                              color={invoicedAt.color}
                            >
                              {invoicedAt.link}
                            </Badge>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggle}>Close</Button>
                </ModalFooter>
              </Modal>
            </>
          ) : (
            <span>
              {info.getValue().length} Deliverable
              {info.getValue().length !== 1 ? "s" : ""} added
            </span>
          );
        },
      },
      {
        accessorKey: "comments",
        header: "Notes",
        cell: (info: any) => {
          const { modal, toggle } = useModal();

          if (!info.getValue()) {
            return "-";
          }

          return (
            <>
              <i
                style={{ cursor: "pointer" }}
                onClick={toggle}
                className="fa fa-sticky-note"
                aria-hidden="true"
                title="Notes"
              ></i>
              <Modal
                className="wd-md-1000 mx-wd-800 w-95"
                isOpen={modal}
                toggle={toggle}
              >
                <ModalHeader>Notes</ModalHeader>
                <ModalBody>{info.getValue()}</ModalBody>
                <ModalFooter>
                  <Button onClick={toggle}>Close</Button>
                </ModalFooter>
              </Modal>
            </>
          );
        },
      },
    ];

    return baseColumns;
  }, [productivity, isEditable, selectedRows]);

  return (
    <>
      {selectedRows.length > 0 && (
        <div className="selected-rows-info">
          <p>You have {selectedRows.length} row(s) selected.</p>
          <BulkUpdateProductivityForm
            projectJobs={projectJobs}
            refreshData={refreshData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
      )}

      <ReactTable
        disableSearch
        columns={columns}
        data={productivity}
        enableCsvExport
        wrapperClasses="overflow-visible bg-white"
        loading={loading}
        enableMultiSelect={isEditable}
        setSelectedRows={setSelectedRows}
        getTableInstance={setTableInstance}
      />
    </>
  );
};

export default ProductivityList;
