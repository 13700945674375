import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaRetweet,
  FaSitemap,
} from "react-icons/fa";
import { MdFitScreen } from "react-icons/md";

export interface ChartControlProps {
  chart: {
    fit: Function;
    expandAll: Function;
    collapseAll: Function;
    compact: Function;
    zoomIn: Function;
    zoomOut: Function;
  };
  compact: number;
  swap: Function;
}

const ChartControl = (props: ChartControlProps) => {
  const { chart, swap } = props;
  let { compact } = props;
  return (
    <div>
      <button onClick={() => swap()} className="btn btn-sm btn-secondary m-1">
        <FaRetweet /> Rotate
      </button>
      <button
        onClick={() => chart.fit()}
        className="btn btn-sm btn-secondary m-1"
      >
        <MdFitScreen /> Fit Screen
      </button>
      <button
        onClick={() => chart.expandAll()}
        className="btn btn-sm btn-secondary m-1"
      >
        <FaAngleDoubleDown /> Expand All
      </button>

      <button
        onClick={() => chart.collapseAll()}
        className="btn btn-sm btn-secondary m-1"
      >
        <FaAngleDoubleUp /> Collapse All
      </button>
      <button
        onClick={() =>
          chart
            .compact(!!(compact++ % 2))
            .render()
            .fit()
        }
        className="btn btn-sm btn-secondary m-1"
      >
        <FaSitemap /> Compact
      </button>
      <button
        onClick={() => chart.zoomIn()}
        className="btn btn-sm btn-secondary m-1"
      >
        <BsZoomIn /> Zoom In
      </button>
      <button
        onClick={() => chart.zoomOut()}
        className="btn btn-sm btn-secondary m-1"
      >
        <BsZoomOut /> Zoom Out
      </button>
    </div>
  );
};

export default ChartControl;
