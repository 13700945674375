import _ from "lodash";
import { evaluate } from "mathjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { getFormValues } from "redux-form";

interface IEvaluated {
  value: string | number;
  formula: string;
}

const ShowFormulaField = (props: any) => {
  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    const newValues = Object.entries(
      _.orderBy(props.formValues?.custom_fields, "order") ?? [],
    )
      .filter(
        ([key, field]: any) =>
          `custom_fields.${key}.value` !== props.input.name,
      )
      .map(([key, field]: any) => {
        const variable = `$`.concat(
          `{${field?.label
            ?.trim()
            .replace(/{|}/g, "")
            .replace(/\s+/g, "-")
            .toLowerCase()}}`,
        );
        return { key: variable, value: field?.value };
      });
    setValues(newValues);
  }, [props.formValues?.custom_fields]);

  let toEval: string = props.formula;

  values.forEach((field) => {
    toEval = toEval.replaceAll(`${field.key}`, field?.value ?? "-");
  });

  let evaluated: string | number = 0;

  try {
    evaluated =
      props.formula_type === "code" ? new Function(toEval)() : evaluate(toEval);
  } catch (err) {
    evaluated = "Unable To Evaluate";
  }

  return (
    <FormulaField
      change={props.change}
      evaluated={{ value: evaluated, formula: toEval }}
      input={props.input}
      label={props.label}
    />
  );
};

const FormulaField = ({
  evaluated,
  change,
  input,
  label,
}: {
  evaluated: IEvaluated;
  change: Function;
  input: any;
  label: React.ReactNode | string;
}) => {
  const [showCode, setShowCode] = useState(false);
  useEffect(() => {
    change(input.name, evaluated.value);
  }, [evaluated.value]);

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <label className="tx-inverse tx-semibold mb-0">{label}</label>
        <div className="d-flex ms-auto align-items-center">
          <Toggle
            checked={showCode}
            onChange={() => {
              setShowCode(!showCode);
            }}
            className="toggle-sm"
            icons={false}
          />
          <span className="tx-sm ms-1">Show Code</span>
        </div>
      </div>
      <div className="bg-white border" style={{ padding: "0.594rem 0.75rem" }}>
        <div>
          <p className="mb-0 tx-inverse white-space">{evaluated.value}</p>
          {showCode && (
            <p className="mb-1 tx-12 white-space mt-2">{evaluated.formula}</p>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any, { meta }: { meta: { form: string } }) => {
  return { formValues: getFormValues(meta.form)(state) };
};

export default connect(mapStateToProps)(ShowFormulaField);
