import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DocsModal from "../utils/DocsModal";

const Tenders = () => {
  const { uuid } = useParams();

  const { loading, data } = useApi(`clients/${uuid}/tenders`, []);

  const columns = [
    {
      accessorKey: "project.name",
      header: "Project Name",
      cell: (info) => {
        const tender = info.row.original;

        if (tender.work) {
          return <Link to={tender.work.link}>{tender.work.name}</Link>;
        }

        return "-";
      },
    },
    {
      accessorKey: "project_name",
      header: "Tender Name",
      cell: (info) => {
        const tender = info.row.original;
        return (
          <Link to={`/tenders/${tender.uuid}/details`}>
            <p className="text-dark mb-0">{info.getValue()}</p>
            <p className="text-muted mb-0">{tender.name}</p>
          </Link>
        );
      },
    },
    {
      accessorKey: "number",
      header: "Number",
    },
    {
      accessorKey: "submitted_on",
      header: "Submitted",
      cell: (info) => {
        const submitted_on = info.getValue();
        return (
          <Badge color={`${submitted_on ? "info" : "warning"}`}>
            {submitted_on ? (
              <>Submitted on {dayjs(submitted_on).format("DD/MM/YYYY")}</>
            ) : (
              "Not Submitted"
            )}
          </Badge>
        );
      },
    },
    {
      accessorKey: "successful",
      header: "Tender Successful",
      cell: (info) => {
        const tender = info.row.original;
        if (info.getValue() !== null) {
          return (
            <Badge color={tender.successful ? "success" : "danger"}>
              {tender.successful ? "Tender Successful" : "Tender Unsuccessful"}
            </Badge>
          );
        }
        return "Not complete";
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/tenders-9273/" />
      <ReactTable columns={columns} data={data} loading={loading} />
    </>
  );
};

export default Tenders;
