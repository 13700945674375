import ReactTable from "../table/ReactTable";
import { CustomJob } from "./customJobTypes";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

interface CustomJobTableProps {
  customJobs: CustomJob[];
}

const CustomJobTable = (props: CustomJobTableProps) => {
  const { customJobs } = props;

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        const customJob = info.row.original;
        return (
          <>
            <Link
              to={`custom-jobs/${customJob.uuid}/details`}
              className="d-flex align-items-center"
            >
              {info.getValue()}{" "}
            </Link>
            <small className="d-block">
              {customJob.organisation.display_name}
            </small>
            {customJob.archived_at ? (
              <Badge className="rounded-pill shadow block mt-1" color="info">
                Archived
              </Badge>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return <ReactTable disableSearch data={customJobs} columns={columns} />;
};

export default CustomJobTable;
