import "chart.js/auto";
import _ from "lodash";
import dayjs from "dayjs";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ReactComponent as Analytics } from "../../svgs/analytics.svg";
import CustomScaleLoader from "../utils/scaleLoader";
import SpreadPrice from "../utils/SpreadPrice";
import SVGContainer from "../utils/SVGContainer";
import ProductivityList from "./ProductivityList";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import SplitProductivityModal from "./SplitProductivityModal";
import SplitProductivityForm from "./SplitProductivityForm";
import { ChartOptions } from "chart.js";
import SearchInput from "../search/SearchInput";
import { IUseApiWithData } from "../api/apiTypes";
import { Productivity } from "./projectTypes";
import { Button } from "reactstrap";
import { Table } from "@tanstack/react-table";
import { generateCsvFile } from "../table/ReactTable";

const options: ChartOptions<"bar"> = {
  scales: {
    y: {
      type: "linear",
    },
  },
};

const formatProductivity = (productivity: any) => {
  return {
    labels: productivity.map((prod: any, key: any) => prod.user),
    datasets: [
      {
        label: "Productivity Hours",
        data: productivity.map((prod: any) => prod.time_in_hours),
        backgroundColor: "rgba(0, 170, 79, 0.2)",
        borderColor: "rgba(0, 170, 79, 1)",
        borderWidth: 1,
      },
    ],
  };
};

const groupByUsers = (productivity: any) => {
  const grouped = _.chain(productivity)
    .groupBy("user_id")
    .map((group) => {
      return {
        user: group[0].user,
        time_in_hours: _.sumBy(group, "time_in_hours"),
      };
    })
    .value();
  return grouped;
};

interface OverviewProductivityProps {
  modelId: number;
  modelType: string;
  to: string;
  from: string;
  allDates: any;
  projectId: number;
}

const OverviewProductivity = (props: OverviewProductivityProps) => {
  const { modelId, modelType, to, from, allDates, projectId } = props;

  const [search, setSearch] = useState<string>("");
  const [tableInstance, setTableInstance] = useState<Table<Productivity>>();

  const { toggle, modal } = useModal();

  const [selectedProductivity, setSelectedProductivity] = useState<any | null>(
    null,
  );

  const generateCsv = useCallback(() => {
    generateCsvFile({
      CsvFileName: "Productivity",
      selectedRows: false,
      csvTable: tableInstance,
    });
  }, [
    tableInstance,
    tableInstance?.getSelectedRowModel(),
    tableInstance?.getRowModel(),
  ]);

  const [isEditable, setIsEditable] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Productivity[]>([]);

  const query = {
    startDate: !allDates ? `${dayjs(from).format("YYYY-MM-DD")}` : null,
    endDate: !allDates ? `${dayjs(to).format("YYYY-MM-DD")}` : null,
    model: modelType,
    modelId,
    withJsonResouceWrapping: "1",
  };

  const { data: projectJobs, loading: projectJobsLoading } = useApi(
    `custom-fields/jobs?filter[project_id]=${projectId ?? modelId}`,
    [],
  );

  const {
    data: productivity,
    refreshData,
    loading,
    setUrl,
    setData,
  }: IUseApiWithData<Productivity[]> = useApi(
    `/productivity-dashboard?${queryString.stringify(query)}`,
    [],
    true,
  );

  useEffect(() => {
    if (to && from && !allDates) {
      setData([]);
      setUrl(`/productivity-dashboard?${queryString.stringify(query)}`);
    }
  }, [to, from, allDates]);

  useEffect(() => {
    setData([]);
    setUrl(`/productivity-dashboard?${queryString.stringify(query)}`);
  }, [allDates]);

  if (loading && projectJobsLoading && productivity.length === 0) {
    return <CustomScaleLoader>Fetching Productivity...</CustomScaleLoader>;
  }

  if (productivity.length === 0) {
    return (
      <div className="h-100 w-100">
        <SVGContainer SVG={Analytics}>
          <p className="mb-3 text-center">
            No Productivity Hours logged for this project.
            <br />
          </p>
        </SVGContainer>
      </div>
    );
  }

  const handleRowClick = (row: any) => {
    setSelectedProductivity(row);
    toggle();
  };

  const filteredProductivity = productivity.filter((prod) => {
    return (
      prod.user.toLowerCase().includes(search.toLowerCase()) ||
      prod.job_full_name.toLowerCase().includes(search.toLowerCase()) ||
      dayjs(prod.start_time).format("DD/MM/YYYY").includes(search) ||
      prod.time_in_hours.toString().includes(search) ||
      prod.chargeout_amount.toString().includes(search)
    );
  });

  const totalChargeoutAmount = _.sumBy(
    filteredProductivity,
    "chargeout_amount",
  );

  return (
    <div className="row mg-b-30 w-100">
      <div className="col-md-12 mg-t-20 mg-md-t-0">
        <Bar
          options={options}
          data={formatProductivity(groupByUsers(filteredProductivity))}
        />
      </div>
      <div className="col-12">
        <div className="card card-dash-one mg-t-20 w-100 rounded-lg">
          <div className="row no-gutters rounded-lg">
            <div className="col-lg-6 rounded-start-lg">
              <i className="icon ion-ios-pie-outline" />
              <div className="dash-content w-100">
                <label className="tx-secondary w-100 d-flex">
                  <span className="ms-auto">Hours</span>
                </label>
                <h2 className="text-end">
                  {_.sumBy(filteredProductivity, "time_in_hours").toFixed(1)}
                </h2>
              </div>
            </div>

            <div className="col-lg-6 rounded-end-lg">
              <i className="icon ion-ios-analytics-outline" />
              <div className="dash-content w-100">
                <label className="tx-success w-100 d-flex">
                  <span className="ms-auto text-end">Estimated Chargeout</span>
                </label>
                <h2>
                  <SpreadPrice price={totalChargeoutAmount} />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="d-flex mb-3 align-items-center">
          <div className="d-flex flex-grow-1">
            <SearchInput
              value={search}
              setValue={setSearch}
              style={{ zIndex: 1 }}
              placeholder="Search..."
              wrapperclass="w-100"
            />
          </div>
          <div>
            <Button
              outline
              size="sm"
              className="btn m-1"
              onClick={() => {
                setIsEditable(!isEditable);
                setSelectedRows([]);
              }}
            >
              {isEditable ? "Disable Edit" : "Enable Edit"}
            </Button>
          </div>
          <div>
            <Button outline size="sm" className="btn m-1" onClick={generateCsv}>
              Download CSV
            </Button>
          </div>
        </div>
        <ProductivityList
          productivity={filteredProductivity}
          modelId={modelId}
          refreshData={refreshData}
          loading={loading}
          projectJobs={projectJobs}
          handleRowClick={handleRowClick}
          isEditable={isEditable}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setTableInstance={setTableInstance}
        />
      </div>
      <SplitProductivityModal
        content={(toggle: () => void) =>
          selectedProductivity && (
            <SplitProductivityForm
              refreshData={refreshData}
              toggle={toggle}
              projectJobs={projectJobs}
              modal={modal}
              selectedProductivity={selectedProductivity}
              projectId={projectId}
            />
          )
        }
        toggle={toggle}
      />
    </div>
  );
};

export default OverviewProductivity;
