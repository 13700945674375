import { Field } from "redux-form";
import FormModal from "../../utils/FormModal";
import renderField from "../../utils/renderField";
import required from "../../utils/required";
import useModal from "../../hooks/useModal";
import useApi from "../../api/useApi";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import errorSwal from "../../utils/errorSwal";
import { toast } from "react-toastify";
import PaginatedSelectInput from "../../form/PaginatedSelectInput";
import TextButton from "../../utils/TextButton";
import { FaCloudDownloadAlt } from "react-icons/fa";

const SampleDumpModal = ({ filters }: { filters: any[] }) => {
  const { takeAction } = useApi();
  const { modal, toggle } = useModal();

  const generateDump = (data: any) => {
    if (parseInt(data.from) > parseInt(data.to)) {
      return toast.warning(
        "The end sample number must be greater than or equal to the start sample number.",
      );
    }

    const baseUrl = "samples/dump";
    const params = new URLSearchParams();
    params.append("filter[number_between]", `${data.from},${data.to}`);

    params.append("sort", "number");

    if (data.project_id) {
      params.append("filter[project_id]", data.project_id);
    }

    const url = `${baseUrl}?${params.toString()}`;

    return takeAction("store", url)
      .then((res: any) => {
        fileDownload(
          res.data,
          `Sample-Export ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        );
        toast.success("Downloading File!");
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <>
      <TextButton
        onClick={toggle}
        color="primary"
        className="text-decoration-none text-secondary"
      >
        <FaCloudDownloadAlt /> Download Samples
      </TextButton>
      <FormModal
        title="Choose Sample Range"
        onSubmit={generateDump}
        modal={modal}
        toggle={toggle}
        form="SampleDump"
      >
        <div className="col-lg-12 form-group">
          <Field
            component={PaginatedSelectInput}
            name="project_id"
            url="projects?"
            label="Project"
            formatData={(data: any[]) => {
              return data.map((d) => ({
                label: (
                  <div>
                    <p className="mb-0 text-dark fw-bolder">{d.name}</p>
                    <p className="mb-0 tx-12">{d.number}</p>
                  </div>
                ),
                value: d.id,
              }));
            }}
          />
        </div>
        <div className="col-6 form-group">
          <Field
            component={renderField}
            name="from"
            label="Start Sample Number"
            validate={required}
            required
            type="number"
          />
        </div>
        <div className="col-6 form-group">
          <Field
            component={renderField}
            name="to"
            label="End Sample Number"
            validate={required}
            required
            type="number"
          />
        </div>
      </FormModal>
    </>
  );
};

export default SampleDumpModal;
