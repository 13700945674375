import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import FormTestRequest from "./TestRequestForm";
import formError from "../utils/formError";

const Add = (props) => {
  const { takeAction } = useApi();

  const { projectUuid } = useParams();

  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", `projects/${projectUuid}/test-requests`, values)
      .then(({ data }) => {
        toast.success("Test request created successfully!");

        history.push(`/testrequests/${data.data.testrequest.uuid}/details`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add New Test Request"
        crumbs={[
          { link: "testrequests", name: "Test Request" },
          {
            link: "testrequests/add",
            name: "New Test Request",
            active: true,
          },
        ]}
      />

      <FormTestRequest {...props} onSubmit={onSubmit} form="AddTestRequest" />
    </>
  );
};

export default Add;
