import { toast } from "react-toastify";
import {
  Field,
  FieldArray,
  formValueSelector,
  initialize,
  reduxForm,
} from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import PurchaseItems from "../purchaseItems/PurchaseItems";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { GroupType } from "../user/userTypes";
import PurchaseOrderEquipmentFields from "./PurchaseOrderEquipmentFields";
import { connect } from "react-redux";
import { IntegrationType } from "../integrations/integrationTypes";
import FormHeader from "../utils/FormHeader";
import FormErrorAlert from "../form/FormErrorAlert";
import formError from "../utils/formError";
import { useEffect } from "react";

const InvoiceForm = (props) => {
  const {
    handleSubmit,
    purchaseOrder,
    setPurchaseOrder,
    dispatch,
    purchaseItems,
    change,
    initialized,
  } = props;

  const { takeAction } = useApi();
  const { user } = useAuth();

  const { response, takeAction: checkNumber } = useApi();

  //2 is fixed asset
  const hasFixedAsset = purchaseItems?.some(
    (item) => item.chart_of_accounts_type == 2,
  );

  const submit = (values) => {
    return uploadToS3(
      values.document,
      `organisations/${user.active_organisation.uuid}/purchase-order-invoices`,
    )
      .then((documents) => {
        const data = {
          ...values,
        };

        if (documents.length) {
          data.documents = documents;
        }

        return takeAction(
          "store",
          `purchase-invoices/${purchaseOrder.purchase_invoice.uuid}`,
          data,
        );
      })
      .then(({ data }) => {
        toast.success(`${purchaseOrder.name} invoice updated`);
        dispatch(initialize("PurchaseInvoice", data.data.purchase_invoice));

        return setPurchaseOrder(data.data);
      })
      .catch(formError);
  };

  useEffect(() => {
    if (!hasFixedAsset && initialized) {
      change("equipment", null);
    }
  }, [purchaseItems]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        {!purchaseOrder.billed_at && (
          <div className="col-12 form-group">
            <Field
              required={
                !purchaseOrder.purchase_invoice.file.link ? true : false
              }
              validate={
                !purchaseOrder.purchase_invoice.file.link ? required : null
              }
              component={FileInput}
              name="document"
              label="Invoice File"
            />
          </div>
        )}
        <div className="col-lg-6 form-group">
          <Field
            name="date"
            component={renderField}
            label="Date"
            required
            validate={required}
            type="date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="number"
            component={renderField}
            label="Supplier Invoice Number"
            required
            validate={required}
            onBlur={(_, number) => {
              checkNumber(
                "store",
                `purchase-invoices/${purchaseOrder.purchase_invoice.uuid}/validate-number`,
                {
                  number,
                },
              );
            }}
          />
          {response?.response?.status === 422 && (
            <small className="tx-warning">
              {response?.response?.data.errors.number[0]}
            </small>
          )}
        </div>

        <FieldArray
          component={PurchaseItems}
          {...props}
          name="purchase_items"
          disabled={purchaseOrder.billed_at}
        />

        {hasFixedAsset &&
          !props.creditCard &&
          user.active_organisation.integration_types.includes(
            IntegrationType.Accounting,
          ) && (
            <>
              <FormHeader
                extra={
                  <p className="mb-0">
                    At least on piece of equipment is required for fixed asset
                    purchases
                  </p>
                }
              >
                Equipment
              </FormHeader>
              <FieldArray
                change={props.change}
                component={PurchaseOrderEquipmentFields}
                initialValues={props.initialValues}
                name="equipment"
              />
            </>
          )}

        {(!purchaseOrder.billed_at ||
          user.groups.includes(GroupType.Accounting)) && (
          <div className="form-group col-12">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({ form: "PurchaseInvoice" });

const mapStateToProps = (state) => {
  const selector = formValueSelector("PurchaseInvoice");

  return {
    purchaseItems: selector(state, "purchase_items"),
  };
};

export default connect(mapStateToProps, {})(form(InvoiceForm));
