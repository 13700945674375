import React from "react";
import { Progress, Spinner } from "reactstrap";
import { BootstrapColor } from "../timesheets/ProductivityProgress";
import {
  ProductivityOverviewType,
  BreakType,
} from "../timesheets/timesheetTypes";

type ColorFunction = (percent: number) => BootstrapColor;

interface ProductivityOverviewProps extends ProductivityOverviewType {
  loading?: boolean;
  width?: number;
  headerElements?: React.ReactNode;
  hoursAddedText?: string;
  billableColor?: ColorFunction;
  nonBillableColor?: ColorFunction;
  break_productivity?: BreakType;
  extraInfo?: any;
}

const ProductivityOverview = ({
  loading = false,
  timesheets,
  total_productivity,
  billable_productivity,
  non_billable_productivity,
  break_productivity,
  width = 8,
  headerElements,
  hoursAddedText = "Approved Timesheet Hours Added.",
  billableColor,
  nonBillableColor,
  extraInfo,
}: ProductivityOverviewProps) => {
  return (
    <div className={`position-relative col-lg-${width} mb-3 mb-lg-0`}>
      <div className="card p-4 h-100 d-flex flex-column justify-content-between position-relative">
        {loading && (
          <div className="z-index-10 t-0 l-0 h-100 w-100 d-flex align-items-center justify-content-center position-absolute bg-white-4">
            <Spinner color="primary" />
          </div>
        )}
        <div className="d-flex">
          <div className="mg-b-20">
            <h6 className="slim-card-title mb-0">Productivity Overview</h6>
            <small>
              {timesheets?.toFixed(2)} {hoursAddedText}
            </small>
          </div>
          {headerElements}
        </div>
        {extraInfo &&
          extraInfo({
            billablePercentage: percent(
              billable_productivity,
              total_productivity,
            ),
          })}
        <div>
          <p className="mb-1">
            Total Productivity ({total_productivity?.toFixed(2)} Hours)
          </p>
          <Progress
            className="mb-2"
            value={percent(total_productivity, timesheets)}
          >
            {percent(total_productivity, timesheets)}%
          </Progress>
          <p className="mb-1">
            Billable Productivity ({billable_productivity?.toFixed(2)} Hours)
          </p>
          <Progress
            className="mb-2"
            value={percent(billable_productivity, total_productivity)}
            color={
              billableColor
                ? billableColor(
                    percent(billable_productivity, total_productivity),
                  )
                : ""
            }
          >
            {percent(billable_productivity, total_productivity)}%
          </Progress>
          <p className="mb-1">
            Non Billable Productivity ({non_billable_productivity?.toFixed(2)}{" "}
            Hours)
          </p>
          <Progress
            className="mb-2"
            value={percent(non_billable_productivity, total_productivity)}
            color={
              nonBillableColor
                ? nonBillableColor(
                    percent(non_billable_productivity, total_productivity),
                  )
                : ""
            }
          >
            {percent(non_billable_productivity, total_productivity)}%
          </Progress>
          <p className="mb-1">
            Breaks ({break_productivity?.toFixed(2)} Hours)
          </p>
          <Progress
            className="mb-2"
            value={percent(break_productivity, total_productivity)}
            color={
              nonBillableColor
                ? nonBillableColor(
                    percent(break_productivity, total_productivity),
                  )
                : ""
            }
          >
            {percent(break_productivity, total_productivity)}%
          </Progress>
        </div>
      </div>
    </div>
  );
};

export const percent = (
  numerator: number | undefined,
  denominator: number | undefined,
): number => {
  if (!denominator || !numerator) {
    return 0;
  }

  if (denominator === 0 && numerator === 0) {
    return 0;
  }

  const percent = ((numerator / denominator) * 100).toFixed(0);

  return parseInt(percent);
};

export default ProductivityOverview;
