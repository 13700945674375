import { Layout, OrgChart } from "d3-org-chart";
import React, { useLayoutEffect, useRef } from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import ChartControl, { ChartControlProps } from "./ChartControl";

export interface OrganisationChartUser {
  id: string;
  name: string;
  position: string;
  is_organisation_head: boolean;
  parent_id: number;
}

interface OrgChartComponentProps {
  data: any[];
}

export const OrgChartComponent = React.forwardRef<
  HTMLDivElement,
  OrgChartComponentProps
>((props, ref) => {
  const d3Container = useRef<HTMLDivElement | null>(null);
  let chart: OrgChart<ChartControlProps> | null = null;

  useLayoutEffect(() => {
    if (props.data.length > 0 && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }

      var index = 0;
      var compact = 0;
      chart
        .nodeHeight(() => 85 + 25)
        .nodeWidth(() => 200)
        .childrenMargin(() => 80)
        .compactMarginBetween(() => 75)
        .compactMarginPair(() => 80)
        .nodeContent(function (d: any, i: any, arr: any, state: any) {
          const color = "#FFFFFF";
          const imageDiffVert = 25 + 2;
          const initials = (d.data.full_name.match(/\b\w/g) || []).join("");

          return `
            <div style='width:${
              d.width
            }px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
              <div style="font-family: 'Inter', sans-serif;background-color:${color};margin-left:-1px;width:${d.width - 2}px;height:${d.height - imageDiffVert}px;border-radius:10px;border: 1px solid #E4E2E9">
                <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px">#${
                  d.data.id
                }</div>
                <div style="background-color:${color};margin-top:${-imageDiffVert - 20}px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;" ></div>
                <div style="margin-left: 20px;margin-top:${
                  -imageDiffVert - 20
                }px;">   
                  <svg height="40" width="40">
                    <circle cx="20" cy="20" r="20" fill="#002b49"/>
                    <text fill="#ffffff" fontSize="16" textAnchor="middle" x="10" y="25">
                      ${initials}
                    </text>
                  </svg>
                </div>
                <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px;margin-right:20px"> ${
                  d.data.full_name
                } </div>
                <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;margin-right:20px"> ${
                  d.data.primary_position.name
                } </div>
              </div>
            </div>
          `;
        })
        .container("#d3-org-chart")
        .data(props.data)
        .render()
        .expandAll();

      reRenderChartControls();

      function handleSwap() {
        if (!chart) return;

        chart
          .layout(["right", "bottom", "left", "top"][index++ % 4] as Layout)
          .render()
          .fit();
      }

      function reRenderChartControls() {
        const container = document.getElementById("chart_tree_top_menu");
        if (container && chart) {
          const root = createRoot(container);
          root.render(
            <ChartControl chart={chart} swap={handleSwap} compact={compact} />,
          );
        }

        props.data.forEach((d) => {
          const container = document.getElementById(`chart_tree_${d.id}`);
          if (container && chart) {
            const root = createRoot(container);
            root.render(
              <ChartControl
                chart={chart}
                swap={handleSwap}
                compact={compact}
              />,
            );
          }
        });
      }
    }
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1px",
        }}
        id="chart_tree_top_menu"
      />
      <div id="d3-org-chart" ref={d3Container}></div>
    </div>
  );
});
