import { toast } from "react-toastify";

import TestRequestForm from "./TestRequestForm";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import formError from "../utils/formError";

const EditTestRequest = (props) => {
  const { setTestRequest, handleSubmit } = props;

  const { uuid } = useParams();
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `testrequests/${uuid}`, values)
      .then(({ data }) => {
        setTestRequest(data.data);
        toast.success(`${data.data.testrequest.title} updated successfully`);
      })
      .catch(formError);
  };

  return (
    <TestRequestForm {...props} form="editTestRequest" onSubmit={onSubmit} />
  );
};

export default EditTestRequest;
