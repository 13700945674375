import AccountCard from "../accounts/AccountCard";
import AccountRequestCard from "../accounts/AccountRequestCard";
import CreateAccountRequest from "../accounts/CreateAccountRequest";
import { AccountRequest } from "../accounts/accountTypes";
import useModal from "../hooks/useModal";
import { Client } from "./clientTypes";
import FormHeader from "../utils/FormHeader";
import DocsModal from "../utils/DocsModal";
import useApi from "../api/useApi";

interface ClientAccountDetailsProps {
  client: Client;
  setClient: (client: Client) => void;
  refreshData: () => void;
}

const ClientAccountDetails = (props: ClientAccountDetailsProps) => {
  const { client, setClient, refreshData } = props;
  const { toggle, modal } = useModal();

  const { data: defaultSettings } = useApi("accounts/settings", {}, true);

  const onUpdateRequest = (request: AccountRequest) => {
    setClient({
      ...client,
      account_request: request,
    });
  };

  return (
    <div className="space-y-5">
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/clients/client-accounts/" />
      <DetailsInfo toggle={toggle} accountRequest={client.account_request} />
      {client.account_request ? (
        <AccountRequestCard
          accountRequest={client.account_request}
          onUpdated={onUpdateRequest}
          onCompleted={refreshData}
        />
      ) : null}
      <AccountCard
        account={{
          account_limit: client.account_limit,
          invoice_terms: client.invoice_terms,
          invoice_terms_amount: client.invoice_terms_amount,
        }}
        currentRequest={client.account_request}
        requestable={{
          type: "App\\Models\\OrganisationClient",
          id: client.id,
        }}
        onCreated={onUpdateRequest}
      />
      <CreateAccountRequest
        toggle={toggle}
        modal={modal}
        initialValues={{
          invoice_terms: defaultSettings?.invoice_terms,
          invoice_terms_amount: defaultSettings?.invoice_terms_amount,
        }}
        requestable={{
          type: "App\\Models\\OrganisationClient",
          id: client.id,
        }}
        onCreated={onUpdateRequest}
      />
      {client.previous_account_requests.length > 0 && (
        <div className="row">
          <FormHeader>Previous Requests</FormHeader>
        </div>
      )}
      {client.previous_account_requests.map((request) => {
        return <AccountRequestCard accountRequest={request} />;
      })}
    </div>
  );
};

const DetailsInfo = ({
  toggle,
  accountRequest,
}: {
  toggle: () => void;
  accountRequest?: AccountRequest;
}) => {
  return (
    <div
      className="position-relative rounded-lg shadow"
      style={{ height: "200px" }}
    >
      <div
        className="position-absolute rounded-lg w-100 p-3"
        style={{
          backgroundImage:
            "url(https://boss-prod.s3.ap-southeast-2.amazonaws.com/accounting.jpg)",
          height: "200px",
          backgroundSize: "cover",
          filter: "brightness(0.6)",
          backgroundPositionY: "-80px",
        }}
      />
      <div
        className="position-absolute z-10 text-white w-100 p-5 d-flex align-items-center"
        style={{ height: "200px" }}
      >
        <div>
          <p className="tx-18">Account Details</p>
          <p className="mb-0" style={{ color: "#d7d7d7" }}>
            See client's account details
            {!accountRequest ? (
              <>
                {" "}
                or{" "}
                <button
                  type="button"
                  className="bg-transparent border-0 p-0 text-white"
                  onClick={toggle}
                >
                  request an update
                </button>
              </>
            ) : null}
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClientAccountDetails;
