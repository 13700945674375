import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/api";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";

import PaginatedList from "../pagination/PaginatedList";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import useSuppliers from "./hooks/useSuppliers";
import { IFilter } from "../hooks/useFilter";
import { AuthUser } from "../user/userTypes";
import { useAuth } from "../../context/auth-context";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import { SupplierIndex } from "./supplierTypes";
import { useMemo } from "react";
import Avatar from "../utils/Avatar";
import { UncontrolledTooltip } from "reactstrap";
import SupplierTable from "./SupplierTable";
import { Project } from "../../enums/Model";

export type SupplierFilters = IFilter<"is_on_hold", boolean | number>;

const Suppliers = () => {
  const filters = useFilters();
  const tabs: Tab[] = [
    {
      title: "Cards",
      component: (
        <PaginatedList
          listName="supplierList"
          indexHook={useSuppliers}
          originalFilters={filters}
          itemCard={({ item }: { item: any }) => (
            <PaginatedCard
              key={item.uuid}
              info={info(item)}
              header={<SupplierHeader supplier={item} />}
              bottomContent={<SupplierFooter supplier={item} />}
            />
          )}
        />
      ),
    },
    {
      title: "Table",
      component: (
        <PaginatedList
          listName="supplierList"
          indexHook={useSuppliers}
          originalFilters={filters}
          list={({ data }: { data?: SupplierIndex[] }) => {
            return (
              <div>
                <SupplierTable suppliers={data ?? []} />
              </div>
            );
          }}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderPage
        titlePage="Suppliers"
        pageDescription={
          <>
            Add and manage suppliers that provide goods and/or services to your
            business.
          </>
        }
        relatedLinks={[{ name: "Projects", link: "/projects", model: Project }]}
        crumbs={[{ link: "", name: "Suppliers", active: true }]}
      />
      <div className="mb-3">
        <Link to="/suppliers/add" className="btn btn-outline-primary">
          Add New Supplier
        </Link>
        <DownloadButton />
      </div>
      <Tabs
        tabs={tabs}
        startingTab={parseInt(localStorage.getItem("supplier_list_tab") ?? "0")}
        onSelect={(i) => {
          localStorage.setItem("supplier_list_tab", `${i}`);
        }}
      />
    </>
  );
};

const DownloadButton = () => {
  const [downloading, setDownloading] = useState(false);

  const downloadData = () => {
    setDownloading(true);

    axios
      .post(`/supplier-exports`)
      .then((res) => {
        fileDownload(
          res.data,
          `suppliers ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        );
      })
      .catch(errorSwal)
      .then(() => setDownloading(false));
  };

  return (
    <button
      className="btn btn-link ms-2"
      type="button"
      onClick={downloadData}
      disabled={downloading}
    >
      {isSubmitting(
        downloading,
        <>
          <i className="fa fa-download tx-primary tx-16" /> Download CSV
        </>,
        "Generating...",
      )}
    </button>
  );
};

const useFilters = (): SupplierFilters[] => {
  return [
    {
      label: "Status",
      name: "is_on_hold",
      options: [
        {
          label: "On Hold",
          value: true,
        },
      ],
    },
  ];
};

const SupplierHeader = ({ supplier }: { supplier: SupplierIndex }) => {
  const tooltipId = useMemo(
    () => `supplier_title_${supplier.uuid}`,
    [supplier.uuid],
  );

  const color = supplier.is_hold ? "danger" : "info";

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={supplier.display_name} colour={color} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={supplier.link}>{supplier.display_name}</Link>
          </p>
          {supplier.uuid && (
            <UncontrolledTooltip placement="top-start" target={tooltipId}>
              {supplier.display_name}
            </UncontrolledTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const SupplierFooter = ({ supplier }: { supplier: SupplierIndex }) => {
  const color = supplier.is_hold ? "bg-danger" : "bg-info";

  const text = supplier.is_hold ? "Hold" : "Open";

  return (
    <div className="d-flex align-items-center mb-1 mt-2">
      <div className=" ms-auto">
        <div
          className={`${color} shadow rounded-pill text-white tx-10 text-center px-2`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const info = (supplier: SupplierIndex): PaginatedInformation[] => {
  return [
    {
      name: "Company Name",
      value: supplier?.name,
      link: supplier?.link,
    },
    {
      name: "Type",
      value: supplier?.type,
    },
    {
      name: "ABN Holder",
      value: supplier?.abn_holder ? "Yes" : "No",
    },
    {
      name: "Contact Email",
      value: supplier?.contact_email,
    },
    {
      name: "Accounts Email",
      value: supplier?.accounts_email,
    },
    {
      name: "Contact Telephone",
      value: supplier?.contact_telephone,
    },
  ];
};

export default Suppliers;
