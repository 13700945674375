import React from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { MdAdd, MdDelete } from "react-icons/md";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { Button } from "reactstrap";
import renderToggleInput from "../utils/renderToggleInput";
import useApi from "../api/useApi";
import TextButton from "../utils/TextButton";
import { useParams } from "react-router-dom";

const MAX_FIELDS = 3;

interface EmployeeSuperFundFormProps extends WrappedFieldArrayProps<any> {
  handleOpenModal: (index: number, value: any) => void;
  change: (field: string, value: any) => void;
}

const EmployeeSuperFundForm = ({
  fields,
  handleOpenModal,
  change,
}: EmployeeSuperFundFormProps) => {
  const { userId } = useParams<{ userId: string }>();

  const { data: integrationSetting } = useApi(
    `payroll-integration/integration-settings`,
    null,
    true,
  );

  return (
    <>
      <div className="d-flex align-items-center">
        <label className="section-title my-0">Super Funds</label>
        <div className="ms-auto">
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => {
              fields.push({});
            }}
            disabled={fields.length >= MAX_FIELDS}
          >
            <MdAdd className="tx-18" /> Add
          </Button>
        </div>
      </div>
      <hr className="w-100" />

      {fields.map((field, index) => {
        const value = fields.get(index);

        return (
          <div className="row" key={index}>
            <div className="col-12 d-flex mb-4 mt-3">
              <p className="mb-0 text-dark">Fund {index + 1}</p>
              <div className="d-flex  ms-auto align-items-center">
                <Field
                  component={renderToggleInput}
                  icons={false}
                  id={`${field}.employerNominatedFund`}
                  name={`${field}.employerNominatedFund`}
                  className="toggle-sm"
                  disabled={!integrationSetting?.employer_nominated_fund_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      change(
                        `${field}.fundName`,
                        integrationSetting?.employer_nominated_fund_name,
                      );
                      change(
                        `${field}.productCode`,
                        integrationSetting?.employer_nominated_product_code,
                      );

                      if (!userId) {
                        change(`${field}.memberNumber`, null);
                      }

                      return;
                    }

                    change(`${field}.fundName`, null);
                    change(`${field}.productCode`, null);
                  }}
                />
                <label
                  htmlFor={`${field}.employerNominatedFund`}
                  className="ms-2 mb-0 no-select"
                  style={{ fontSize: "12px" }}
                >
                  {integrationSetting?.employer_nominated_fund_name
                    ? "Employer Nominated Fund"
                    : "No Employer Nominated Fund found, please add in 'Integrations'"}
                </label>
              </div>
            </div>

            <div className="col-lg-3 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Fund Name
              </label>
              <div className="readOnly">
                {value?.employerNominatedFund ? (
                  <span>{value?.fundName}</span>
                ) : (
                  <TextButton
                    className="text-start text-dark"
                    onClick={() => handleOpenModal(index, value)}
                  >
                    {value?.fundName || "Select Super Fund"}
                  </TextButton>
                )}
              </div>
            </div>
            <div className="col-lg-2 form-group">
              <Field
                component={RenderField}
                name={`${field}.productCode`}
                label="Product Code"
                readOnly
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                name={`${field}.memberNumber`}
                label="Member Number"
                extraProps={{
                  disabled: value.employerNominatedFund && !userId,
                }}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={RenderField}
                name={`${field}.allocatedPercentage`}
                label="Allocated Percentage"
                validate={required}
              />
            </div>
            <div className="col-lg-1 form-group">
              <label>Delete</label>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <MdDelete className="tx-18" />
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EmployeeSuperFundForm;
