const SVGContainer = ({
  SVG,
  children,
  height = "100%",
  width = "50%",
  className = "",
  top = true,
  wrapperClass = "",
}) => {
  return (
    <div className={wrapperClass} style={{ userSelect: "none" }}>
      <>
        {top && (
          <div className={`d-flex justify-content-center ${className}`}>
            {children}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <SVG height={height} width={width} className="drop-shadow" />
        </div>
        {!top && (
          <div className={`d-flex justify-content-center ${className}`}>
            {children}
          </div>
        )}
      </>
    </div>
  );
};

export default SVGContainer;
