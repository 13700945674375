import { useState } from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import ProfileCards from "../timesheets/ProfileCards";
import Avatar from "../utils/Avatar";
import Spinner from "../utils/Spinner";
import { DirectReport } from "./directReportTypes";
import TimesheetSummary from "../timesheets/TimesheetSummary";

const DirectReports = () => {
  const { data, loading }: IUseApi<DirectReport[]> = useApi(
    "direct-reports",
    [],
    true,
  );

  const [selectedUser, setSelectedUser] = useState<{ user_uuid: string }>();

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Direct Reports" />
      <div className="flex-nowrap row overflow-auto">
        {data?.map((directReport, index) => {
          return (
            <div className="col-lg-3" key={directReport.id}>
              <div className="bg-white w-100 border rounded-lg p-0">
                <button
                  type="button"
                  className="p-3 d-flex bg-transparent border-0 w-100 justify-content-center flex-column align-items-center "
                  tabIndex={index + 1}
                  onClick={() =>
                    setSelectedUser({ user_uuid: directReport.uuid })
                  }
                >
                  <Avatar
                    colour="info"
                    rounded="-circle"
                    name={directReport.name}
                    size="60px"
                  />
                  <p className="mb-0 fs-5 mt-4">{directReport.name}</p>
                  {directReport.staff_roles.map((staffRole) => {
                    return (
                      <p className="text-secondary mb-0">{staffRole.name}</p>
                    );
                  })}
                </button>
                <div className="border-top p-3 d-flex justify-content-between">
                  {directReport.email ? (
                    <a
                      className="btn btn-info text-white btn-sm"
                      href={`mailto:${directReport.email}`}
                    >
                      Email
                    </a>
                  ) : null}
                  {directReport.phone ? (
                    <a
                      className="btn btn-sm border"
                      href={`tel:${directReport.phone}`}
                    >
                      Call
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-4">
        {selectedUser?.user_uuid ? (
          <TimesheetSummary userUuid={selectedUser?.user_uuid} />
        ) : null}
      </div>
      <div className="mt-4">
        <ProfileCards timesheet={selectedUser} />
      </div>
    </>
  );
};

export default DirectReports;
