import { useState } from "react";
import { ModalBody, ModalHeader, Modal, Button } from "reactstrap";
import { SelectOption } from "../../utils/utilTypes";
import useModal from "../../hooks/useModal";
import { useRefinementList } from "react-instantsearch";
import { MultiValue } from "react-select"; // Make sure to import this type
import TextButton from "../../utils/TextButton";
import { FaFilter } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import FormHeader from "../../utils/FormHeader";
import dayjs from "dayjs";

const SampleFilterModal = ({
  setFilters,
  filters,
  between,
  setBetween,
}: {
  setFilters: Function;
  filters: any[];
  between: [number | undefined, number | undefined];
  setBetween: Function;
}) => {
  const [selectedProjects, setSelectedProjects] = useState<SelectOption[]>([]);
  const [selectedClients, setSelectedClients] = useState<SelectOption[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<SelectOption[]>([]);

  const { toggle, modal } = useModal();

  const {
    items: projects,
    refine: projectRefine,
    searchForItems: projectSearch,
  } = useRefinementList({
    attribute: "project.name",
    operator: "or",
  });

  const {
    items: clients,
    refine: clientRefine,
    searchForItems: clientSearch,
  } = useRefinementList({
    attribute: "client.name",
    operator: "or",
  });

  const { items: branches, refine: branchRefine } = useRefinementList({
    attribute: "branch.name",
    operator: "or",
  });

  const handleRefinement = (
    field: string,
    items: Array<{ value: string; isRefined: boolean }>,
    refineFn: (value: string) => void,
    selectedOptions: SelectOption[] | null,
  ) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value.toString()) // Convert to string
      : [];

    if (values.length === 0) {
      items.forEach((item) => item.isRefined && refineFn(item.value));
    } else {
      items.forEach((item) => item.isRefined && refineFn(item.value)); // Clear existing refinements
      values.forEach((value) => refineFn(value)); // Apply new refinements
    }

    setFilters({
      ...filters,
      [field]: selectedOptions?.map((d: any) => d.value),
    });
  };

  return (
    <>
      <TextButton onClick={toggle}>
        <FaFilter className="tx-16" />
      </TextButton>
      <Modal className="wd-md-600 mx-wd-800" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filter Samples</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-4 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Project
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedProjects}
                loadOptions={(inputValue, callback) => {
                  projectSearch(inputValue);

                  callback(
                    projects.map((item) => ({
                      value: item.value,
                      label: item.label,
                    })),
                  );
                }}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedProjects(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "project.id",
                    projects,
                    projectRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Clients
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedClients}
                loadOptions={(inputValue, callback) => {
                  clientSearch(inputValue);

                  callback(
                    clients.map((item) => ({
                      value: item.value,
                      label: item.label,
                    })),
                  );
                }}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedClients(selectedOptions as SelectOption[]);

                  handleRefinement(
                    "clients.id",
                    clients,
                    clientRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Branches
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedBranches}
                loadOptions={(inputValue, callback) => {
                  clientSearch(inputValue);

                  callback(
                    branches.map((item) => ({
                      value: item.value,
                      label: item.label,
                    })),
                  );
                }}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedBranches(selectedOptions as SelectOption[]);

                  handleRefinement(
                    "branches.id",
                    branches,
                    branchRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>

            <FormHeader>Date Between</FormHeader>
            <div className="d-flex form-group space-x-3">
              <Button
                size="sm"
                outline
                onClick={() => {
                  const today: [number, number] = [
                    dayjs()
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                    dayjs()
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                  ];

                  // refineDate(today);
                  setBetween(today);
                }}
              >
                Today
              </Button>
              <Button
                size="sm"
                outline
                onClick={() => {
                  const yesterday: [number, number] = [
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                  ];

                  // refineDate(yesterday);
                  setBetween(yesterday);
                }}
              >
                Yesterday
              </Button>
              <Button
                size="sm"
                outline
                onClick={() => {
                  const lastWeek: [number, number] = [
                    dayjs()
                      .subtract(1, "week")
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                    dayjs()
                      .startOf("day")
                      .add(dayjs().utcOffset(), "minute")
                      .unix(),
                  ];
                  // refineDate(lastWeek);
                  setBetween(lastWeek);
                }}
              >
                Last Week
              </Button>
              <Button
                size="sm"
                outline
                onClick={() => {
                  // refineDate([undefined, undefined]);
                  setBetween([undefined, undefined]);
                }}
              >
                Clear Dates
              </Button>
            </div>
            <div className="col-lg-6 form-group">
              <label
                htmlFor="start"
                className="form-control-label tx-inverse tx-semibold"
              >
                From
              </label>
              <input
                type="date"
                className="form-control"
                id="start"
                value={
                  between?.[0]
                    ? dayjs.unix(between?.[0]).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  if (!e.target.value) {
                    // refineDate([undefined, between?.[1]]);
                    setBetween([undefined, between?.[1]]);
                  }

                  const newStart = dayjs
                    .utc(e.target.value, "YYYY-MM-DD")
                    .unix();

                  // refineDate([newStart, between?.[1]]);
                  setBetween([newStart, between?.[1]]);
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label
                htmlFor="end"
                className="form-control-label tx-inverse tx-semibold"
              >
                To
              </label>
              <input
                type="date"
                className="form-control"
                id="end"
                value={
                  between?.[1]
                    ? dayjs.unix(between?.[1]).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  if (!e.target.value) {
                    // refineDate([between?.[0], undefined]);
                    setBetween([between?.[0], undefined]);
                  }

                  const newEnd = dayjs.utc(e.target.value, "YYYY-MM-DD").unix();

                  console.log([between?.[0], newEnd]);

                  // refineDate([between?.[0], newEnd]);
                  setBetween([between?.[0], newEnd]);
                }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SampleFilterModal;
