import { IntegrationType } from "../integrations/integrationTypes";

export type UserSettingKeys = "load_work_day" | "auto_fill_purchase_branch";

export enum AdministrationLevels {
  Senior,
  Junior,
  Basic,
}

export interface UserIndex {
  id: number;
  uuid: string;
  full_name: string;
  email: string;
  secondary_email: string;
  phone: string;
  link: string;
  is_admin: boolean;
  jobs_count?: number;
  primary_position?: {
    name: string;
  };
  child_labour_hire_organisations?: {
    name: string;
    id: number;
  }[];
}

export interface EmployedUser {
  name: string;
  id: number;
  uuid: string;
  email: string;
}

export interface AuthUser {
  name: string;
  uuid: string;
  id: number;
  email: string;
  secondary_email?: string;
  has_mfa: boolean;
  two_factor_secret: boolean;
  two_factor_required_at?: {
    display_name: string;
    two_factor_enabled_at: string;
  };
  settings: {
    [k in UserSettingKeys]: boolean;
  };
  is_position_admin: boolean;
  is_admin: boolean;
  position_administration_level: AdministrationLevels;
  default_branch_id: number;
  active_organisation: {
    name: string;
    display_name: string;
    uuid: string;
    id: number;
    trading_name: string;
    beta: boolean;
    timezone: string;
    syncable_organisation_id?: number;
    integration_types: IntegrationType[];
    group_members: {
      id: number;
      name: string;
    }[];
    labour_hire_children: {
      id: number;
      name: string;
    }[];
    timesheet_required_time?: number;
  };
  acl_items: {
    model: string;
    method: string;
  }[];
  navigation: {
    name: string;
    links: {
      value: string;
      link: string;
    }[];
  }[];

  multiple_organisations: boolean;
  hasAccessTo: (model: string, method: string) => boolean;
  groups: string[];
  is_primary_organisation: boolean;
  timesheet_required: false | "warning" | "required";
  search_key: string;
}

export interface IncompleteUserTask {
  title: string;
  sub_title: string;
  items: {
    name: string;
    link: string;
  }[];
  count: number;
}

export interface Tab {
  link: String;
  page: String;
  icon: String;
  label: String;
}

export enum GroupType {
  Accounting = "Accounting",
  Safety = "Safety",
  HR = "HR",
  Administration = "Administration",
  Procedures = "Procedures",
  Compliance = "Compliance",
  Equipment = "Equipment",
  BusinessDevelopment = "Business Development",
  IT = "I.T.",
  Marketing = "Marketing",
}

export enum AboriginalEnum {
  Aboriginal = 1,
  TorresStraightIslander = 2,
  Neither = 3,
  Unspecified = 4,
}

export enum GenderEnum {
  Male = 1,
  Female = 2,
  Unspecified = 3,
  NonBinary = 4,
}

export interface Goal {
  name: string;
  description: string;
  percentage_complete: number;
  start_date?: string;
  to_be_completed_by?: string;
  id?: number;
  uuid?: string;
}

export type EmploymentHeroUser = {
  anniversaryDate: string; // assuming ISO date-time string
  australianResident: boolean;
  automaticallyApplyPublicHolidayNotWorkedEarningsLines: boolean;
  automaticallyPayEmployee: string;
  bankAccount1_AccountName: string;
  bankAccount1_AccountNumber: string;
  bankAccount1_AllocatedPercentage: number;
  bankAccount1_BSB: string;
  bankAccount1_FixedAmount: number;
  bankAccount2_AccountName: string;
  bankAccount2_AccountNumber: string;
  bankAccount2_AllocatedPercentage: number;
  bankAccount2_BSB: string;
  bankAccount2_FixedAmount: number;
  bankAccount3_AccountName: string;
  bankAccount3_AccountNumber: string;
  bankAccount3_AllocatedPercentage: number;
  bankAccount3_BSB: string;
  bankAccount3_FixedAmount: number;
  businessAwardPackage: string;
  claimMedicareLevyReduction: boolean;
  claimTaxFreeThreshold: boolean;
  closelyHeldEmployee: boolean;
  closelyHeldReporting: string;
  contractorABN: string;
  dateCreated: string; // assuming ISO date-time string
  dateOfBirth: string; // assuming ISO date-time string
  dateTaxFileDeclarationReported: string; // assuming ISO date-time string
  dateTaxFileDeclarationSigned: string; // assuming ISO date-time string
  dvlPaySlipDescription: string;
  emailAddress: string;
  emergencyContact1_Address: string;
  emergencyContact1_AlternateContactNumber: string;
  emergencyContact1_ContactNumber: string;
  emergencyContact1_Name: string;
  emergencyContact1_Relationship: string;
  emergencyContact2_Address: string;
  emergencyContact2_AlternateContactNumber: string;
  emergencyContact2_ContactNumber: string;
  emergencyContact2_Name: string;
  emergencyContact2_Relationship: string;
  employingEntityABN: string;
  employingEntityId: string;
  employmentAgreement: string;
  employmentType: string;
  endDate: string; // assuming ISO date-time string
  externalId: string;
  firstName: string;
  gender: string;
  hasApprovedWorkingHolidayVisa: boolean;
  hasWithholdingVariation: boolean;
  homePhone: string;
  hoursPerDay: number;
  hoursPerWeek: number;
  id: number;
  includeInPortableLongServiceLeaveReport: boolean;
  isEnabledForTimesheets: string;
  isExemptFromFloodLevy: boolean;
  isExemptFromPayrollTax: boolean;
  isSeasonalWorker: boolean;
  jobTitle: string;
  leaveAccrualStartDateType: string;
  leaveTemplate: string;
  leaveYearStart: string; // assuming ISO date-time string
  locations: string;
  maximumQuarterlySuperContributionsBase: number;
  medicareLevyExemption: string;
  medicareLevyReductionDependentCount: number;
  medicareLevyReductionSpouse: boolean;
  medicareLevySurchargeWithholdingTier: string;
  middleName: string;
  mobilePhone: string;
  otherTaxOffset: boolean;
  overrideTemplateRate: string;
  payConditionRuleSet: string;
  payRateTemplate: string;
  paySchedule: string;
  paySlipNotificationType: string;
  portableLongServiceLeaveId: string;
  postalAddressIsOverseas: boolean;
  postalAddressLine2: string;
  postalCountry: string;
  postalPostCode: string;
  postalState: string;
  postalStreetAddress: string;
  postalSuburb: string;
  preferredName: string;
  previousSurname: string;
  primaryLocation: string;
  primaryPayCategory: string;
  rate: number;
  rateUnit: string;
  reportingDimensionValues: string;
  residentialAddressIsOverseas: boolean;
  residentialAddressLine2: string;
  residentialCountry: string;
  residentialPostCode: string;
  residentialState: string;
  residentialStreetAddress: string;
  residentialSuburb: string;
  rosteringNotificationChoices: string;
  seniorsTaxOffset: boolean;
  singleTouchPayroll: string;
  startDate: string; // assuming ISO date-time string
  status: string;
  stslDebt: boolean;
  superFund1_AllocatedPercentage: number;
  superFund1_EmployerNominatedFund: boolean;
  superFund1_FixedAmount: number;
  superFund1_FundName: string;
  superFund1_MemberNumber: string;
  superFund1_ProductCode: string;
  superFund2_AllocatedPercentage: number;
  superFund2_EmployerNominatedFund: boolean;
  superFund2_FixedAmount: number;
  superFund2_FundName: string;
  superFund2_MemberNumber: string;
  superFund2_ProductCode: string;
  superFund3_AllocatedPercentage: number;
  superFund3_EmployerNominatedFund: boolean;
  superFund3_FixedAmount: number;
  superFund3_FundName: string;
  superFund3_MemberNumber: string;
  superFund3_ProductCode: string;
  superThresholdAmount: number;
  surname: string;
  tags: string;
  taxCategory: string;
  taxFileNumber: string;
  taxVariation: number;
  terminationReason: string;
  title: string;
  workPhone: string;
  workTypes: string;
  workingHolidayVisaCountry: string;
  workingHolidayVisaStartDate: string; // assuming ISO date-time string
};
