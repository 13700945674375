import { Button } from "reactstrap";
import { Field, FieldArray } from "redux-form";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import FormModal from "../utils/FormModal";
import formError from "../utils/formError";

interface SplitProductivityFormProps {
  toggle: () => void;
  modal: boolean;
  refreshData: () => void;
  selectedProductivity: any;
  projectJobs: any;
  projectId: number;
}

const SplitProductivityForm = (props: SplitProductivityFormProps) => {
  const { toggle, modal, refreshData, selectedProductivity, projectJobs } =
    props;
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `split-productivity/${selectedProductivity.uuid}`,
      {
        ...values,
      },
    )
      .then(() => {
        refreshData();
        toggle();
        toast.success("Productivity successfully split.");
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Split Productivity"
      form="SplitProductivityForm"
      onSubmit={onSubmit}
      initialValues={{
        uuid: selectedProductivity.uuid,
        job_id: selectedProductivity.job_id,
        productivity: [
          {
            is_primary: true,
            job_id: selectedProductivity.job_id,
            time_in_hours: selectedProductivity.time_in_hours,
          },
        ],
      }}
    >
      <div className="row">
        <FieldArray
          component={Fields}
          name={`productivity`}
          projectJobs={projectJobs}
          job_id={selectedProductivity.job_id}
        />
      </div>
    </FormModal>
  );
};

const Fields = (props: any) => {
  const { fields, job_id, projectJobs } = props;

  return (
    <>
      {fields.map((field: any, index: any) => {
        return (
          <>
            <FormHeader>Productivity {index + 1}</FormHeader>
            <div className="col-lg-6 form-group">
              <Field
                component={SelectInput}
                options={projectJobs.map((projectJob: any) => ({
                  label: projectJob.name,
                  value: projectJob.id,
                }))}
                name={`${field}.job_id`}
                validate={required}
                required
                label="Job"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.time_in_hours`}
                label="Hour"
              />
            </div>
          </>
        );
      })}
      <div className="form-group col-12 d-flex">
        <Button
          color="primary"
          className="ms-auto"
          outline
          onClick={() => fields.push({ job_id: job_id, time_in_hours: "" })}
        >
          Add Productivity
        </Button>
      </div>
    </>
  );
};

export default SplitProductivityForm;
