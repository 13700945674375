import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import ProfilePage from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import { CustomJob } from "./customJobTypes";
import DeleteButton from "./DeleteButton";
import ShowPage from "./ShowPage";
import { Badge } from "reactstrap";

export type CustomJobPages = "details" | "fields" | "templates" | "aggregates";

const Profile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const {
    data,
    setData: setCustomJob,
    loading,
    response,
    refreshData,
  }: IUseApi<CustomJob> = useApi(
    `custom-jobs/${uuid}`,
    null,
    true,
    "/custom-jobs",
  );

  const customJob = data as CustomJob;

  const canEditCustomJob =
    user?.active_organisation.uuid === data?.organisation?.uuid;

  const tabs: ProfileTab<CustomJobPages>[] = [
    {
      link: `/custom-jobs/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/custom-jobs/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
    {
      link: `/custom-jobs/${uuid}/aggregates`,
      icon: "",
      page: "aggregates",
      label: "Aggregates",
    },
  ];

  if (canEditCustomJob) {
    tabs.push({
      link: `/custom-jobs/${uuid}/templates`,
      icon: "",
      page: "templates",
      label: "Templates",
    });
  }

  if (!response || loading) return <Spinner loading />;

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={customJob?.name}
        crumbs={[
          { name: "Custom Jobs", link: "/custom-jobs" },
          { name: customJob?.name, link: "", active: true },
        ]}
      />

      <ProfilePage
        header={
          <>
            <DashboardHeader>
              <DashboardH3>{customJob?.name}</DashboardH3>
              {customJob?.archived_at && (
                <Badge className="rounded-pill shadow" color="info">
                  Archived
                </Badge>
              )}
            </DashboardHeader>
            {!canEditCustomJob && (
              <div className="mb-3">
                <InformationAlert
                  title="Shared Custom Job"
                  body="Shared custom job can not be edited from child organisation."
                  type="warning"
                />
              </div>
            )}
          </>
        }
        content={
          <ShowPage
            customJob={customJob}
            setCustomJob={setCustomJob}
            canEditCustomJob={canEditCustomJob}
          />
        }
        tabs={tabs}
        sideBar={
          canEditCustomJob ? (
            <DeleteButton
              archived={customJob?.archived_at}
              refreshData={refreshData}
            />
          ) : null
        }
      />
    </>
  );
};

export default Profile;
