import dayjs from "dayjs";
import Linkify from "react-linkify";

export interface TimelineItem {
  uuid: string | number;
  created_at: string;
  title?: string | React.ReactNode;
  user?: {
    name: string;
  };
  content: string | React.ReactNode;
}

interface TimeLineProps {
  items: TimelineItem[];
  title: string | React.ReactNode;
  empty?: string | React.ReactNode;
}

const TimeLine = ({ items, title, empty }: TimeLineProps) => {
  return (
    <div className="row row-timeline">
      <div className="col-lg-12">
        <div className="card pd-30">
          <p className="slim-card-title mb-4">{title}</p>
          <div className="timeline-group">
            {/* {Object.values(this.props.noticeboards).length > 0 && (
                <div className="timeline-item timeline-day">
                  <div className="timeline-time">&nbsp;</div>
                  <div className="timeline-body">
                    <p className="timeline-date">Today</p>
                  </div>
                </div>
              )} */}

            {items.map((item) => {
              return (
                //@ts-ignore
                <Linkify key={item.uuid} properties={{ target: "_blank" }}>
                  <div className="timeline-item">
                    <div className="timeline-time">
                      {dayjs(item.created_at).format("MMMM, DD hh:mm a")}
                    </div>
                    <div className="timeline-body">
                      {item.title && (
                        <p className="timeline-title">{item.title}</p>
                      )}
                      {item.user && (
                        <p className="timeline-author">{item.user.name}</p>
                      )}
                      <p className="timeline-text white-space">
                        {item.content}
                      </p>
                    </div>
                  </div>
                </Linkify>
              );
            })}
          </div>
          {empty}
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
