import PropTypes from "prop-types";

const Empty = ({
  isDragActive,
  folder = {
    name: "",
  },
  isDragAccept,
}) => {
  return (
    <div
      className={`bg-${isDragActive ? "primary" : "white"} border p-5`}
      style={{
        transitionDuration: "0.3s",
      }}
    >
      <img
        className="w-100"
        src="/img/empty_documents.svg"
        height="200"
        alt="No Documents Found"
      />
      {isDragAccept ? (
        <>
          <p className="fw-bold w-100 tx-white text-center mt-5 mb-2">
            Drop Files!
          </p>
          <p className="w-100  text-center mb-0 tx-white">
            Drop Files to upload to {folder.name}.
          </p>
        </>
      ) : (
        <>
          <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
            No Documents {folder.name ? `for ${folder.name}` : ""} found.
          </p>
          <p className="w-100  text-center mb-0">
            Drag documents onto the image, click or add a folder to get started.
          </p>
        </>
      )}
    </div>
  );
};

Empty.propTypes = {
  isDragActive: PropTypes.bool.isRequired,
  isDragAccept: PropTypes.bool.isRequired,
  folder: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default Empty;
