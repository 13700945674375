import { Configure, InstantSearch, useInfiniteHits } from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useAuth } from "../../../context/auth-context";
import BossSearchBox from "../../utils/algolia/BossSearchBox";
import { Link } from "react-router-dom";
import HeaderPage from "../../header/HeaderPage";
import { Button } from "reactstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import SampleTable from "./SampleTable";
import SampleItem from "./SampleItem";
import { SampleIndex } from "./sampleTypes";
import SampleFilterModal from "./SampleFilterModal";
import TestStatusDump from "./TestStatusDump";
import SampleDumpModal from "./SampleDumpModal";
import SampleListActionDropdown from "./SampleListActionDropdown";

const NewSampleList = () => {
  const { user } = useAuth();

  const [filters, setFilters] = useState<any[]>([]);
  const [between, setBetween] = useState<[number, number]>();

  const searchClient = useMemo(() => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: user?.search_key ?? "", // Be sure to use the search-only-api-key
        nodes: [
          //@ts-ignore
          {
            host: "search.thebossapp.com.au",
            protocol: "https",
          },
        ],
      },
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  query_by is required.
      additionalSearchParameters: {
        query_by:
          "project.name,reports.name,test_request.name,number,tests.name,client.name,numerical_number",
        per_page: 18,
        /**@ts-ignore */
        num_facet_candidates: -1,
      },
    });

    return typesenseInstantsearchAdapter.searchClient;
  }, [user?.search_key]);

  const [showTable, setShowTable] = useState(false);

  return (
    <>
      <HeaderPage
        titlePage="Samples"
        crumbs={[{ link: "samples", name: "Samples", active: true }]}
      />
      <InstantSearch
        searchClient={searchClient}
        indexName={`${import.meta.env.VITE_SCOUT_PREFIX ?? ""}sample_registers`}
      >
        <div className="d-flex space-x-3 align-items-center">
          <BossSearchBox />
          <Configure
            filters={
              between?.[0] && between[1]
                ? `sampled_at_date:[${between[0]}..${between[1]}]`
                : ""
            }
          />

          <SampleFilterModal
            setFilters={setFilters}
            filters={filters}
            //@ts-ignore
            between={between}
            setBetween={setBetween}
          />
          <Button
            outline
            size="sm"
            color="primary"
            className="ms-3"
            onClick={() => setShowTable(!showTable)}
          >
            {showTable ? "Show List" : "Show Table"}
          </Button>

          <SampleListActionDropdown filters={filters}/>
        </div>
        <div className="row">
          <Hits showTable={showTable} />
        </div>
      </InstantSearch>
    </>
  );
};

const Hits = ({ showTable }: { showTable: boolean }) => {
  const { items, isLastPage, showMore } = useInfiniteHits<SampleIndex>();

  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sentinelRef.current) return;

    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <div className="mt-3">
      {showTable ? (
        <SampleTable data={items} />
      ) : (
        <div className="row row-sm">
          {items?.map((sample) => {
            return <SampleItem sample={sample} key={sample.uuid} />;
          })}
        </div>
      )}

      <div ref={sentinelRef} />
    </div>
  );
};

export default NewSampleList;
