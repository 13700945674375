import { useHistory, useParams } from "react-router-dom";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import { ApprovalStatuses } from "../../approvals/approvalTypes";
import Tabs from "../../tabs/Tabs";
import { Tab } from "../../tabs/tabTypes";
import errorSwal from "../../utils/errorSwal";
import ProfileButtons from "../../utils/ProfileButtons";
import isSubmitting from "../../utils/submitting";
import { TestSuiteTestProps } from "../testSuiteTypes";
import AddApproveTestSuiteTestButton from "./AddApproveTestSuiteTestButton";
import TestSuiteTest from "./TestSuiteTest";
import { toast } from "react-toastify";

const TestSuiteTests = (props: TestSuiteTestProps) => {
  const { test } = props;
  const { uuid } = useParams<{ uuid: string }>();

  const history = useHistory();

  const { loading, takeAction }: IUseApi<{}, { data: string }> = useApi();

  const {
    takeAction: deliverableAction,
    loading: creatingDeliverable,
  }: IUseApi<{}, { data: { link: string } }> = useApi();

  const generateDeliverable = () => {
    return deliverableAction("store", `test-suite-tests/${uuid}/deliverables`)
      .then(({ data }) => {
        history.push(data.data.link);
        toast.success("Deliverable Created");
      })
      .catch(errorSwal);
  };

  const download = () => {
    return takeAction("store", `/download-test-suite-tests/${uuid}`)
      .then(({ data }) => {
        if (data.data) {
          window.location.href = data.data;
        }
      })
      .catch(errorSwal);
  };

  const tabs: Tab[] = test.samples.map((sample) => {
    return {
      component: <TestSuiteTest key={sample.uuid} {...props} sample={sample} />,
      title: sample.number,
    };
  });

  const buttons: {
    text: string | React.ReactNode;
    onClick: Function;
    disabled?: boolean;
  }[] = [
    {
      text: isSubmitting(loading, "Download", "Generating..."),
      onClick: download,
      disabled: loading,
    },
  ];

  if (
    test?.approval?.status === ApprovalStatuses.APPROVED &&
    test.completed_at
  ) {
    buttons.push({
      text: isSubmitting(
        creatingDeliverable,
        `Generate Deliverable${
          test.deliverable?.has_been_sent ? " Revision" : ""
        }`,
        "Generating...",
      ),
      onClick: generateDeliverable,
      disabled: creatingDeliverable,
    });
  }

  return (
    <>
      <ProfileButtons buttons={buttons} />
      <Tabs tabs={tabs} />
      <AddApproveTestSuiteTestButton {...props} />
    </>
  );
};

export default TestSuiteTests;
