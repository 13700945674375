import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import { useAuth } from "../../context/auth-context";
import { ProjectJob, Template } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";
import PaginatedList from "../pagination/PaginatedList";
import useCustomJobs from "./hooks/useCustomJobs";
import CustomJobTable from "./CustomJobTable";
import { CustomJob } from "./customJobTypes";
import { IFilter } from "../hooks/useFilter";

export type CustomJobFilters = IFilter<"archived_at", boolean | number>;

const CustomJobs = () => {
  const filters = useFilters();
  const { user } = useAuth();

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/custom-jobs/" />
      <HeaderPageAdminFilter
        titlePage="Custom Jobs"
        pageDescription={
          <>
            The Custom Job Types feature allows organizations to create job
            types with specific fields to capture information and manage them
            effectively.
          </>
        }
        relatedLinks={[
          { name: "Jobs", link: "/jobs", model: ProjectJob },
          { name: "Templates", link: "/templates", model: Template },
        ]}
        crumbs={[{ name: "Custom Jobs", link: "/custom-jobs", active: true }]}
      />

      <PaginatedList
        listName="projectsList"
        indexHook={useCustomJobs}
        originalFilters={filters}
        addLink={
          user?.hasAccessTo("App\\Models\\CustomJob", "create")
            ? "/custom-jobs/add"
            : ""
        }
        list={({ data }) => {
          const customJobs = data as CustomJob[];

          return (
            <div className="col-12">
              <CustomJobTable customJobs={customJobs} />
            </div>
          );
        }}
      />
    </>
  );
};

const useFilters = (): CustomJobFilters[] => {
  return [
    {
      label: "Status",
      name: "archived_at",
      options: [
        {
          label: "Open",
          value: false,
        },
        {
          label: "Archived",
          value: true,
        },
      ],
    },
  ];
};

export default CustomJobs;
