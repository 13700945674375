export interface IStatusReport {
  title: string;
  description?: string;
  value: string | number;
}

export interface IStatusProgress {
  min: number;
  max: number;
  current: number;
  description?: string;
}

interface IStatusReportWidget {
  title: string;
  reports: IStatusReport[];
  progress?: IStatusProgress;
}

const StatusReportWidget = ({
  title,
  reports,
  progress,
}: IStatusReportWidget) => {
  const currentProgress = progress ? Math.ceil(progress.current / 5) * 5 : 0;

  return (
    <div className="card card-sales">
      <h6 className="slim-card-title tx-primary tx-14">{title}</h6>
      <div className="row">
        {reports?.map((report, idx) => (
          <div key={idx} className="col-6">
            <span>
              <label className="tx-12 fw-bold text-primary">
                {report.title}
              </label>
              {report.description && (
                <p className="tx-12 mb-2">{report.description}</p>
              )}
            </span>
            <p className="tx-primary fw-bold">{report.value}</p>
          </div>
        ))}
      </div>

      {progress && (
        <>
          <div className="progress mg-b-5">
            <div
              className={`progress-bar bg-primary wd-${currentProgress}p`}
              role="progressbar"
              aria-valuenow={progress.current}
              aria-valuemin={progress.min}
              aria-valuemax={progress.max}
            >
              {progress.current.toFixed(2)}%
            </div>
          </div>
          <small>Percentage Completed</small>
          {progress.description && (
            <p className="tx-12 mg-b-0">{progress.description}</p>
          )}
        </>
      )}
    </div>
  );
};

export default StatusReportWidget;
