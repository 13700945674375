import { Link } from "react-router-dom";
import SpreadPrice from "../utils/SpreadPrice";
import DetailsTable from "./DetailsTable";
import dayjs from "dayjs";
import { useMemo } from "react";

const InvoicedTable = (props) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "invoice_number",
        header: "Invoice Number",
        cell: (info) => (
          <Link to={info.row.original.link}>{info.getValue()}</Link>
        ),
      },
      {
        accessorKey: "date",
        header: "Date",
        cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "project.name",
        header: "Project",
        cell: (info) => {
          const project = info.row.original.project;

          const tags = project.tags ?? [];

          return (
            <div>
              <Link to={`/${project.link}`}>{info.getValue()}</Link>
              <div className="d-flex space-x-3">
                {tags.map((tag) => (
                  <div
                    className="rounded-pill shadow bg-indigo text-white text-center px-2 tx-10"
                    key={tag.value}
                  >
                    {tag.value}
                  </div>
                ))}
              </div>
            </div>
          );
        },
        getFilterValue: (row) => {
          const project = row.original.project;
          console.log(project);

          const tags = project.tags ?? [];
          const tagString = tags.map((tag) => tag.value).join(" ");

          return `${project.name} ${tagString}`;
        },
      },
      {
        accessorKey: "project.client.name",
        header: "Client",
        cell: (info) => (
          <Link to={`/${info.row.original.project.client.link}`}>
            {info.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "project_manager.name",
        header: "Project Manager",
        cell: (info) => {
          const job = info.row.original;
          return (
            <a href={`mailto:${job.project_manager.email}`}>
              {job.project_manager.name}
            </a>
          );
        },
      },
      {
        accessorKey: "job_type",
        header: "Job Type",
      },
      {
        accessorKey: "total_hours_added",
        header: "Total Hours Added",
      },
      {
        accessorKey: "job_manager.name",
        header: "Job Manager",
        cell: (info) => {
          const job = info.row.original;
          return (
            <a href={`mailto:${job.job_manager.email}`}>
              {job.job_manager.name}
            </a>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Description",
        cell: (info) => (
          <Link to={info.row.original.link}>{info.getValue()}</Link>
        ),
      },
      {
        accessorKey: "total_due",
        header: "Amount",
        cell: (info) => <SpreadPrice price={info.getValue()} />,
      },
      {
        id: "tags",
        enableHiding: true,
        show: false,
        accessorFn: (row) => {
          const tags = row.project?.tags ?? [];

          return tags.map((tag) => tag.value).join(" ");
        },
      },
    ],
    [props.details],
  );

  return (
    <DetailsTable
      url="invoice-overview"
      {...props}
      title="Invoices"
      columns={columns}
    />
  );
};

export default InvoicedTable;
